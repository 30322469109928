import React, { useEffect, useImperativeHandle, useState } from "react";
import { useForm } from "react-hook-form";

import { SearchIcon, EditQc, DeleteQc, SiteDefault, SiteSelected, InterfaceDisable, InterfaceDefault, InterfaceSelected, DevicesDisable, DevicesDefault, DevicesSelected, AddNode, SearchIconWhite, RemoveIcon } from '../../../../assets/images'

import Swal from "sweetalert2";
import { OverlayTrigger } from "react-bootstrap";
import Tooltip from "react-bootstrap/Tooltip";
import Modal from "react-bootstrap/Modal";
import { Mention, MentionsInput } from "react-mentions";

import { addCustomerName, getStateDetails, getInterfaceSubTypeData, deviceconfigcrud } from "../../../../services/configurationServices";
import { SuccessToast } from "../../../../CustomHooks/SuccessToast";
import { HIDE_STUFF, MULTI_TIME_ZONE } from "../../../../config";
import CameraBulkUpload from "../Common/CameraBulkUpload";
import PanelBulkUpload from "../Common/PanelBulkUpload";
import ConfigurePanelZones from "../Common/ConfigurePanelZones";
import AssociateCameraOnSensor from "../Common/AssociateCameraOnSensor";
import AddERTForm from "../Common/AddERTForm";
import { GetmultiTimeZone } from "../../../../services/multiTimeZoneService";
import { ConvertDateToUTC } from "../../../../utils/utils";
import { ErrorToast } from "../../../../CustomHooks/ErrorToast";
import AcsBulkUpload from "../Common/AcsBulkUpload";

let tempSiteObj = {
  "SiteID": 0,
  "SiteName": '',
  "BranchID": '',
  "SiteAddress": '',
  "clientID": 0,
  "AddCustomerName": '',
  "CountryName": 0,
  "StateName": 0,
  "CityName": '',
  "SiteRegionName": '',
  "SitePincode": '',
  "SiteArea": '',
  "SiteModeID": 0
}

let interfaceObj = {
  "siteid": 0,
  "InterfaceId": 0,
  "InterfaceName": '',
  "InterfaceType": 0,
  "InterfaceSubType": 0,
  "InterfaceIP": '',
  "InterfacePort": '',
  "InterfaceUsername": '',
  "InterfacePassword": '',
  "UserId": 0,
  "EntityTypeID": 0,
  "CCTVTypeIDInterface": 0,
  "InterfaceTwoWayEnabled": '',
  "CheckInternalHooter": 0,
  "CheckExternalHooter": 0,
  "IsHooterEnabled": 0,
  "PlaybackTimeStampsToUTC": '',
  "PlaybackLongDateFormat": '',
  "LiveRTSPURL": '',
  "PlaybackRTSPURL": '',
  "CCTVType": '',
  "TimeZoneId": 0
}

const AddSiteForm = React.forwardRef((props, ref) => {
  let {
    onSubmit,
    site,
    isSiteEdit,
    setIsSiteEdit,
    clients,
    getCliets,
    siteTypes,
    country,
    countryState,
    setCountryState,
    onSelectSite,
    ertLevels,
    siteERTOnClick,
    selectedERT,
    onAddERTMemberClick,
    addNewERTMemberClick,
    emeContactList,
    onDeleteERT,
    userOptions,
    onUserRoleChange,
    selectedUserRole,
    setSelectedUserRole,
    siteUserFieldsVisibility,
    filteredSites,
    cameradeviceslist,
    onDeviceSelection,
    selectedDevice,
    devicesmasterlist,
    isSubmitting,
    sites,
    OnAddIDSPanel,
    paneldeviceslist,
    OnSaveDevice,
    onSearchChange,
    setSelectedSite,
    setSelectedDevice,
    onDeleteChange,
    checkForAlertsAndDelDevice,
    getdeviceslistdata,
    interfaceTypeData,
    interfaceSubTypeData,
    setInterfaceSubTypeData,
    interfaceDataList,
    onInterfaceFormSubmit,
    onSelectInterface,
    interfaceDevice,
    setSelectedInterface,
    bulkUpload,
    setBulkUpload,
    setIsSingleOrBulkSelection,
    setBulkDevToUpload,
    selectedCamHeaders,
    OnAddBulkDevicesClick,
    setSelectedCamHeaders,
    clearImportedCamHeaders,
    deviceBulkUploadResponse,
    setImportedFileData,
    setDeviceBulkUploadResponse,
    isInterfaceEdit,
    isDeviceEdit,
    setIsInterfaceEdit,
    setIsDeviceEdit,
    getInterfaceData,
    onDeleteInterfaceChange,
    selectedHeaders,
    onPanelBulkUpload,
    setSelectedHeaders,
    clearImportedPanelHeaders,
    panelBulkUploadResponse,
    panelBulkUploadError,
    setPanelBulkUploadResponse,
    setPanelBulkUploadError,
    setFilteredSites,
    importedFileHeaders,
    setImportedFileHeaders,
    importedPanelHeaders,
    setImportedPanelHeaders,
    selectedZone,
    setselectedZone,
    associateCamSecVisibility,
    setAssociateCamSecVisibility,
    onSensorSelection,
    associatedDevices,
    onAssociate,
    selectedSensor,
    selectedItems,
    setSelectedItems,
    cctvTypeData,
    setInterfaceDataList,
    setcameradeviceslist,
    setpaneldeviceslist,
    setParentIsEncoding,
    parentIsEncoding,
    parentIsPlaybackEncoding,
    setParentIsPlaybackEncoding,
    parentIsEnablingTwoWayDevice,
    setParentIsEnablingTwoWayDevice,
    setParentIsEnablingTwoWayInterface,
    parentIsEnablingTwoWayInterface,
    setParentIsEnablingInternalHooter,
    setParentIsEnablingExternalHooter,
    setParentPanelIsEnablingHooter,
    parentIsEnablingInternalHooter,
    setParentIsEnablingPlaybackTimeStampsToUTC,
    parentIsEnablingPlaybackTimeStampsToUTC,
    parentIsEnablingPlaybackLongDateFormat,
    setParentIsEnablingPlaybackLongDateFormat,
    parentIsEnablingExternalHooter,
    parentPanelIsEnablingHooter,
    setRtspValueChanged,
    isDarkTheme,
    setUnknownBrandUrl,
    validationError,
    savebtnClick,
    setSavebtnClick,
    setInputValidationError,
    buttonDisable,
    timezone,
    setTimeZone,
    parentIsEnablingPTZ,
    setParentIsEnablingPTZ,
    parentIsEnablingUTCPlayback,
    setParentIsEnablingUTCPlayback,
    acsDevicesList,
    setAcsDevicesList,
    onSaveACS,
    selectedAcsHeaders,
    setSelectedAcsHeaders,
    clearImportedAcsHeaders,
    setClearImportedAcsHeaders,
    acsBulkUploadResponse,
    setAcsBulkUploadResponse,
    acsBulkUploadError,
    setAcsBulkUploadError,
    importedAcsHeaders,
    setImportedAcsHeaders,
    onAcsBulkUpload
  } = { ...props };
  const {
    register,
    handleSubmit,
    getValues,
    setValue,
    watch,
    formState: { isDirty, touchedFields },
  } = useForm({ defaultValue: { ...site, ...interfaceDevice, ...selectedDevice } });
  const [show, setShow] = useState(false);
  const SITES = "SITES";
  const INTERFACES = "INTERFACES";
  const DEVICES = "DEVICES";

  const PANEL = "PANEL";
  const NVRDVR = "NVRDVR";
  const ACS = "ACS";
  const siteRef = React.useRef(null);
  const interfaceRef = React.useRef(null);

  //#region useState start
  const [activeTab, setActiveTab] = useState(SITES)
  const [disableTab, setDisableTab] = useState(true);
  const [interfaceTab, setInterfaceTab] = useState(true);
  const [interfaceType, setInterfaceType] = useState(NVRDVR);
  const [selectedBrand, setSelectedBrand] = useState('');
  const [channelNumberPlaceholder, setChannelNumberPlaceholder] = useState('Channel Number* e.g. 1');

  const [searchTextSite, setSearchTextSite] = useState('');
  const [searchTextInterface, setSearchTextInterface] = useState('');
  const [searchTextDevice, setSearchTextDevice] = useState('');
  const [isChannelNumberEdited, setIsChannelNumberEdited] = useState(false)

  // Add site form error states
  const [customerError, setCustomerError] = useState('');
  const [siteError, setSiteError] = useState('');
  const [countryError, setCountryError] = useState('');
  const [stateError, setStateError] = useState('');
  const [cityError, setCityError] = useState('');
  const [regionError, setRegionError] = useState('');
  const [pincodeError, setPincodeError] = useState('');
  const [addressError, setAddressError] = useState('');
  const [areaError, setAreaError] = useState('');
  const [branchidError, setBranchidError] = useState('');
  // const [siteTypeError, setSiteTypeError] = useState('');

  // Add Interface error states
  const [interfaceError, setInterfaceError] = useState('');
  const [interfaceTypeError, setInterfaceTypeError] = useState('')
  const [interfaceSubtypeError, setInterfaceSubtypeError] = useState('')
  const [interfaceEntitySubtypeError, setInterfaceEntitySubtypeError] = useState('')
  const [interfaceIPError, setInterfaceIPError] = useState('')
  const [interfacePortError, setInterfacePortError] = useState('')
  const [interfaceUserError, setInterfaceUserError] = useState('')
  const [interfacePasswardError, setInterfacePasswardError] = useState('')
  const [liveStreamFormatError, setLiveStreamFormatError] = useState('');
  const [playbackStreamFormatError, setPlaybackStreamFormatError] = useState('');
  const [interfaceTimeZoneError, setInterfaceTimeZoneError] = useState('')
  // Add camera form error states
  const [communicationPortError, setCommunicationPortError] = useState('');
  const [httpPortError, setHttpPotError] = useState('');
  const [channelNumberError, setChannelNumberError] = useState('');
  const [liveStreamTypeError, setLiveStreamTypeError] = useState('');
  const [playbackStreamTypeError, setPlaybackStreamTypeError] = useState('');
  const [rtspURLError, setrtspURLError] = useState('');
  const [deviceNameError, setDeviceNameError] = useState('');
  const [identifierError, setIdentifierError] = useState("");
  // const [entitySubtypeError, setEntitySubtypeError] = useState('');

  const [addCustomerError, setAddCustomerError] = useState('');

  const [displayTwoWay, setDisplayTwoWay] = useState(false);
  const [displayCamera, setDisplayCamera] = useState(false);
  const [enableHooter, setEnableHooter] = useState(false);
  const [disabledEntity, setDisabledEntity] = useState(false);
  const [displayEntity, setDisplayEntity] = useState(false);

  const [displayLivePlaybackfield, setDisplayLivePlaybackfield] = useState(false);

  const [rtspValue, setRtspValue] = useState("");
  const [playbackValue, setPlaybackValue] = useState("");
  const [rtspValueEntered, setRtspValueEntered] = useState("");
  const [playbackValueEntered, setPlaybackValueEntered] = useState("");
  const [isAlreadyRetail, setIsAlreadyRetail] = useState(false);
  const [editAssociate, setEditAssociate] = useState(false);
  const [acsList, setAcsList] = useState([]);
  const [acsMasterList, setAcsMasterList] = useState([]);
  const [cameraCount, setCameraCount] = useState(0);
  const [cameraSelectAll, setCameraSelectAll] = useState(false);
  const [cameraSearch, setCameraSearch] = useState("");
  //#endregion

  //#region useEffect start
  useEffect(() => {
    if (savebtnClick) {
      let templst = validationError.split(",");
      for (let i = 0; i < templst.length; i++) {
        if (templst[i].includes("customer-")) {
          setCustomerError(templst[i].replace("customer-", ""));
          continue
        }

        if (templst[i].includes("site-")) {
          setSiteError(templst[i].replace("site-", ""));
          continue
        }

        if (templst[i].includes("country-")) {
          setCountryError(templst[i].replace("country-", ""));
          continue
        }

        if (templst[i].includes("state-")) {
          setStateError(templst[i].replace("state-", ""));
          continue
        }

        if (templst[i].includes("city-")) {
          setCityError(templst[i].replace("city-", ""));
          continue
        }

        if (templst[i].includes("region-")) {
          setRegionError(templst[i].replace("region-", ""));
          continue
        }

        if (templst[i].includes("pincode-")) {
          setPincodeError(templst[i].replace("pincode-", ""));
          continue
        }

        if (templst[i].includes("address-")) {
          setAddressError(templst[i].replace("address-", ""));
          continue
        }

        if (templst[i].includes("area-")) {
          setAreaError(templst[i].replace("area-", ""));
          continue
        }

        if (templst[i].includes("branchid-")) {
          setBranchidError(templst[i].replace("branchid-", ""));
          continue
        }
      }
      setSavebtnClick(false)
    }
  }, [savebtnClick]);
  useEffect(() => {
    if (savebtnClick) {
      let templst = validationError.split(",");
      for (let i = 0; i < templst.length; i++) {
        if (templst[i].includes("interface-")) {
          setInterfaceError(templst[i].replace("interface-", ""));
          continue
        }

        if (templst[i].includes("interface type-")) {
          setInterfaceTypeError(templst[i].replace("interface type-", ""));
          continue
        }

        if (templst[i].includes("interface sub type-")) {
          setInterfaceSubtypeError(templst[i].replace("interface sub type-", ""));
          continue
        }
        if (templst[i].includes("interface timezone-")) {
          setInterfaceTimeZoneError(templst[i].replace("interface timezone-", ""));
          continue
        }

        if (templst[i].includes("interface entity subtype-")) {
          setInterfaceEntitySubtypeError(templst[i].replace("interface entity subtype-", ""));
          continue
        }

        if (templst[i].includes("interface IP-")) {
          setInterfaceIPError(templst[i].replace("interface IP-", ""));
          continue
        }

        if (templst[i].includes("interface port-")) {
          setInterfacePortError(templst[i].replace("interface port-", ""));
          continue
        }

        if (templst[i].includes("interface username-")) {
          setInterfaceUserError(templst[i].replace("interface username-", ""));
          continue
        }

        if (templst[i].includes("interface password-")) {
          setInterfacePasswardError(templst[i].replace("interface password-", ""));
          continue
        }

        if (templst[i].includes("interface livestream format-")) {
          setLiveStreamFormatError(templst[i].replace("interface livestream format-", ""));
          continue
        }

        if (templst[i].includes("interface playbackstream format-")) {
          setPlaybackStreamFormatError(templst[i].replace("interface playbackstream format-", ""));
          continue
        }
      }
      setSavebtnClick(false)
    }
  }, [savebtnClick]);

  useEffect(() => {
    if (savebtnClick) {
      let templst = validationError.split(",");
      for (let i = 0; i < templst.length; i++) {
        if (templst[i].includes("communication port-")) {
          setCommunicationPortError(templst[i].replace("communication port-", ""));
          continue
        }

        if (templst[i].includes("http port-")) {
          setHttpPotError(templst[i].replace("http port-", ""));
          continue
        }

        if (templst[i].includes("channel number-")) {
          setChannelNumberError(templst[i].replace("channel number-", ""));
          continue
        }

        if (templst[i].includes("live stream type-")) {
          setLiveStreamTypeError(templst[i].replace("live stream type-", ""));
          continue
        }

        if (templst[i].includes("playback stream type-")) {
          setPlaybackStreamTypeError(templst[i].replace("playback stream type-", ""));
          continue
        }

        if (templst[i].includes("rtsp URL-")) {
          setrtspURLError(templst[i].replace("rtsp URL-", ""));
          continue
        }

        if (templst[i].includes("device name-")) {
          setDeviceNameError(templst[i].replace("device name-", ""));
          continue
        }

        if (templst[i].includes("identifier name-")) {
          setIdentifierError(templst[i].replace("identifier name-", ""));
          continue;
        }
      }
      setSavebtnClick(false)
    }
  }, [savebtnClick]);

  useEffect(() => {
    //for getting Country in dropdown
    let tempCountryDetails = country?.filter(item => {
      return item.CountryMasterId == site.CountryId
    })
    if (tempCountryDetails?.length > 0) {
      setValue("CountryName", tempCountryDetails[0].CountryName);
    } else {
      setValue("CountryName", site.CountryName);
    }
    //for getting state in dropdown
    let tempCountryStateDetails = countryState?.filter(item => {
      return item.StateID == site.StateId
    })
    if (tempCountryStateDetails?.length > 0) {
      setValue("StateName", tempCountryStateDetails[0].StateName);
    } else {
      setValue("StateName", site.StateName);
    }

    setValue("clientID", site.clientID);
    setValue("SiteName", site.SiteName);
    setValue("BranchID", site.BranchID);
    setValue("SiteAddress", site.SiteAddress);
    setValue("CityName", site.City);
    setValue("SiteRegionName", site.Region);
    setValue("SitePincode", site.pincode);
    setValue("SiteAddress", site.Address);
    setValue("SiteArea", site.Area);

    if (!site.SiteModeID == null || !site.SiteModeID == 0) {
      setValue("SiteModeID", site.SiteModeID)
      setIsAlreadyRetail(true);
    } else {
      setValue("SiteModeID", 0)
      setIsAlreadyRetail(false);
    }

  }, [site]);

  useEffect(() => {
    let tempCountryStateDetails = countryState?.filter(item => {
      return item.StateID == site.StateId
    })
    if (tempCountryStateDetails?.length > 0) {
      setValue("StateName", tempCountryStateDetails[0].StateName);
    } else {
      setValue("StateName", site.StateName);
    }
  }, [countryState])


  useEffect(() => {
    console.log("selectedDevice", selectedDevice)
    if (selectedDevice && selectedDevice != null) {
      let tempCameraBrandId = devicesmasterlist?.cameraTypeMasterList?.filter(item => {
        return item.brandId == selectedDevice.cameraBrandId || selectedDevice.CameraBrandId
      })
      let cameraBrandName = "";
      if (tempCameraBrandId != undefined) {
        cameraBrandName = tempCameraBrandId[0]?.brandName
      } else {
        cameraBrandName = "";
      }

      setValue("SiteID", selectedDevice.SiteID)
      setValue("cameraName", selectedDevice.CameraName)
      setValue("privateIPAddress", selectedDevice.privateIPAddress)
      setValue("communicationPort", selectedDevice.communicationPort)
      setValue("httpPort", selectedDevice.httpPort)
      setValue("userName", selectedDevice.userName || selectedDevice.camUserName)
      setValue("password", selectedDevice.password || selectedDevice.camPassword)
      setValue("port", selectedDevice.port || selectedDevice.camPort)
      setValue("channelNumber", selectedDevice.channelNum)
      //New
      let liveSubType;
      let playbackSubType;
      if (cameraBrandName == "Hikvision" || cameraBrandName == "Hikvision-Hybrid") {
        liveSubType = selectedDevice.SubTypeLive == "01" ? "Main" : selectedDevice.SubTypeLive == "02" ? "Sub" : selectedDevice.SubTypeLive == "03" ? "Ter" : ""
        setValue("liveStreamTypeNumber", liveSubType)
        playbackSubType = selectedDevice.SubTypePlayback == "01" ? "Main" : selectedDevice.SubTypePlayback == "02" ? "Sub" : selectedDevice.SubTypePlayback == "03" ? "Ter" : ""
        setValue("playbackStreamTypeNumber", playbackSubType)
      } else {
        if (cameraBrandName == "UnV" && selectedDevice.CCTVTypeID == 4) {
          liveSubType = selectedDevice.SubTypeLive == "1" ? "Main" : selectedDevice.SubTypeLive == "2" ? "Sub" : selectedDevice.SubTypeLive == '3' ? "Ter" : ""
          setValue("liveStreamTypeNumber", liveSubType)
          playbackSubType = selectedDevice.SubTypePlayback == "1" ? "Main" : selectedDevice.SubTypePlayback == "2" ? "Sub" : selectedDevice.SubTypePlayback == "3" ? "Ter" : ""
          setValue("playbackStreamTypeNumber", playbackSubType)
        } else {
          liveSubType = selectedDevice.SubTypeLive == "0" ? "Main" : selectedDevice.SubTypeLive == "1" ? "Sub" : selectedDevice.SubTypeLive == '2' ? "Ter" : ""
          setValue("liveStreamTypeNumber", liveSubType)
          playbackSubType = selectedDevice.SubTypePlayback == "0" ? "Main" : selectedDevice.SubTypePlayback == "1" ? "Sub" : selectedDevice.SubTypePlayback == "2" ? "Ter" : ""
          setValue("playbackStreamTypeNumber", playbackSubType)
        }
      }

      setValue("rTSPURL", selectedDevice.RTSPURL && selectedDevice.RTSPURL.replace("%40", "@"))
      setValue("location", selectedDevice.Location)
      setValue("ParentCameraID", selectedDevice.ParentCameraID)
      setValue("CameraBrandId", selectedDevice.cameraBrandId || selectedDevice.CameraBrandId)
      setValue("CCTVTypeID", selectedDevice.CCTVTypeID)
      setValue("TimeZoneIdDevice", selectedDevice.TimeZoneIdDevice)
      setValue("identifierName", selectedDevice.identifierName)

      if (selectedDevice.isTranscoded == "1") {
        setValue("isTranscoded", 1);
        setParentIsEncoding(true);
      } else {
        setValue("isTranscoded", 0);
        setParentIsEncoding(false);
      }

      if (selectedDevice.isPlaybackTranscoded == "1") {
        setValue("isPlaybackTranscoded", 1);
        setParentIsPlaybackEncoding(true);
      } else {
        setValue("isPlaybackTranscoded", 0);
        setParentIsPlaybackEncoding(false);
      }

      if (selectedDevice.isTwoWayEnabled == "1") {
        setValue("isTwoWayEnabledDevice", 1);
        setParentIsEnablingTwoWayDevice(true);
      } else {
        setValue("isTwoWayEnabledDevice", 0);
        setParentIsEnablingTwoWayDevice(false);
      }

      if (selectedDevice.isPTZ == "1") {
        setValue("isPTZ", 1);
        setParentIsEnablingPTZ(true)
      } else {
        setValue("isPTZ", 0);
        setParentIsEnablingPTZ(false);
      }

      if (selectedDevice.isPlaybackUTC == "1") {
        setValue("isPlaybackUTC", 1);
        setParentIsEnablingUTCPlayback(true);
      } else {
        setValue("isPlaybackUTC", 0);
        setParentIsEnablingUTCPlayback(false);
      }

      // setValue("isTranscoded", selectedDevice.isTranscoded);
    }
    handleRefClick();
  }, [selectedDevice]);

  useEffect(() => {
    if (watch("CameraBrandId") && watch("privateIPAddress") && watch("userName") && watch("password") && watch("port") && watch("channelNumber") && watch("liveStreamTypeNumber")) {
      var channelState;
      let liveStreamType = 0;

      setIsChannelNumberEdited(prevState => {
        channelState = prevState;
        return prevState;
      });

      let tempCameraBrandId = devicesmasterlist.cameraTypeMasterList.filter(item => {
        return item.brandId == getValues("CameraBrandId")
      })
      let cameraBrandName = tempCameraBrandId[0].brandName
      if (cameraBrandName == "Hikvision" || cameraBrandName == "Hikvision-Hybrid") {
        // liveStreamType = getValues("liveStreamTypeNumber") == "Main" ? 1 : 2
        liveStreamType = getValues("liveStreamTypeNumber") == "Main" ? "01" : getValues("liveStreamTypeNumber") == "Sub" ? "02" : "03"
      } else {
        // liveStreamType = getValues("liveStreamTypeNumber") == "Main" ? 0 : 1
        liveStreamType = getValues("liveStreamTypeNumber") == "Main" ? "0" : getValues("liveStreamTypeNumber") == "Sub" ? "1" : "2"
      }

      let temp = "";
      if (cameraBrandName == "Hikvision") {
        temp =
          "rtsp://" +
          getValues("userName") +
          ":" +
          getValues("password") +
          "@" +
          getValues("privateIPAddress") +
          ":" +
          getValues("port") +
          "/Streaming/Channels/" +
          getValues("channelNumber") +
          liveStreamType;
      } else if (cameraBrandName == "CP Plus" || cameraBrandName == "Dahua") {
        temp =
          "rtsp://" +
          getValues("userName") +
          ":" +
          getValues("password") +
          "@" +
          getValues("privateIPAddress") +
          ":" +
          getValues("port") +
          "/cam/realmonitor?channel=" +
          getValues("channelNumber") +
          "&subtype=" + liveStreamType; //+getValues("channelNumber")
      } else if (cameraBrandName == "Sparsh") {
        temp =
          "rtsp://" +
          getValues("userName") +
          ":" +
          getValues("password") +
          "@" +
          getValues("privateIPAddress") +
          ":" +
          getValues("port") +
          "/avstream/channel=" +
          getValues("channelNumber") +
          "/stream=" + liveStreamType + ".sdp";
      } else if (cameraBrandName == "DLink") {
        //=> rtsp://admin:Admin@123@122.170.105.219:554/avstream/channel=1/stream=0.sdp
        temp =
          "rtsp://" +
          getValues("userName") +
          ":" +
          getValues("password") +
          "@" +
          getValues("privateIPAddress") +
          ":" +
          getValues("port") +
          "/avstream/channel=" +
          getValues("channelNumber") +
          "/stream=" + liveStreamType + ".sdp";
        // => rtsp://admin:Admin@@12k@102.168.0.1:7001/avstream/channel=1/stream=0.sdp
      } else if (cameraBrandName == "UnV") {
        if (selectedDevice.CCTVTypeID == "4") {
          liveStreamType = getValues("liveStreamTypeNumber") == "Main" ? "1" : getValues("liveStreamTypeNumber") == "Sub" ? "2" : "3"
          temp =
            "rtsp://" +
            getValues("userName") +
            ":" +
            getValues("password") +
            "@" +
            getValues("privateIPAddress") +
            ":" +
            getValues("port") +
            "/media/video" +
            liveStreamType;
          // getValues("channelNumber");
        } else {
          temp =
            "rtsp://" +
            getValues("userName") +
            ":" +
            getValues("password") +
            "@" +
            getValues("privateIPAddress") +
            ":" +
            getValues("port") +
            "/unicast/c" +
            getValues("channelNumber") +
            "/s" + liveStreamType + "/live";
        }
      } else if (cameraBrandName == "Unknown") {
        // "rtsp://[Username]:[Password]@[IpAddress]:[Port]/Streaming/channels/[ChannelNo]"
        let link = cameradeviceslist[0].LiveRTSPURL
        temp = link

        const replacements = {
          "[Username]": getValues("userName"),
          "[Password]": getValues("password").replaceAll("@", "%40"),
          "[IpAddress]": getValues("privateIPAddress"),
          "[Port]": getValues("port"),
          "[ChannelNo]": getValues("channelNumber"),
          "[LiveStreamType]": liveStreamType
        };

        for (const [placeholder, value] of Object.entries(replacements)) {
          temp = temp.split(placeholder).join(value);
        }
        setUnknownBrandUrl(temp)

      } else if (cameraBrandName == "Raysharp") {
        // =>rtsp://admin:admin123.@73.14.118.111:3311/rtsp/streaming?channel=01&subtype=0 ---new
        //rtsp://admin:admin123.@73.14.118.111:3311/rtsp/streaming?channel=01&subtype=1 --new
        temp =
          "rtsp://" +
          getValues("userName") +
          ":" +
          getValues("password") +
          "@" +
          getValues("privateIPAddress") +
          ":" +
          getValues("port") +
          "/rtsp/streaming?channel=" +
          getValues("channelNumber") +
          "&subtype=" + liveStreamType;



        //  => "rtsp://192.168.1.100:554/rtsp/streaming?channel=1&subtype=0&token=123"---old 
        // temp =
        //   "rtsp://" +
        //   getValues("userName") +
        //   ":" +
        //   getValues("password") +
        //   "@" +
        //   getValues("privateIPAddress") +
        //   ":" +
        //   getValues("port") +
        //   "/rtsp/streaming?channel=" +
        //   getValues("channelNumber") +
        //   "&subtype=" + liveStreamType + "&token=123";
      }
      else if (cameraBrandName == "Hikvision-Hybrid") {
        temp =
          "rtsp://" +
          getValues("userName") +
          ":" +
          getValues("password") +
          "@" +
          getValues("privateIPAddress") +
          ":" +
          getValues("port") +
          "/Streaming/Channels/" +
          getValues("channelNumber") +
          liveStreamType;

      }

      if (selectedDevice.RTSPURL === undefined) {
        //add condition
        setValue("rTSPURL", temp?.replace(/%40/g, "@").replace(/%38/g, "&").replace(/%26/g, "&"))
        setrtspURLError('')
      } else if (selectedDevice.RTSPURL !== temp) {
        setValue("rTSPURL", temp?.replace(/%40/g, "@").replace(/%38/g, "&").replace(/%26/g, "&"))
        setrtspURLError('')
      } else {
        setValue("rTSPURL", temp?.replace(/%40/g, "@").replace(/%38/g, "&").replace(/%26/g, "&"))
        setrtspURLError('')
      }
    } else {
      // setValue("rTSPURL", "")
    }
  }, [watch("CameraBrandId"), watch("privateIPAddress"), watch("userName"), watch("password"), watch("port"), watch("channelNumber"), watch("liveStreamTypeNumber"), isChannelNumberEdited]);

  useEffect(() => {
    if (site.SiteID != 0) {
      setInterfaceTab(false);
    } else {
      setInterfaceTab(true);
    }
  }, [site])

  useEffect(() => {
    if (interfaceDevice.InterfaceId != 0 && site.SiteID != 0) {
      setDisableTab(false);
    } else {
      setDisableTab(true);
    }
  }, [interfaceDevice, site])

  useEffect(() => {
    if (selectedDevice && selectedDevice != null) {
      console.log("interfaceDevice", interfaceDevice)
      if (selectedDevice.DeviceTypeID == 3 || selectedDevice.DeviceTypeID == 4 || selectedDevice.DeviceTypeID == 5) {
        let arr = devicesmasterlist.panelTypeMasterList.filter(item => item.panelName == interfaceDevice.Interfacesubtypename);
        let temp = arr[0].panelMasterID;
        console.log("interfaceDevice", temp)
        setValue("PanelMasterId", temp)
      } else {
        setValue("PanelMasterId", selectedDevice.panelMasterId || selectedDevice.PanelMasterId || selectedDevice.cameraBrandId)
      }
      // setValue("PanelMasterId", selectedDevice.panelMasterId || selectedDevice.PanelMasterId || selectedDevice.cameraBrandId)
      setValue("SiteID", selectedDevice.SiteID)
      setValue("privateIPAddress", selectedDevice.privateIPAddress)
      setValue("communicationPort", selectedDevice.communicationPort)
      setValue("httpPort", selectedDevice.httpPort)
      // setValue("cameraName", selectedDevice.CameraName || selectedDevice.cameraName) 
      setValue("CCTVTypeID", selectedDevice.CCTVTypeID);
      setValue("TimeZoneIdDevice", selectedDevice.TimeZoneIdDevice)
      if (interfaceType === NVRDVR) {
        setValue("cameraName", selectedDevice.CameraName)
      } else {
        setValue("cameraName", selectedDevice.CameraName || selectedDevice.cameraName)
      }
    }
  }, [selectedDevice]);

  useEffect(() => {
    setValue("InterfaceName", interfaceDevice.InterfaceName);
    setValue("InterfaceType", interfaceDevice.Interfacetypename || interfaceDevice.InterfaceType);
    setValue("InterfaceSubType", interfaceDevice.Interfacesubtypename || interfaceDevice.InterfaceSubType);
    setValue("EntityTypeID", interfaceDevice.EntityTypeID || interfaceDevice.InterfaceType);
    setValue("InterfaceIP", interfaceDevice.InterfaceIP);
    setValue("TimeZoneId", interfaceDevice.TimeZoneId);
    setValue("InterfacePort", interfaceDevice.InterfacePort);
    setValue("InterfaceUsername", interfaceDevice.UserName);
    setValue("InterfacePassword", interfaceDevice.Password);
    setValue("LiveRTSPURL", interfaceDevice.LiveRTSPURL);
    setRtspValueEntered(interfaceDevice.LiveRTSPURL)
    setRtspValue(interfaceDevice.LiveRTSPURL)
    setValue("PlaybackRTSPURL", interfaceDevice.PlaybackRTSPURL);
    setPlaybackValue(interfaceDevice.PlaybackRTSPURL)
    setPlaybackValueEntered(interfaceDevice.PlaybackRTSPURL)
    setValue("", interfaceDevice.InterfaceId);
    setValue("CCTVTypeIDInterface", interfaceDevice.CCTVTypeIDInterface);
    setValue("CCTVType", interfaceDevice.CCTVType);
    if (interfaceDevice.IsTwoWayEnabled == "1") {
      setValue("InterfaceTwoWayEnabled", 1)
      setParentIsEnablingTwoWayInterface(true);
    } else {
      setValue("InterfaceTwoWayEnabled", 0)
      setParentIsEnablingTwoWayInterface(false);
    }

    if (interfaceDevice.CheckPlaybackUTCTime == "1") {
      setValue("PlaybackTimeStampsToUTC", 1)
      setParentIsEnablingPlaybackTimeStampsToUTC(true);
    } else {
      setValue("PlaybackTimeStampsToUTC", 0)
      setParentIsEnablingPlaybackTimeStampsToUTC(false);
    }

    if (interfaceDevice.CheckPlaybackLongTime == "1") {
      setValue("PlaybackLongDateFormat", 1)
      setParentIsEnablingPlaybackLongDateFormat(true);
    } else {
      setValue("PlaybackLongDateFormat", 0)
      setParentIsEnablingPlaybackLongDateFormat(false);
    }

    if (interfaceDevice.CheckInternalHooter == "1") {
      setValue("CheckInternalHooter", 1)
      setParentIsEnablingInternalHooter(true);
    } else {
      setValue("CheckInternalHooter", 0)
      setParentIsEnablingInternalHooter(false);
    }

    if (interfaceDevice.CheckExternalHooter == "1") {
      setValue("CheckExternalHooter", 1)
      setParentIsEnablingExternalHooter(true);
    } else {
      setValue("CheckExternalHooter", 0)
      setParentIsEnablingExternalHooter(false);
    }

    if (interfaceDevice.IsHooterEnabled == "1") {
      setValue("IsHooterEnabled", 1)
      setParentPanelIsEnablingHooter(true);
    } else {
      setValue("IsHooterEnabled", 0)
      setParentPanelIsEnablingHooter(false);
    }


    if (interfaceDevice.InterfaceId == 0) {
      setEnableHooter(false)
      setDisplayCamera(false)
      setDisplayLivePlaybackfield(false)
      setDisabledEntity(false)
    }
    else {
      setDisabledEntity(true)
    }
    if (interfaceDevice.InterfaceType == 0 || interfaceDevice.Interfacetypename == "CCTV") {
      setInterfaceType(NVRDVR);
      setBulkUpload(false);
    } else if (interfaceDevice.Interfacetypename == "Alarm Panel") {
      setInterfaceType(PANEL);
      setBulkUpload(false);
    } else {
      setInterfaceType(ACS);
      setBulkUpload(false);
    }
    if (interfaceDevice.CCTVType == "Camera") {
      if (interfaceDevice.Interfacesubtypename == "Unknown") {
        setDisplayCamera(false)
        setEnableHooter(false)
      } else {
        setDisplayCamera(true)
        setEnableHooter(false)
      }
    } else if (interfaceDevice.EntityTypeID == 0 && interfaceDevice.Interfacetypename == "Alarm Panel") {
      setDisplayCamera(false)
      setEnableHooter(true)
    }
    else if (interfaceDevice.EntityTypeID == 0) {
      setDisplayCamera(false)
      setEnableHooter(false)
    }
    else if (interfaceDevice.Interfacesubtypename == "Unknown") {
      setDisplayCamera(false)
      setEnableHooter(false)
    }
    else {
      setDisplayCamera(false)
      setEnableHooter(true)
    }

    if (interfaceDevice.Interfacetypename == "CCTV") {
      if (interfaceDevice.Interfacesubtypename == "Unknown") {
        setDisplayTwoWay(false);
        setDisplayEntity(true);
      } else {
        setDisplayTwoWay(true);
        setDisplayEntity(true);
      }
    } else {
      setDisplayTwoWay(false);
      setDisplayEntity(false);
    }
  }, [interfaceDevice])

  useEffect(() => {
    setValue("InterfaceSubType", interfaceDevice.Interfacesubtypename || interfaceDevice.InterfaceSubType);
    // this is for demo/unknown
    if (interfaceDevice.Interfacesubtypename == "Unknown") {
      setDisplayLivePlaybackfield(true);
    } else {
      setDisplayLivePlaybackfield(false);
    }

  }, [interfaceSubTypeData])

  useEffect(() => {
    setValue("EntitySubtype", interfaceDevice.EntitySubtype || interfaceDevice.InterfaceType);
  }, [cctvTypeData])

  useEffect(() => {
    if (watch('InterfaceType') == "Alarm Panel") {
      setInterfaceType(PANEL);
      setBulkUpload(false);
    } else if (watch('InterfaceType') == "ACS") {
      setInterfaceType(ACS);
      setBulkUpload(false);
    } else {
      setInterfaceType(NVRDVR);
      setBulkUpload(false);
    }
  }, [watch('InterfaceType')])
  //#endregion 

  const handleRefClick = () => {
    if (activeTab !== SITES) {
      setTimeout(() => {
        siteRef?.current?.scrollIntoView({
          behavior: 'smooth',
          block: 'start',
          inline: 'nearest',
        })
      }, 100)
    }
  }

  const getAssociateACSList = (devicedt) => {
    let obj = {
      "action": "getAssociatedCamerasACSList",
      "deviceId": devicedt.ParentCameraID,
      "userUniqueId": null,
      "clientId": devicedt.clientId,
      "branchId": devicedt.BranchID
    }
    deviceconfigcrud(obj)
      .then((res) => {
        if (res.status == "Ok") {
          console.log('getAssociateACSList', res.message);
          let isAllSelected = true;
          let custTemp = res.message.map((item, index) => {
            let obj;
            console.log('getAssociateACSList', item.isSelected);
            if (item.isSelected == false) {
              isAllSelected = false;
              obj = {
                ...item,
                isChecked: false
              }
            } else {
              obj = {
                ...item,
                isChecked: true
              }
            }
            console.log("getAssociateACSList", obj)
            return obj;
          })
          setAcsList(custTemp);
          setAcsMasterList(custTemp);
          setCameraCount(custTemp.length);
          if (isAllSelected) {
            setCameraSelectAll(true)
          } else {
            setCameraSelectAll(false)
          }
        }
      })
  }


  const searchCameraHandler = (e, type) => {
    let searchText = e.target.value;
    if (type == "Camera") {
      setCameraSearch(searchText);
      let arr = acsMasterList.filter((item) => item.cameraName.toLowerCase().includes(searchText.toLowerCase()));
      let isChecked = true;
      for (let i = 0; i < arr.length; i++) {
        if (arr[i].isChecked == true) {
          continue;
        } else {
          isChecked = false;
          break;
        }
      }
      setCameraSelectAll(isChecked);
      setAcsList(arr);
      setCameraCount(arr.length);
    }
  }

  const handleCameraSelectAll = () => {
    const tempArr = acsList.map((item, index) => {
      let obj = {
        ...item,
        isChecked: !cameraSelectAll
      }
      return obj;
    })

    let customerOne = acsMasterList.map((item) => {
      let objcustomer;
      if (cameraSelectAll) {
        if (!item.cameraName.toLowerCase().includes(cameraSearch.toLowerCase())) {
          objcustomer = {
            ...item
          }
        } else {
          objcustomer = {
            ...item,
            isChecked: false
          }
        }
      } else {
        if (!item.cameraName.toLowerCase().includes(cameraSearch.toLowerCase())) {
          objcustomer = {
            ...item,
          }
        } else {
          objcustomer = {
            ...item,
            isChecked: true
          }
        }
      }
      return objcustomer;
    })

    setCameraSelectAll(!cameraSelectAll);
    setAcsList(tempArr);
    setAcsMasterList(customerOne);
    let customerArr = customerOne.filter((item) => item.isChecked).map((i) => i.deviceId);
    let clientIds = customerArr.join(',');
    // if (customerArr.length != 0) {
    //   GetPanelFilterData("customer", clientIds).then((resp) => {
    //     if (resp.Status == "success") {
    //       siteIsChecked(resp.Data.LstSite);
    //       setSiteSelectAll(false);
    //     }
    //   })
    // }

  };

  const customerCheckHandler = (e, id) => {
    let arr = acsList.map((item) => {
      let obj;
      if (item.deviceId == id) {
        obj = {
          ...item,
          isChecked: !item.isChecked
        }
      } else {
        obj = {
          ...item
        }
      }
      return obj;
    })

    let masterCustomer = acsMasterList.map((item) => {
      let obj;
      if (item.deviceId == id) {
        obj = {
          ...item,
          isChecked: !item.isChecked
        }
      } else {
        obj = {
          ...item
        }
      }
      return obj;
    })

    let customerArr = arr.filter((item) => item.isChecked).map((i) => i.deviceId);
    let clientIds = customerArr.join(',');
    // if (customerArr.length != 0) {
    //   GetPanelFilterData("customer", clientIds).then((resp) => {
    //     if (resp.Status == "success") {
    //       siteIsChecked(resp.Data.LstSite);
    //       setSiteSelectAll(false);
    //     }
    //   })
    // }
    // else {
    //   GetPanelFilterData("all", "").then((resp) => {
    //     if (resp.Status == "success") {
    //       siteIsChecked(resp.Data.LstSite);
    //       setSiteSelectAll(false);
    //     }
    //   })
    // }
    setAcsMasterList(masterCustomer);
    setAcsList(arr);

  }

  const associatetoACS = (selectedDevice) => {
    var createdtime = ConvertDateToUTC(new Date())
    let userid = localStorage.getItem('UserID')
    let selectedItems = acsMasterList.filter(j => j.isChecked).map((i) => i.deviceId);
    if(selectedItems.length > 4){
      ErrorToast(`Maximum only 4 cameras can be associated, you have associated ${selectedItems.length} cameras`)
        return;
    }
    var obj = {
      "action": "associateCamerasToACS",
      "associatedCameraIds": selectedItems,
      "deviceId": selectedDevice.ParentCameraID,
      "createdBy": userid,
      "createdOn": createdtime,
      "userUniqueId": null,
    }
    deviceconfigcrud(obj).then((resp) => {
      if (resp && resp.status === "Ok") {
        SuccessToast(resp.message)
        setEditAssociate(false);
      }
      else {
        ErrorToast('Error occurred while associating the device!')
      }
    })
  }

  const cancelACSAssociation = () => {
    Swal.fire({
      title: 'Are you sure?',
      text: "Do you want to cancel the changes made?",
      icon: 'warning',
      showCancelButton: true,
      confirmButtonColor: '#027aaa',
      cancelButtonColor: '#d33',
      confirmButtonText: 'Yes, Leave!'
    }).then((result) => {
      if(result.isConfirmed){
        setCameraSearch("")
        setEditAssociate(false);
        getAssociateACSList(selectedDevice)
      }
    })
  }

  const handleInterfaceRefClick = () => {
    if (activeTab !== INTERFACES) {
      setTimeout(() => {
        interfaceRef?.current?.scrollIntoView({
          behavior: 'smooth',
          block: 'start',
          inline: 'nearest',
        })
      }, 100)
    }
  }

  const clearSiteFields = () => {
    setCustomerError('');
    setSiteError('');
    setCountryError('');
    setStateError('');
    setCityError('');
    setRegionError('');
    setPincodeError('');
    setAddressError('');
    setAreaError('');
    setBranchidError('');
    // setSiteTypeError('');
    setInputValidationError('');
  }

  const clearInterfaceFields = () => {
    setInterfaceError('');
    setInterfaceTypeError('');
    setInterfaceSubtypeError('');
    setInterfaceEntitySubtypeError('');
    setInterfaceIPError('');
    setInterfacePortError('');
    setInterfaceUserError('');
    setInterfacePasswardError('');
    setLiveStreamFormatError('');
    setPlaybackStreamFormatError('');
    setInterfaceTimeZoneError('');
  }

  const clearCameraFields = () => {
    setCommunicationPortError('');
    setChannelNumberError('');
    setLiveStreamTypeError('')
    setPlaybackStreamTypeError('')
    setrtspURLError('');
    setDeviceNameError('');
    setHttpPotError('')
    setIdentifierError('');
  }

  const handleBulkUploadClick = (e) => {
    setBulkUpload(true);
    setBulkDevToUpload([]);
    setIsSingleOrBulkSelection("bulk_upload");
  };

  const handleSingleUploadClick = () => {
    setBulkUpload(false);
    setIsSingleOrBulkSelection("single_device");
  };

  useImperativeHandle(ref, () => ({
    isDataChanged() {
      return (
        getValues("SiteName") != site.SiteName ||
        getValues("BranchID") != site.BranchID ||
        getValues("SiteAddress") != site.SiteAddress ||
        getValues("clientID") != site.clientID ||
        getValues("CityName", site.City) ||
        getValues("SiteRegionName", site.Region) ||
        getValues("SitePincode", site.pincode) ||
        getValues("SiteAddress", site.Address) ||
        getValues("SiteArea", site.Area) ||
        getValues("CountryName", site.CountryName) ||
        getValues("StateName", site.StateName) ||
        getValues("SiteModeID", site.SiteModeID)
      );
    },
  }));

  function isDataChanged() {
    return (
      getValues("SiteName") != site.SiteName ||
      getValues("BranchID") != site.BranchID ||
      getValues("SiteAddress") != site.SiteAddress ||
      getValues("clientID") != site.clientID ||
      getValues("CityName", site.City) ||
      getValues("SiteRegionName", site.Region) ||
      getValues("SitePincode", site.pincode) ||
      getValues("SiteAddress", site.Address) ||
      getValues("SiteArea", site.Area) ||
      getValues("CountryName", site.CountryName) ||
      getValues("StateName", site.StateName) ||
      getValues("SiteModeID", site.SiteModeID)
    );
  }

  const onCancel = (e) => {
    e.stopPropagation();
    if (isDataChanged()) {
      Swal.fire({
        title: "Are you sure?",
        text: "Do you want to cancel the changes made?",
        icon: "warning",
        showCancelButton: true,
        confirmButtonColor: "#027aaa",
        cancelButtonColor: "#d33",
        confirmButtonText: "Yes, Leave!",
        allowOutsideClick: false,
      }).then((result) => {
        if (result.isConfirmed) {
          onSelectSite(site.SiteID, true);
          setValue("SiteName", site.SiteName);
          setValue("BranchID", site.BranchID);
          setValue("SiteAddress", site.SiteAddress);
          setValue("clientID", site.clientID);
          setIsSiteEdit(false);
          // setSelectedUserRole([])
          setValue("CityName", site.City);
          setValue("SiteRegionName", site.Region);
          setValue("SitePincode", site.pincode);
          setValue("SiteAddress", site.Address);
          setValue("SiteArea", site.Area);
          setValue("SiteModeID", site.SiteModeID);
          // setValue("CountryName", site.CountryName);
          let tempCountryDetails = country?.filter(item => {
            return item.CountryMasterId == site.CountryId
          })
          if (tempCountryDetails?.length > 0) {
            setValue("CountryName", tempCountryDetails[0].CountryName);

          } else {
            setValue("CountryName", site.CountryName);
          }

          let tempCountryStateDetails = countryState?.filter(item => {
            return item.StateID == site.StateId
          })
          if (tempCountryStateDetails?.length > 0) {
            setValue("StateName", tempCountryStateDetails[0].StateName);
          } else {
            setValue("StateName", site.StateName);
          }
          clearSiteFields();
        }
      });
    } else {
      setIsSiteEdit(false);
    }
  };

  function isDataChangedInterface() {
    return (
      getValues("InterfaceName") != interfaceDevice.InterfaceName ||
      getValues("InterfaceType") != interfaceDevice.Interfacetypename ||
      getValues("InterfaceSubType") != interfaceDevice.Interfacesubtypename ||
      getValues("InterfaceIP") != interfaceDevice.InterfaceIP ||
      getValues("TimeZoneId") != interfaceDevice.TimeZoneId ||
      getValues("InterfacePort", interfaceDevice.InterfacePort) ||
      getValues("InterfaceUsername", interfaceDevice.UserName) ||
      getValues("InterfacePassword", interfaceDevice.Password) ||
      getValues("InterfaceTwoWayEnabled", interfaceDevice.IsTwoWayEnabled) ||
      getValues("CheckInternalHooter", interfaceDevice.CheckInternalHooter) ||
      getValues("CheckExternalHooter", interfaceDevice.CheckExternalHooter) ||
      getValues("IsHooterEnabled", interfaceDevice.IsHooterEnabled) ||
      getValues("PlaybackTimeStampsToUTC", interfaceDevice.CheckPlaybackUTCTime) ||
      getValues("PlaybackLongDateFormat", interfaceDevice.CheckPlaybackLongTime) ||
      getValues("EntityTypeID ", interfaceDevice.EntityTypeID) ||
      getValues("LiveRTSPURL", interfaceDevice.LiveRTSPURL) ||
      getValues("PlaybackRTSPURL", interfaceDevice.PlaybackRTSPURL) ||
      getValues("CCTVType", interfaceDevice.CCTVType)

    );
  }
  const onCancelInterface = (e) => {
    e.stopPropagation();
    if (isDataChangedInterface()) {
      Swal.fire({
        title: "Are you sure?",
        text: "Do you want to cancel the change made?",
        icon: "warning",
        showCancelButton: true,
        confirmButtonColor: "#027aaa",
        cancelButtonColor: "#d33",
        confirmButtonText: "Yes, Leave!",
        allowOutsideClick: false,
      }).then((result) => {
        if (result.isConfirmed) {
          // setValue("SiteID", interfaceDevice.SiteID)
          setValue("InterfaceName", interfaceDevice.InterfaceName)
          setValue("InterfaceType", interfaceDevice.Interfacetypename)
          setValue("InterfaceSubType", interfaceDevice.Interfacesubtypename)
          setValue("InterfaceIP", interfaceDevice.InterfaceIP)
          setValue("TimeZoneId", interfaceDevice.TimeZoneId)
          setValue("InterfacePort", interfaceDevice.InterfacePort)
          setValue("InterfaceUsername", interfaceDevice.UserName)
          setValue("InterfacePassword", interfaceDevice.Password)
          setValue("LiveRTSPURL", interfaceDevice.LiveRTSPURL)
          setRtspValue(interfaceDevice.LiveRTSPURL)
          setValue("PlaybackRTSPURL", interfaceDevice.PlaybackRTSPURL)
          setPlaybackValue(interfaceDevice.PlaybackRTSPURL)
          setValue("EntityTypeID", interfaceDevice.EntityTypeID)
          setValue("CCTVType", interfaceDevice.CCTVType)
          setIsInterfaceEdit(false);
          clearInterfaceFields();
          if (interfaceDevice.IsTwoWayEnabled == "1") {
            setValue("InterfaceTwoWayEnabled", 1);
            setParentIsEnablingTwoWayInterface(true);
          } else {
            setValue("InterfaceTwoWayEnabled", 0);
            setParentIsEnablingTwoWayInterface(false);
          }

          if (interfaceDevice.CheckPlaybackUTCTime == "1") {
            setValue("PlaybackTimeStampsToUTC", 1);
            setParentIsEnablingPlaybackTimeStampsToUTC(true);
          } else {
            setValue("PlaybackTimeStampsToUTC", 0);
            setParentIsEnablingPlaybackTimeStampsToUTC(false);
          }

          if (interfaceDevice.CheckPlaybackLongTime == "1") {
            setValue("PlaybackLongDateFormat", 1);
            setParentIsEnablingPlaybackLongDateFormat(true);
          } else {
            setValue("PlaybackLongDateFormat", 0);
            setParentIsEnablingPlaybackLongDateFormat(false);
          }



          // else {
          // }
          if (interfaceDevice.CheckInternalHooter == "1") {
            setValue("CheckInternalHooter", 1);
            setParentIsEnablingInternalHooter(true);
          } else {
            setValue("CheckInternalHooter", 0);
            setParentIsEnablingInternalHooter(false);
          }

          if (interfaceDevice.CheckExternalHooter == "1") {
            setValue("InterfaceExternalHooter", 1);
            setParentIsEnablingExternalHooter(true);
          } else {
            setValue("InterfaceExternalHooter", 0);
            setParentIsEnablingExternalHooter(false);
          }

          if (interfaceDevice.IsHooterEnabled == "1") {
            setValue("IsHooterEnabled", 1);
            setParentPanelIsEnablingHooter(true);
          } else {
            setValue("IsHooterEnabled", 0);
            setParentPanelIsEnablingHooter(false);
          }
        }
        else {
          setIsInterfaceEdit(true);
        }
      })
    }
  }

  function isDataChangedDevice() {
    return (
      getValues("SiteID") != selectedDevice.SiteID ||
      getValues("cameraName") != selectedDevice.CameraName ||
      getValues("privateIPAddress") != selectedDevice.privateIPAddress ||
      getValues("communicationPort") != selectedDevice.communicationPort ||
      getValues("httpPort") != selectedDevice.httpPort ||
      getValues("userName") != selectedDevice.userName ||
      getValues("userName") != selectedDevice.camUserName ||
      getValues("password") != selectedDevice.password ||
      getValues("password") != selectedDevice.camPassword ||
      getValues("port") != selectedDevice.port ||
      getValues("port") != selectedDevice.camPort ||
      getValues("channelNumber") != selectedDevice.channelNum ||
      // getValues("playbackChannelNumber") != selectedDevice.playbackChannelNum ||
      getValues("liveStreamTypeNumber") != selectedDevice.SubTypeLive ||
      getValues("playbackStreamTypeNumber") != selectedDevice.SubTypePlayback ||
      getValues("rTSPURL") != selectedDevice.RTSPURL ||
      getValues("location") != selectedDevice.Location ||
      getValues("ParentCameraID") != selectedDevice.ParentCameraID ||
      getValues("CameraBrandId") != selectedDevice.cameraBrandId ||

      getValues("CCTVTypeID") != selectedDevice.CCTVTypeID ||
      getValues("TimeZoneIdDevice") != selectedDevice.TimeZoneIdDevice
    );
  }

  const onCancelDevice = (e) => {
    e.stopPropagation();
    if (isDataChangedDevice()) {
      Swal.fire({
        title: "Are you sure?",
        text: "Do you want to cancel the change made?",
        icon: "warning",
        showCancelButton: true,
        confirmButtonColor: "#027aaa",
        cancelButtonColor: "#d33",
        confirmButtonText: "Yes, Leave!",
        allowOutsideClick: false,
      }).then((result) => {
        if (result.isConfirmed) {
          setValue("SiteID", selectedDevice.SiteID)
          setValue("cameraName", selectedDevice.CameraName)
          setValue("privateIPAddress", selectedDevice.privateIPAddress)
          setValue("communicationPort", selectedDevice.communicationPort)
          setValue("httpPort", selectedDevice.httpPort)
          setValue("userName", selectedDevice.userName || selectedDevice.camUserName)
          setValue("password", selectedDevice.password || selectedDevice.camPassword)
          setValue("port", selectedDevice.port || selectedDevice.camPort)
          setValue("channelNumber", selectedDevice.channelNum)
          // setValue("playbackChannelNumber", selectedDevice.playbackChannelNum)

          let tempCameraBrandId = devicesmasterlist?.cameraTypeMasterList?.filter(item => {
            return item.brandId == selectedDevice.cameraBrandId || selectedDevice.CameraBrandId
          })
          let cameraBrandName = "";
          if (tempCameraBrandId != undefined) {
            cameraBrandName = tempCameraBrandId[0]?.brandName
          } else {
            cameraBrandName = "";
          }
          //New
          if (cameraBrandName == "Hikvision" || cameraBrandName == "Hikvision-Hybrid") {
            let liveSubType = selectedDevice.SubTypeLive == "01" ? "Main" : selectedDevice.SubTypeLive == "02" ? "Sub" : selectedDevice.SubTypeLive == "03" ? "Ter" : ""
            setValue("liveStreamTypeNumber", liveSubType)
            let playbackSubType = selectedDevice.SubTypePlayback == "01" ? "Main" : selectedDevice.SubTypePlayback == "02" ? "Sub" : selectedDevice.SubTypePlayback == "03" ? "Ter" : ""
            setValue("playbackStreamTypeNumber", playbackSubType)
          } else {
            let liveSubType = selectedDevice.SubTypeLive == "0" ? "Main" : selectedDevice.SubTypeLive == "1" ? "Sub" : selectedDevice.SubTypeLive == '2' ? "Ter" : ""
            setValue("liveStreamTypeNumber", liveSubType)
            let playbackSubType = selectedDevice.SubTypePlayback == "0" ? "Main" : selectedDevice.SubTypePlayback == "1" ? "Sub" : selectedDevice.SubTypePlayback == "2" ? "Ter" : ""
            setValue("playbackStreamTypeNumber", playbackSubType)
          }


          setValue("rTSPURL", selectedDevice.RTSPURL && selectedDevice.RTSPURL.replace("%40", "@"))
          setValue("location", selectedDevice.Location)
          setValue("ParentCameraID", selectedDevice.ParentCameraID)
          setValue("CameraBrandId", selectedDevice.cameraBrandId)
          setValue("CCTVTypeID", selectedDevice.CCTVTypeID)
          setValue("TimeZoneIdDevice", selectedDevice.TimeZoneIdDevice)
          setValue("identifierName", selectedDevice.identifierName)
          setIsDeviceEdit(false);
          clearCameraFields();
        } else {
          setIsDeviceEdit(true);
        }
      })
    }
  }

  const pointerEvent = !isSiteEdit ? "none" : "auto"
  const cursor = !isSiteEdit ? "auto" : ""

  const addCustomer = (e) => {

    if (getValues('AddCustomerName') === '') {
      setAddCustomerError('Please Enter a valid Customer Name!')
      return
    }
    try {
      let addCustomerObj = {
        "ClientName": getValues('AddCustomerName')
      }
      addCustomerName(addCustomerObj)
        .then((resp) => {
          if (resp != undefined) {
            if (resp.status == "ok") {
              // setCountry(resp.message)
              SuccessToast("New customer name added!")
              getCliets()
              setValue('AddCustomerName', '')
              setShow(false)
            }
          }
        })
    } catch (error) {
      console.error(error)
    }
  }

  const handleCountryChange = (e) => {
    const selectedOption = e.target.options[e.target.selectedIndex];
    const selectedCountryValue = selectedOption.value;
    const selectedCountryValueId = selectedOption.getAttribute('id');
    try {
      getStateDetails(selectedCountryValueId).then((resp) => {
        if (resp != undefined) {
          if (resp.status == "ok") {
            setCountryState(resp.message)
          } else {
            setCountryState(null)
          }
        }
      })
    } catch (error) {
      console.error(error)
    }
  }

  const interfaceSubTypeHandler = (e) => {
    const selectedOption = e.target.options[e.target.selectedIndex];
    const selectedInterfaceType = selectedOption.value;
    const selectedInterfaceTypeId = selectedOption.getAttribute('id');
    try {
      getInterfaceSubTypeData(selectedInterfaceTypeId).then((resp) => {
        if (resp != undefined) {
          if (resp.Status == "success") {
            setInterfaceSubTypeData(resp.Data)
            if (selectedInterfaceTypeId == 1) {
              setInterfaceType(NVRDVR)
              setDisplayTwoWay(true)
              // setDisplayHooter(false)
              setEnableHooter(false)
              setValue("CCTVTypeIDInterface", 0);
              setDisplayEntity(true)
            }
            else if (selectedInterfaceTypeId == 3 || selectedInterfaceTypeId == 4 || selectedInterfaceTypeId == 5) {
              setInterfaceType(ACS);
              setDisplayTwoWay(false)
              setDisplayCamera(false)
              setEnableHooter(false);
              setDisplayEntity(false);
            }
            else {
              setInterfaceType(PANEL)
              setDisplayTwoWay(false)
              setDisplayCamera(false)
              //setDisplayHooter(true)
              setEnableHooter(true)
              setDisplayEntity(false);
            }
          } else {
            setInterfaceSubTypeData(null)
          }
        }
      })
    } catch (error) {
      console.error(error)
    }
  }

  const handleOptionChange = (option) => {
    setSelectedBrand(option);
    // Determine the placeholder for the next input based on the selected option
    if (+option === 1) {
      setChannelNumberPlaceholder("Channel Number* e.g. 101");
    } else if (+option === 2) {
      setChannelNumberPlaceholder("Channel Number* e.g. 1");
    } else {
      setChannelNumberPlaceholder("Channel Number* e.g. 1");
    }
  };

  const onNumberOnlyChange = (event) => {
    const keyCode = event.keyCode || event.which;
    const keyValue = String.fromCharCode(keyCode);
    const isValid = new RegExp("[0-9]").test(keyValue);
    if (!isValid) {
      event.preventDefault();
      return;
    }
  };

  const onSpecialChangeOnly = (event) => {
    const keyCode = event.keyCode || event.which;
    const keyValue = String.fromCharCode(keyCode);
    const isValid = new RegExp("^[A-Za-z0-9.\\-_ ]*$").test(keyValue);
    if (!isValid) {
      event.preventDefault();
      return;
    }
  }

  const onNumberOnlyChange2 = (event) => {
    const keyCode = event.keyCode || event.which;
    const keyValue = String.fromCharCode(keyCode);
    const isValid = new RegExp("[0-9.]").test(keyValue);
    if (!isValid) {
      event.preventDefault();
      return;
    }
  };

  const changeTab = (e, type) => {
    switch (type) {
      case SITES:
        setSearchTextSite('');
        break;

      case INTERFACES:
        setSearchTextInterface('');
        break;

      case DEVICES:
        setSearchTextDevice('');
        break;
    }
    setActiveTab(type)
  }

  const timeInterfaceZoneHandler = (e) => {

    try {
      GetmultiTimeZone().then((resp) => {
        if (resp != undefined) {
          if (resp.Status == "success") {
            setTimeZone(resp.Data)

          } else {
            setTimeZone(null)
          }
        }
      })
    } catch (error) {
      console.error(error)
    }
  }
  const RTSP = [
    {
      id: "Username",
      display: "[Username]",
    },
    {
      id: "Password",
      display: "[Password]",
    },
    {
      id: "IpAddress",
      display: "[IpAddress]",
    },
    {
      id: "Port",
      display: "[Port]",
    },
    {
      id: "ChannelNo",
      display: "[ChannelNo]",
    },
    {
      id: "LiveStreamType",
      display: "[LiveStreamType]",
    },
  ];

  const PLAYBACK = [
    {
      id: "Username",
      display: "[Username]",
    },
    {
      id: "Password",
      display: "[Password]",
    },
    {
      id: "IpAddress",
      display: "[IpAddress]",
    },
    {
      id: "Port",
      display: "[Port]",
    },
    {
      id: "ChannelNo",
      display: "[ChannelNo]",
    },
    {
      id: "PlaybackStreamType",
      display: "[PlaybackStreamType]",
    },
    /*   {
        id: "StartDate",
        display: "[StartDate]",
      },
      {
        id: "EndDate",
        display: "[EndDate]",
      },
      {
        id: "DateFormat",
        display: "[DateFormat]",
      }, */
    {
      id: "DateDD",
      display: "[DD]",
    },
    {
      id: "DateMM",
      display: "[MM]",
    },
    {
      id: "DateYY",
      display: "[YY]",
    },
    {
      id: "DateYYYY",
      display: "[YYYY]",
    },
    {
      id: "HoursHH",
      display: "[hh]",
    },
    {
      id: "MinutesMM",
      display: "[mm]",
    },
    {
      id: "SecondsSS",
      display: "[ss]",
    },
  ];

  // Refresh

  return (
    <div className="">
      <div className="">
        <ul className="progressbar">
          <li className="active" >
            <div className={`${activeTab === SITES ? "stepperIconContainer progressactive" : "stepperIconContainer"}`}
              onClick={(e) => {
                setFilteredSites(sites)
                changeTab(e, SITES)
                setInterfaceDataList(null)
                setcameradeviceslist(null)
                setpaneldeviceslist(null)
                setAcsDevicesList(null)
                let tempdeviceObj = {
                  "ParentCameraID": 0,
                  "cameraName": '',
                  "privateIPAddress": '',
                  "SiteID": 0,
                  "userName": '',
                  "password": '',
                  "port": '',
                  "channelNumber": '',
                  "liveStreamTypeNumber": '',
                  "playbackStreamTypeNumber": '',
                  "rTSPURL": '',
                  "location": '',
                  "PanelMasterId": 0,
                  "CameraBrandId": 0,
                  "communicationPort": '',
                  "httpPort": '',
                  "isTranscoded": '',
                  "isPlaybackTranscoded": '',
                  "isTwoWayEnabledDevice": '',
                  "interfaceId": '',
                  "CCTVTypeID": 0,
                  "TimeZoneIdDevice": 0,
                  "isPTZ": "",
                  "isPlaybackUTC": "",
                  "identifierName": ""
                }
                setSelectedDevice(tempdeviceObj);
                handleRefClick();
                let interfaceObjTemp = {
                  "siteid": 0,
                  "InterfaceId": 0,
                  "InterfaceName": '',
                  "InterfaceType": 0,
                  "InterfaceSubType": 0,
                  "InterfaceIP": '',
                  "InterfacePort": '',
                  "InterfaceUsername": '',
                  "InterfacePassword": '',
                  "UserId": 0,
                  "EntityTypeID": 0,
                  "CCTVTypeIDInterface": 0,
                  "InterfaceTwoWayEnabled": '',
                  "CheckInternalHooter": 0,
                  "CheckExternalHooter": 0,
                  "IsHooterEnabled": 0,
                  "PlaybackTimeStampsToUTC": '',
                  "PlaybackLongDateFormat": '',
                  "LiveRTSPURL": '',
                  "PlaybackRTSPURL": '',
                  "CCTVType": '',
                  "TimeZoneId": 0
                }
                setSelectedInterface(interfaceObjTemp);
                clearInterfaceFields();
              }}>
              <img src={activeTab === SITES ? SiteSelected : SiteDefault} alt="Sites" />
            </div>
            <span className="stepperText">
              Site <span style={{ color: "#8f8e8e", fontSize: "12px" }}>({filteredSites?.length})</span>
            </span>
          </li>
          <li className="active" >
            <div
              className={`${activeTab === SITES && interfaceTab ? "stepperIconContainer progressdisable" : activeTab === INTERFACES ? "stepperIconContainer progressactive" : "stepperIconContainer"}`}
              onClick={(e) => {
                if (interfaceTab) {
                  return
                } else {
                  getInterfaceData()
                  changeTab(e, INTERFACES)
                  if (interfaceDevice.InterfaceId == 0) {
                    setIsInterfaceEdit(true)
                  } else {
                    setIsInterfaceEdit(false)
                  }
                  setcameradeviceslist(null)
                  setpaneldeviceslist(null)
                  setAcsDevicesList(null)
                  let tempdeviceObj = {
                    "ParentCameraID": 0,
                    "cameraName": '',
                    "privateIPAddress": '',
                    "SiteID": 0,
                    "userName": '',
                    "password": '',
                    "port": '',
                    "channelNumber": '',
                    "liveStreamTypeNumber": '',
                    "playbackStreamTypeNumber": '',
                    "rTSPURL": '',
                    "location": '',
                    "PanelMasterId": 0,
                    "CameraBrandId": 0,
                    "communicationPort": '',
                    "httpPort": '',
                    "isTranscoded": '',
                    "isPlaybackTranscoded": '',
                    "isTwoWayEnabledDevice": '',
                    "interfaceId": '',
                    "CCTVTypeID": 0,
                    "TimeZoneIdDevice": 0,
                    "isPTZ": "",
                    "isPlaybackUTC": "",
                    "identifierName": ""
                  }
                  setSelectedDevice(tempdeviceObj);
                  handleInterfaceRefClick();
                  clearCameraFields();
                  clearSiteFields();
                }
              }}>
              <img
                src={activeTab === SITES && interfaceTab ? InterfaceDisable : activeTab === INTERFACES ? InterfaceSelected : InterfaceDefault}
                alt="interface icon"
                width={18} />
            </div>
            <span className={`${interfaceTab ? "StepperTextDisable" : "stepperText"}`}>
              Interface {interfaceDataList && interfaceDataList?.length > 0 && <span style={{ color: "#8f8e8e", fontSize: "12px" }}>({interfaceDataList?.length})</span>}
            </span>

          </li>

          <li className="active">
            <div className={`${disableTab ? "stepperIconContainer progressdisable" : activeTab === DEVICES ? "stepperIconContainer progressactive" : "stepperIconContainer"}`} onClick={(e) => {

              if (disableTab) {
                return
              } else {
                if (selectedDevice.ParentCameraID != 0) {
                  return;
                }
                changeTab(e, DEVICES)
                let temp = 0, otherTemp = 0;
                console.log("tabSwitch", interfaceDevice.InterfacetypeId)
                if (interfaceDevice.InterfacetypeId == 1) {
                  let arr = devicesmasterlist.cameraTypeMasterList.filter(item => item.brandName == interfaceDevice.Interfacesubtypename);
                  temp = arr[0].brandId;
                  let deviceObject = {
                    "ParentCameraID": 0,
                    "cameraName": interfaceDevice.InterfaceName,
                    "privateIPAddress": interfaceDevice.InterfaceIP,
                    "SiteID": interfaceDevice.siteid,
                    "userName": interfaceDevice.UserName,
                    "password": interfaceDevice.Password,
                    "port": interfaceDevice.InterfacePort,
                    "channelNumber": '',
                    "liveStreamTypeNumber": '',
                    "playbackStreamTypeNumber": '',
                    "rTSPURL": '',
                    "location": '',
                    "PanelMasterId": otherTemp,
                    "CameraBrandId": temp,
                    "communicationPort": '',
                    "httpPort": '',
                    "isTranscoded": 0,
                    "isPlaybackTranscoded": 0,
                    "isTwoWayEnabledDevice": 0,
                    "CCTVTypeID": interfaceDevice.EntityTypeID,
                    "TimeZoneIdDevice": interfaceDevice.TimeZoneId || 1,
                    "isPTZ": "" || 0,
                    "isPlaybackUTC": "",
                    "identifierName": ""
                  }
                  setSelectedDevice(deviceObject);
                  setIsDeviceEdit(true)
                  getdeviceslistdata()
                }
                else if (interfaceDevice.InterfacetypeId == 3 || interfaceDevice.InterfacetypeId == 4 || interfaceDevice.InterfacetypeId == 5) {
                  let arr = devicesmasterlist.panelTypeMasterList.filter(item => item.panelName == interfaceDevice.Interfacesubtypename);
                  temp = arr[0].panelMasterID;
                  console.log("tabSwitch", interfaceDevice)
                  let deviceObject = {
                    "ParentCameraID": 0,
                    "cameraName": "",
                    "privateIPAddress": interfaceDevice.InterfaceIP,
                    "SiteID": interfaceDevice.siteid,
                    "userName": interfaceDevice.UserName,
                    "password": interfaceDevice.Password,
                    "port": interfaceDevice.InterfacePort,
                    "channelNumber": '',
                    "liveStreamTypeNumber": '',
                    "playbackStreamTypeNumber": '',
                    "rTSPURL": '',
                    "location": '',
                    "PanelMasterId": temp,
                    "CameraBrandId": temp,
                    "communicationPort": '',
                    "httpPort": '',
                    "isTranscoded": 0,
                    "isPlaybackTranscoded": 0,
                    "isTwoWayEnabledDevice": 0,
                    "CCTVTypeID": 0,
                    "TimeZoneIdDevice": interfaceDevice.TimeZoneId || 1,
                    "isPTZ": "" || 0,
                    "isPlaybackUTC": "",
                    "identifierName": ""
                  }
                  console.log("tabSwitch", deviceObject)
                  setSelectedDevice(deviceObject);
                  setIsDeviceEdit(true)
                  getdeviceslistdata()
                }
                else {
                  let arr = devicesmasterlist.panelTypeMasterList.filter(item => item.panelName == interfaceDevice.Interfacesubtypename);
                  otherTemp = arr[0].panelMasterID
                  getdeviceslistdata()
                }
              }

            }}>
              <img src={disableTab ? DevicesDisable : activeTab === DEVICES ? DevicesSelected : DevicesDefault} alt="device icon" width={17} />
            </div>

            <span className={`${disableTab ? "StepperTextDisable" : "stepperText"}`}>
              Devices {interfaceType && (cameradeviceslist?.length > 0 || paneldeviceslist?.length > 0 || acsDevicesList?.length > 0) &&
                !disableTab &&
                <span style={{ color: "#8f8e8e", fontSize: "12px" }}>
                  ({interfaceType === NVRDVR ? cameradeviceslist?.length : interfaceType === ACS ? acsDevicesList?.length : paneldeviceslist?.length})</span>}
            </span>
          </li>
        </ul>
      </div>

      <div className="">
        {
          activeTab == SITES ?
            <div className="SiteMainContainer">

              <div className="SiteLeftContainer">
                <div className="m-2 HeaderSiteList">
                  <input
                    id="Search_txt_site"
                    className="SearchViewInput1"
                    type="text"
                    onChange={(e) => {
                      setSearchTextSite(e.target.value)
                      onSearchChange(e.target.value, "Site List")
                    }
                    }
                    placeholder="Search Site"
                    value={searchTextSite}
                  />
                  <div className="SearchTextDivConfigTool">
                    {
                      isDarkTheme ?
                        <img
                          className="EyeIcon"
                          src={SearchIcon}
                          alt="search icon"
                        /> :
                        <img
                          className="EyeIcon"
                          src={SearchIconWhite}
                          alt="search icon" height="15"
                        />
                    }

                  </div>
                </div>
                <div className="StepperSiteList" id="siteListDiv" >
                  {
                    filteredSites && (
                      filteredSites?.length > 0 ?
                        filteredSites.map((item) => {
                          return (
                            <div
                              ref={item.SiteID == site?.SiteID ? siteRef : null}
                              className={site?.SiteID === item.SiteID ? "OneList SelectedList" : "OneList"}
                              key={item.SiteID}
                              onClick={(e) => {
                                e.stopPropagation();
                                onSelectSite(item.SiteID, true);
                                setInterfaceDataList(null)
                                clearSiteFields()
                              }}
                            >
                              <p className="ListofText">
                                {item.SiteName}
                                <span className="SiteNameText"> - {item.BranchID}</span>
                              </p>
                              <div>

                              </div>
                            </div>
                          );
                        }) : <div className="no-data-found"><p>No Site Found.</p></div>
                    )
                  }
                  {!filteredSites && (
                    <div className="no-data-found">No Sites Available.</div>
                  )}
                </div>

              </div>
              <div className="SiteRightContainer">
                <h1 className="MainHeaderName">Site Details</h1>
                <div className="siteformContainer">
                  <div>
                    <form onSubmit={handleSubmit((e) => onSubmit(e, site.SiteID))}>
                      <div className="site-form" style={{ display: "flex", flexDirection: "column", alignItems: "center" }}>
                        <div className="inputMainContainer w40dot75rem">
                          <div style={{ display: "flex" }}>
                            <div className="inputTypeLabelContainer" style={{ width: "95%" }}>
                              <label className='formFieldLabels'>Select Customer</label>
                              <select
                                required
                                disabled={!isSiteEdit}
                                {...register("clientID")}
                                className="inputType ArrowSelectClass"
                                defaultValue={site.clientID}
                                onChange={(e) => {
                                  setValue("clientID", e.target.value);
                                  setCustomerError('')
                                }}
                              >
                                <option key="0" disabled={false} value="0">Select Customer*</option>
                                {clients &&
                                  clients.map((val) => (
                                    <option value={val.clientID} key={val.clientName}>
                                      {val.clientName}
                                    </option>
                                  ))}
                              </select>
                              {/* </div> */}
                            </div>
                            <OverlayTrigger
                              placement='bottom'
                              overlay={
                                <Tooltip>
                                  Add Customer
                                </Tooltip>
                              }
                            >
                              <button className="addCustomer" style={{ marginLeft: "10px" }} onClick={(e) => {
                                e.preventDefault()
                                setShow(!show)
                              }}>
                                <span className="ViewUpdate">
                                  <img src={AddNode} alt="add plus icon" />
                                </span>
                              </button>
                            </OverlayTrigger>
                          </div>
                          <p className="Error_P" style={{ float: "left" }}><span>{customerError}</span></p>
                        </div>

                        <div className="inputMainContainer w40dot75rem mt35">
                          <div className="inputTypeLabelContainer">
                            <label className='formFieldLabels'>Site Name</label>
                            <input
                              {...register("SiteName")}
                              className="inputType"
                              disabled={!isSiteEdit}
                              placeholder="Site Name*"
                              onKeyPress={(e) => {
                                onSpecialChangeOnly(e);
                                setSiteError('')
                              }}
                              onChange={() => { setSiteError('') }}
                            />
                            <p className="Error_P" style={{ float: "left" }}><span>{siteError}</span></p>
                          </div>
                        </div>

                        <div className="inputMainContainer w40dot75rem mt35">
                          <div className="inputTypeLabelContainer">
                            <label className='formFieldLabels'>Select Country</label>
                            <select
                              {...register("CountryName")}
                              disabled={!isSiteEdit}
                              className="inputType ArrowSelectClass"
                              onChange={(e) => {
                                handleCountryChange(e)
                                setCountryError('')
                              }}
                            >
                              <option key="0" disabled={false} value="0" id="0">Select Country*</option>
                              {country &&
                                country.map((val) => (
                                  <option value={val.CountryName} key={val.CountryMasterId} id={val.CountryMasterId}>
                                    {val.CountryName}
                                  </option>
                                ))}
                            </select>
                            <p className="Error_P" style={{ float: "left" }}><span> {countryError} </span></p>
                          </div>
                        </div>

                        <div className="inputMainContainer w40dot75rem mt35">
                          <div className="inputTypeLabelContainer">
                            <label className='formFieldLabels'>Select State</label>
                            <select
                              {...register("StateName")}
                              disabled={!isSiteEdit}
                              className="inputType ArrowSelectClass"
                              onChange={() => {
                                setStateError('')
                              }}
                            >
                              <option key="0" disabled={false} value="0">Select State*</option>
                              {countryState &&
                                countryState.map((val) => (
                                  <option value={val.StateName} key={val.StateID}>
                                    {val.StateName}
                                  </option>
                                ))}
                            </select>
                            <p className="Error_P" style={{ float: "left" }}><span> {stateError} </span></p>
                          </div>
                        </div>

                        <div className="inputMainContainer w40dot75rem mt35">
                          <div className="inputTypeLabelContainer">
                            <label className='formFieldLabels'>Select City</label>
                            <input
                              {...register("CityName")}
                              className="inputType"
                              disabled={!isSiteEdit}
                              placeholder="Select City*"
                              onKeyPress={(e) => {
                                onSpecialChangeOnly(e);
                                setCityError('')
                              }}
                              onChange={() => setCityError("")}
                            />
                            <p className="Error_P" style={{ float: "left" }}><span> {cityError} </span></p>
                          </div>
                        </div>

                        <div className="inputMainContainer w40dot75rem mt35">
                          <div className="inputTypeLabelContainer">
                            <label className='formFieldLabels'>Select Region</label>
                            <input
                              {...register("SiteRegionName")}
                              className="inputType"
                              disabled={!isSiteEdit}
                              placeholder="Select Region*"
                              onKeyPress={(e) => {
                                onSpecialChangeOnly(e);
                                setRegionError('')
                              }}
                              onChange={() => { setRegionError('') }}
                            />
                            <p className="Error_P" style={{ float: "left" }}><span> {regionError} </span></p>
                          </div>
                        </div>

                        <div className="inputMainContainer w40dot75rem mt35">
                          <div className="inputTypeLabelContainer">
                            <label className='formFieldLabels'>Pin Code</label>
                            <input
                              {...register("SitePincode")}
                              className="inputType"
                              disabled={!isSiteEdit}
                              onKeyPress={(e) => {
                                onNumberOnlyChange(e);
                                setPincodeError('')
                              }}
                              onChange={() => { setPincodeError('') }}
                              placeholder="Pin Code*"
                            />
                            <p className="Error_P" style={{ float: "left" }}><span> {pincodeError} </span></p>
                          </div>
                        </div>

                        <div className="inputMainContainer w40dot75rem mt35">
                          <div className="inputTypeLabelContainer">
                            <label className='formFieldLabels'>Address</label>
                            <input
                              maxLength={100}
                              {...register("SiteAddress")}
                              className="inputType"
                              disabled={!isSiteEdit}
                              placeholder="Address*"
                              onChange={() => setAddressError('')}
                            />
                            <p className="Error_P" style={{ float: "left" }}><span> {addressError} </span></p>
                          </div>
                        </div>

                        <div className="inputMainContainer w40dot75rem mt35">
                          <div className="inputTypeLabelContainer">
                            <label className='formFieldLabels'>Area</label>
                            <input
                              {...register("SiteArea")}
                              className="inputType"
                              disabled={!isSiteEdit}
                              placeholder="Area*"
                              onKeyPress={(e) => {
                                onSpecialChangeOnly(e);
                                setAreaError('')
                              }}
                              onChange={() => { setAreaError('') }}
                            />
                            <p className="Error_P" style={{ float: "left" }}><span> {areaError} </span></p>
                          </div>
                        </div>

                        <div className="inputMainContainer w40dot75rem mt35">
                          <div className="inputTypeLabelContainer">
                            <label className='formFieldLabels'>Branch ID</label>
                            <input
                              maxLength={50}
                              {...register("BranchID")}
                              className="inputType"
                              disabled={!isSiteEdit}
                              placeholder="Branch ID*"
                              onKeyPress={(e) => {
                                onSpecialChangeOnly(e);
                                setBranchidError('')
                              }}
                              onChange={() => { setBranchidError('') }}
                            />
                            <p className="Error_P" style={{ float: "left" }}><span> {branchidError} </span></p>
                          </div>
                        </div>

                        <div className="inputMainContainer w40dot75rem mt35">
                          <div className="inputTypeLabelContainer">
                            <label className='formFieldLabels'>Select Site Type</label>
                            <select
                              {...register("SiteModeID")}
                              disabled={!isSiteEdit || isAlreadyRetail}
                              className="inputType ArrowSelectClass"
                            >
                              defaultValue={site.SiteModeID}
                              onChange={(e) => { setValue("SiteModeID", e.target.value); }}
                              <option key="0" disabled={false} value={0} id="0"> Site Type</option>
                              {siteTypes &&
                                siteTypes.map((val) => (
                                  <option value={val.SiteModeID} key={val.SiteModeID}>
                                    {val.SiteMode}
                                  </option>
                                ))}
                            </select>
                          </div>
                        </div>

                        <div className="BulkText" hidden>
                          <div className="SentenceText">

                          </div>

                          <div className="ButtonText">
                            <button
                              className="AlertViewButton"
                              style={{ visibility: "collapse" }}
                            >
                              <p>Upload CSV</p>
                            </button>
                          </div>
                        </div>
                      </div>
                    </form>
                  </div>
                  <div>
                    {site.SiteID != 0 && (
                      <div className="">
                        <h1 className="MainHeaderName mb-0">Add User to Site</h1>
                        <div className="ManageSiteDiv">
                          <div className="DevicesHeader">
                            <p className="mb-0" style={{ width: "40%" }}>
                              Users
                              <br />
                            </p>
                            <p className="mb-0">
                              Configure Users to Site
                              <br />
                            </p>
                          </div>

                          <div className="site-group">
                            <div className="site-group-list"
                            >
                              {emeContactList &&
                                emeContactList.map((item) => {
                                  return (
                                    <div
                                      id={item.EmergencyContactID}
                                      key={item.EmergencyContactID}
                                      className={`cameraList user-list-item ${selectedERT &&
                                        item.EmergencyContactID ==
                                        selectedERT?.EmergencyContactID &&
                                        "selected-user"
                                        }`}
                                      onClick={(e) => {
                                        if (isSiteEdit)
                                          siteERTOnClick(item)
                                      }}
                                    >
                                      {item &&
                                        item.IsCustomerSPOC == true &&
                                        item.IsERT == false ? (
                                        <p className="mb-0">{item.ContactName} - SPOC</p>
                                      ) : (
                                        <p className="mb-0">
                                          {item.ContactName} - {item.ContactLevel}
                                        </p>
                                      )}

                                      <div style={{ float: "right", pointerEvents: pointerEvent, cursor: cursor }}>
                                        <img
                                          height={12}
                                          width={12}
                                          onClick={() => siteERTOnClick(item)}
                                          src={EditQc}
                                          className="m-1"
                                          alt="edit icon"
                                        ></img>
                                        <img
                                          height={12}
                                          width={12}
                                          src={DeleteQc}
                                          className="m-1"
                                          title="Delete"
                                          onClick={(e) => onDeleteERT(site.SiteID, item)}
                                          alt="delete icon"
                                        />
                                      </div>
                                    </div>
                                  );
                                })}
                            </div>
                            <div style={{ margin: "10px", textAlign: "end" }}>
                              <div className="AddMore" style={{ float: "left" }}>
                                <a
                                  disabled={!isSiteEdit}
                                  className="UserAddPlus mt-1"
                                  onClick={() => addNewERTMemberClick()}
                                >
                                  Add New Member +
                                </a>
                              </div>
                            </div>
                          </div>
                          <AddERTForm
                            onSubmit={onAddERTMemberClick}
                            ertLevels={ertLevels}
                            site={site}
                            selectedERT={selectedERT}
                            userOptions={userOptions}
                            onUserRoleChange={onUserRoleChange}
                            selectedUserRole={selectedUserRole}
                            setSelectedUserRole={setSelectedUserRole}
                            siteUserFieldsVisibility={siteUserFieldsVisibility}
                            isSiteEdit={isSiteEdit}
                          />
                        </div>
                      </div>
                    )}
                  </div>

                </div>
                <div style={{ textAlign: "center" }}>
                  {
                    site.SiteID != 0 &&
                    (
                      <button className="DataAccessEdit" style={{ width: "100px" }}
                        onClick={() => {
                          setSelectedSite(tempSiteObj);
                          setSelectedInterface(interfaceObj);
                          setIsSiteEdit(true);
                        }}
                      >
                        <span className="ViewUpdate">Add New</span>
                      </button>
                    )
                  }
                  {
                    site.SiteID != 0 && !isSiteEdit &&
                    (
                      <button className="DataAccessEdit" style={{ width: "100px" }}
                        onClick={() => setIsSiteEdit(true)}
                      >
                        <span className="ViewUpdate">Edit</span>
                      </button>
                    )
                  }
                  {
                    site.SiteID == 0 &&
                    (
                      <button className="DataAccessEdit" style={{ width: "100px" }}
                        onClick={handleSubmit((e) => onSubmit(e, site.SiteID))}
                        disabled={buttonDisable}
                      >
                        <span className="ViewUpdate">Save</span>
                      </button>
                    )
                  }
                  {
                    site.SiteID != 0 && isSiteEdit &&
                    (
                      <>
                        <button className="DataAccessEdit" style={{ width: "100px" }}
                          onClick={handleSubmit((e) => onSubmit(e, site.SiteID))}
                          disabled={buttonDisable}
                        >
                          <span className="ViewUpdate">Save</span>
                        </button>
                        <button className="DataAccessEdit" style={{ width: "100px" }} onClick={(e) => onCancel(e)} >
                          <span className="ViewUpdate">Cancel</span>
                        </button>
                      </>
                    )
                  }
                  {
                    site.SiteID != 0 && !isSiteEdit &&
                    (
                      <button className="DataAccessEdit" style={{ width: "100px" }}
                        onClick={(e) => onDeleteChange(e, site.SiteID, site.SiteName)}
                        disabled={buttonDisable}
                      >
                        <span className="ViewUpdate">Delete</span>
                      </button>
                    )
                  }
                </div>
              </div>
            </div>
            : activeTab == INTERFACES ?
              <div className="SiteMainContainer">
                <div className="SiteLeftContainer">
                  <div className="m-2 HeaderSiteList">
                    <input
                      id="Search_txt_interface"
                      className="SearchViewInput1"
                      type="text"
                      onChange={(e) => {
                        setSearchTextInterface(e.target.value)
                        onSearchChange(e.target.value, 'interface')
                      }
                      }
                      placeholder="Search Interface"
                      autoFocus
                      value={searchTextInterface}
                    />
                    <div className="SearchTextDivConfigTool">
                      {
                        isDarkTheme ?
                          <img
                            className="EyeIcon"
                            src={SearchIcon}
                            alt="search icon"
                          /> :
                          <img
                            className="EyeIcon"
                            src={SearchIconWhite}
                            alt="search icon" height="15"
                          />
                      }
                    </div>
                  </div>

                  <div className="StepperSiteList" id="interfaceListDiv">
                    {
                      interfaceDataList && (
                        interfaceDataList?.length > 0 ?
                          interfaceDataList.map((interfaceparam) => {
                            return (
                              <div
                                ref={interfaceDevice.InterfaceId === interfaceparam.InterfaceId ? interfaceRef : null}
                                className={interfaceDevice.InterfaceId === interfaceparam.InterfaceId ? "OneList SelectedList" : "OneList"}
                                key={interfaceparam.InterfaceId}
                                onClick={(e) => {
                                  e.stopPropagation();
                                  onSelectInterface(interfaceparam.InterfaceId, true);
                                  clearInterfaceFields();
                                }}
                              >
                                <p className="ListofText">
                                  {interfaceparam.InterfaceName}
                                  {<span className="SiteNameText"> - {interfaceparam.Interfacetypename}
                                  </span>}
                                </p>
                                <div>
                                </div>
                              </div>
                            );
                          }) : <div className="no-data-found"><p>No Interface Found.</p></div>
                      )
                    }
                    {!interfaceDataList && (
                      <div className="no-data-found">No Interface Available.</div>
                    )}
                  </div>
                </div>
                <div className="SiteRightContainer">
                  <h1 className="MainHeaderName">Interface Details</h1>
                  <div className="siteformContainer">
                    <form onSubmit={handleSubmit((e) => onInterfaceFormSubmit(e, interfaceDevice.InterfaceId))}>
                      <div
                        className="site-form"
                        style={{ display: "flex", flexDirection: "column", alignItems: "center" }}
                      >

                        <div className="inputMainContainer w40dot75rem">
                          <div className="inputTypeLabelContainer">
                            <label className='formFieldLabels'>Interface Name</label>
                            <input
                              {...register("InterfaceName")}
                              className="inputType"
                              disabled={!isInterfaceEdit}
                              placeholder="Enter Interface Name*"
                              onKeyPress={(e) => {
                                onSpecialChangeOnly(e)
                                setInterfaceError('')
                              }}
                              onChange={() => { setInterfaceError('') }}
                            />
                            <p className="Error_P" style={{ float: "left" }}><span> {interfaceError} </span></p>
                          </div>
                        </div>

                        <div className="inputMainContainer w40dot75rem mt35">
                          <div className="inputTypeLabelContainer">
                            <label className='formFieldLabels'>Interface Type</label>
                            <select
                              {...register("InterfaceType")}
                              disabled={interfaceDevice.InterfaceId != 0 ? true : false}
                              className="inputType ArrowSelectClass"
                              onChange={(e) => {
                                interfaceSubTypeHandler(e)
                                setInterfaceTypeError('')
                                setValue("EntityTypeID", 0)
                                setValue("CheckExternalHooter", 0);
                                setValue("CheckInternalHooter", 0);
                                setValue("IsHooterEnabled", 0);
                                setValue("InterfaceTwoWayEnabled", 0)
                              }}
                            >
                              <option key="0" disabled={false} value="0" id="0">Select Interface Type*</option>
                              {interfaceTypeData &&
                                interfaceTypeData.map((val) => (
                                  <option
                                    value={val.Interfacetypename} key={val.InterfacetypeId}
                                    id={val.InterfacetypeId}>
                                    {val.Interfacetypename}
                                  </option>
                                ))}
                            </select>
                            <p className="Error_P" style={{ float: "left" }}><span> {interfaceTypeError} </span></p>
                          </div>
                        </div>

                        <div className="inputMainContainer w40dot75rem mt35">
                          <div className="inputTypeLabelContainer">
                            <label className='formFieldLabels'>Interface Sub-Type</label>
                            <select
                              {...register("InterfaceSubType")}
                              disabled={interfaceDevice.InterfaceId != 0 ? true : false}
                              className="inputType ArrowSelectClass"
                              onChange={(e) => {
                                setValue("EntityTypeID", 0)
                                setInterfaceSubtypeError('')
                                if (e.target.value == "Unknown") {
                                  setDisplayLivePlaybackfield(true)
                                  setEnableHooter(false)
                                  setDisplayTwoWay(false)
                                  setDisplayCamera(false)
                                } else if (getValues("InterfaceType") == "Alarm Panel") {
                                  setDisplayTwoWay(false);
                                  setEnableHooter(true);
                                  setDisplayLivePlaybackfield(false);
                                }
                                else if (getValues("InterfaceType") == "ACS") {
                                  setDisplayLivePlaybackfield(false)
                                  setEnableHooter(false)
                                  setDisplayTwoWay(false)
                                  setDisplayCamera(false)
                                }
                                else {
                                  setDisplayLivePlaybackfield(false)
                                  setEnableHooter(false)
                                  setDisplayTwoWay(true)
                                  setDisplayCamera(false);
                                  setValue("IsHooterEnabled", 0);
                                  setValue("InterfaceTwoWayEnabled", 0)

                                }
                              }}
                            >
                              <option key="0" disabled={false} value="0" id="0">Select Interface Sub Type*</option>
                              {interfaceSubTypeData &&
                                interfaceSubTypeData.map((val) => (
                                  <option
                                    value={val.Interfacesubtypename}
                                    key={val.InterfacesubtypeId}
                                    id={val.InterfacesubtypeId}
                                  >
                                    {val.Interfacesubtypename}
                                  </option>
                                ))}
                            </select>
                            <p className="Error_P" style={{ float: "left" }}><span> {interfaceSubtypeError} </span></p>
                          </div>
                        </div>

                        {
                          displayEntity
                            ?
                            <>
                              <div className="inputMainContainer w40dot75rem mt35">
                                <div className="inputTypeLabelContainer">
                                  <label className='formFieldLabels'>Entity Subtype</label>
                                  <select
                                    {...register("EntityTypeID")}
                                    className="inputType ArrowSelectClass"
                                    placeholder="Entity Subtype*"
                                    disabled={disabledEntity}
                                    defaultValue={interfaceDevice.EntityTypeID}
                                    onChange={(e) => {
                                      setValue("CCTVType", e.target.selectedOptions[0].text)
                                      setInterfaceEntitySubtypeError()
                                      if (getValues("InterfaceSubType") == "Unknown") {
                                        setDisplayCamera(false)
                                        setEnableHooter(false)
                                        setParentPanelIsEnablingHooter(false)
                                        setParentIsEnablingInternalHooter(false)
                                        setParentIsEnablingExternalHooter(false);
                                      } else {
                                        if (e.target.selectedOptions[0].text == "Camera") {
                                          setDisplayCamera(true)
                                          setEnableHooter(false)
                                          setParentPanelIsEnablingHooter(false);
                                        } else {
                                          setDisplayCamera(false)
                                          setEnableHooter(true)
                                          setParentIsEnablingInternalHooter(false)
                                          setParentIsEnablingExternalHooter(false)
                                          setValue("CheckExternalHooter", 0);
                                          setValue("CheckInternalHooter", 0);
                                          setValue("IsHooterEnabled", 0);
                                          setValue("InterfaceTwoWayEnabled", 0)
                                        }
                                      }

                                      setValue("CCTVTypeIDInterface", e.target.value)
                                    }
                                    }
                                  >
                                    <option key="0" disabled={false} value="0" id="0">Entity Subtype*</option>
                                    {cctvTypeData && cctvTypeData.map((entity) => (
                                      <option value={entity.CCTVTypeID} name={entity.CCTVType} key={entity.CCTVTypeID}>
                                        {entity.CCTVType}
                                      </option>
                                    ))}
                                  </select>
                                  <p className="Error_P" style={{ float: "left" }}><span> {interfaceEntitySubtypeError} </span></p>
                                </div>
                              </div>
                            </>
                            : null
                        }

                        <div className="inputMainContainer w40dot75rem mt35">
                          <div className="inputTypeLabelContainer">
                            <label className='formFieldLabels'>Interface IP</label>
                            <input
                              {...register("InterfaceIP")}
                              className="inputType"
                              disabled={!isInterfaceEdit}
                              placeholder="Enter Interface IP*"
                              onKeyPress={(e) => {
                                onNumberOnlyChange2(e);
                                setInterfaceIPError('')
                              }}
                              onChange={() => setInterfaceIPError('')}
                            />
                            <p className="Error_P" style={{ float: "left" }}><span> {interfaceIPError} </span></p>
                          </div>
                        </div>

                        <div className="inputMainContainer w40dot75rem mt35">
                          <div className="inputTypeLabelContainer">
                            <label className='formFieldLabels'>Interface Port</label>
                            <input
                              {...register("InterfacePort")}
                              className="inputType"
                              disabled={!isInterfaceEdit}
                              placeholder="Enter Interface Port*"
                              onKeyPress={(e) => {
                                onNumberOnlyChange(e);
                                setInterfacePortError('')
                              }}
                              onChange={() => setInterfacePortError('')}
                            />
                            <p className="Error_P" style={{ float: "left" }}><span> {interfacePortError} </span></p>
                          </div>
                        </div>

                        <div className="inputMainContainer w40dot75rem mt35">
                          <div className="inputTypeLabelContainer">
                            <label className='formFieldLabels'>Interface Username</label>
                            <input
                              {...register("InterfaceUsername")}
                              className="inputType"
                              disabled={!isInterfaceEdit}
                              placeholder="Enter Interface Username*"
                              onChange={() => setInterfaceUserError('')}
                            />
                            <p className="Error_P" style={{ float: "left" }}><span> {interfaceUserError} </span></p>
                          </div>
                        </div>

                        <div className="inputMainContainer w40dot75rem mt35">
                          <div className="inputTypeLabelContainer">
                            <label className='formFieldLabels'>Interface Password</label>
                            <input
                              {...register("InterfacePassword")}
                              className="inputType"
                              disabled={!isInterfaceEdit}
                              placeholder="Enter Interface Password*"
                              onChange={() => setInterfacePasswardError('')}
                            />
                            <p className="Error_P" style={{ float: "left" }}><span> {interfacePasswardError} </span></p>
                          </div>
                        </div>

                        {
                          MULTI_TIME_ZONE ?

                            <div className="inputMainContainer w40dot75rem mt35">
                              <div className="inputTypeLabelContainer">
                                <label className='formFieldLabels'>Timezone</label>
                                <select
                                  {...register("TimeZoneId")}
                                  disabled={!isInterfaceEdit}
                                  className="inputType ArrowSelectClass"
                                  onChange={(e) => {
                                    setValue("TimeZoneId", e.target.value)
                                    setInterfaceTimeZoneError('')
                                  }}
                                >
                                  <option key="0" disabled={false} value="0" id="0">Select timezone*</option>
                                  {timezone &&
                                    timezone.map((val) => (
                                      <option
                                        value={val.TimeZoneId} key={val.TimeZoneId}
                                        id={val.Abbreviation}>
                                        {val.Abbreviation} (UTC{val.Shift.slice(0, -3)}) {val.Fullname}
                                      </option>
                                    ))}
                                </select>
                                <p className="Error_P" style={{ float: "left" }}><span> {interfaceTimeZoneError} </span></p>
                              </div>
                            </div>
                            :
                            null
                        }

                        {
                          !HIDE_STUFF
                            ?

                            displayLivePlaybackfield ?
                              <>
                                <div className="inputMainContainer w40dot75rem mt35">
                                  <div className="inputTypeLabelContainer">
                                    <label className='formFieldLabels'>Live Stream Format</label>
                                    <MentionsInput
                                      {...register("LiveRTSPURL")}
                                      disabled={!isInterfaceEdit}
                                      placeholder="Enter live stream URL format"
                                      className="inputType long-value-input"
                                      style={{ marginLeft: "18px" }}
                                      singleLine
                                      value={rtspValue}
                                      onChange={(e, newValue, newPlainTextValue) => {
                                        setRtspValue(e.target.value)
                                        setRtspValueEntered(newPlainTextValue)
                                        setLiveStreamFormatError('')
                                      }}
                                    >
                                      <Mention
                                        data={RTSP}
                                        markup="{__display__}"
                                        trigger={/(@([^@]*))$/}
                                      // displayTransform={(value) => {
                                      //   if(value == "username" || value == "ip")
                                      //   {
                                      //     return `${value}:`
                                      //   }else if(value == "password"){
                                      //     return `${value}@`
                                      //   }else{
                                      //     return `${value}`                                        
                                      //   }
                                      // }}
                                      />
                                    </MentionsInput>
                                    <p className="Error_P" style={{ float: "left" }}><span>{liveStreamFormatError}</span></p>
                                  </div>
                                </div>

                                <div className="inputMainContainer w40dot75rem mt35">
                                  <div className="inputTypeLabelContainer">
                                    <label className='formFieldLabels'>Playback Stream Format</label>
                                    <MentionsInput
                                      {...register("PlaybackRTSPURL")}
                                      disabled={!isInterfaceEdit}
                                      placeholder="Enter playback URL format"
                                      className="inputType long-value-input"
                                      style={{ marginLeft: "18px" }}
                                      singleLine
                                      value={playbackValue}
                                      onChange={(e, newValue, newPlainTextValue) => {
                                        setPlaybackValue(e.target.value)
                                        setPlaybackValueEntered(newPlainTextValue)
                                        setPlaybackStreamFormatError('')
                                      }}
                                    >
                                      <Mention
                                        data={PLAYBACK}
                                        markup="{__display__}"
                                        trigger={/(@([^@]*))$/}
                                        style={{ border: "0px impotant" }}
                                      />
                                    </MentionsInput>
                                    <p className="Error_P" style={{ float: "left" }}><span>{playbackStreamFormatError}</span></p>
                                  </div>
                                </div>
                                {/* </div> */}

                                {/* <div className="SiteTextBox SiteNameText"> */}
                                <div className="inputMainContainer w40dot75rem mt35">
                                  <div className="inputTypeLabelContainer">
                                    <label className='formFieldLabels'>UTC Conversion</label>
                                    <div className="onlyforEnableCheckbox">
                                      <input
                                        className="checkbox_custom"
                                        type="checkbox"
                                        disabled={!isInterfaceEdit}
                                        {...register("PlaybackTimeStampsToUTC")}
                                        onChange={(e) => {
                                          setValue("PlaybackTimeStampsToUTC", Number(e.target.checked))
                                          setParentIsEnablingPlaybackTimeStampsToUTC(e.target.checked);
                                        }}
                                        defaultValue={Number(interfaceDevice.CheckPlaybackUTCTime)}
                                      />
                                      <label className="mb-0"
                                        onClick={() => {
                                          if (!isInterfaceEdit) {
                                            return;
                                          }
                                          getValues("PlaybackTimeStampsToUTC")
                                          if (parentIsEnablingPlaybackTimeStampsToUTC) {
                                            setValue("PlaybackTimeStampsToUTC", 0)
                                            setParentIsEnablingPlaybackTimeStampsToUTC(false);
                                          } else {
                                            setValue("PlaybackTimeStampsToUTC", 1)
                                            setParentIsEnablingPlaybackTimeStampsToUTC(true);
                                          }
                                        }}
                                      >
                                        Playback Timestamps to UTC
                                      </label>
                                    </div>
                                  </div>
                                </div>
                                {/* </div> */}

                                <div className="SiteTextBox SiteNameText" style={{ display: "none", height: "55px" }}>
                                  <div className="onlyforEnableCheckbox">
                                    <input
                                      className="checkbox_custom"
                                      type="checkbox"
                                      disabled={!isInterfaceEdit}
                                      {...register("PlaybackLongDateFormat")}
                                      onChange={(e) => {
                                        setValue("PlaybackLongDateFormat", Number(e.target.checked))
                                        setParentIsEnablingPlaybackLongDateFormat(e.target.checked);
                                      }}
                                      defaultValue={Number(interfaceDevice.CheckPlaybackLongTime)}
                                    />
                                    <label className="mb-0"
                                      onClick={(e) => {
                                        if (!isInterfaceEdit) {
                                          return;
                                        }
                                        getValues("PlaybackLongDateFormat")
                                        if (parentIsEnablingPlaybackLongDateFormat) {
                                          setValue("PlaybackLongDateFormat", 0)
                                          setParentIsEnablingPlaybackLongDateFormat(false);
                                        } else {
                                          setValue("PlaybackLongDateFormat", 1)
                                          setParentIsEnablingPlaybackLongDateFormat(true);
                                        }
                                      }}
                                    >
                                      Playback Long Date Format
                                    </label>
                                  </div>
                                </div>

                              </>
                              : null

                            : null
                        }



                        {
                          displayTwoWay
                            ?
                            <>
                              <div className="inputMainContainer w40dot75rem mt30">
                                <div className="inputTypeLabelContainer">
                                  <label className='formFieldLabels'>Two Way Communication</label>
                                  <div
                                    className="onlyforEnableCheckbox"
                                  >
                                    <input
                                      className="checkbox_custom"
                                      type="checkbox"
                                      disabled={!isInterfaceEdit}
                                      {...register("InterfaceTwoWayEnabled")}
                                      onChange={(e) => {
                                        setValue("InterfaceTwoWayEnabled", Number(e.target.checked))
                                        if (Number(e.target.checked)) {
                                          setParentIsEnablingTwoWayInterface(true);
                                        } else {
                                          setParentIsEnablingTwoWayInterface(false);
                                        }
                                      }}
                                      defaultValue={Number(interfaceDevice.IsTwoWayEnabled)}
                                    />
                                    <label className="mb-0"
                                      onClick={() => {
                                        if (!isInterfaceEdit) {
                                          return;
                                        }
                                        getValues("InterfaceTwoWayEnabled")
                                        if (parentIsEnablingTwoWayInterface) {
                                          setValue("InterfaceTwoWayEnabled", 0)
                                          setParentIsEnablingTwoWayInterface(false);
                                        } else {
                                          setValue("InterfaceTwoWayEnabled", 1)
                                          setParentIsEnablingTwoWayInterface(true);
                                        }
                                      }}
                                    >
                                      Two Way Communication Available
                                    </label>
                                  </div>
                                </div>
                              </div>
                            </>
                            : null
                        }

                        {
                          !HIDE_STUFF
                            ?
                            displayCamera
                              ?
                              <div className="inputMainContainer w40dot75rem">
                                <div className="inputTypeLabelContainer">
                                  <label className='formFieldLabels'>Enable Hooter types</label>
                                  <div className='mt-1 signTypeCheckboxLabels'>
                                    <div className="SiteTextBox SiteNameText mt-0">
                                      <div className="onlyforEnableCheckbox ml-0">
                                        <input
                                          className="checkbox_custom"
                                          type="checkbox"
                                          disabled={!isInterfaceEdit}
                                          {...register("CheckInternalHooter")}
                                          //Internal hooter
                                          onChange={(e) => {
                                            setValue("CheckInternalHooter", Number(e.target.checked))
                                            setParentIsEnablingInternalHooter(e.target.checked);
                                            if (e.target.checked) {
                                              setValue("CheckExternalHooter", 0);
                                              setParentIsEnablingExternalHooter(false);
                                            }
                                          }}

                                          defaultValue={Number(interfaceDevice.CheckInternalHooter)}
                                        />
                                        <label className="mb-0"
                                          onClick={() => {
                                            if (!isInterfaceEdit) {
                                              return;
                                            }
                                            getValues("CheckInternalHooter")
                                            if (parentIsEnablingInternalHooter) {
                                              setValue("CheckInternalHooter", 0)
                                              setParentIsEnablingInternalHooter(false);
                                            } else {
                                              setValue("CheckInternalHooter", 1)
                                              setParentIsEnablingInternalHooter(true);
                                              setValue("CheckExternalHooter", 0);
                                              setParentIsEnablingExternalHooter(false);
                                            }
                                          }}
                                        >
                                          Enable Internal Hooter
                                        </label>
                                      </div>
                                    </div>
                                    <div className="SiteTextBox SiteNameText mt-0">
                                      <div className="onlyforEnableCheckbox ml-0">
                                        <input
                                          className="checkbox_custom"
                                          type="checkbox"
                                          disabled={!isInterfaceEdit}
                                          {...register("CheckExternalHooter")}
                                          // External hooter
                                          onChange={(e) => {
                                            setValue("CheckExternalHooter", Number(e.target.checked))
                                            setParentIsEnablingExternalHooter(e.target.checked);
                                            if (e.target.checked) {
                                              setValue("CheckInternalHooter", 0);
                                              setParentIsEnablingInternalHooter(false);
                                            }
                                          }}

                                          defaultValue={Number(interfaceDevice.CheckExternalHooter)}
                                        />
                                        <label className="mb-0"
                                          onClick={() => {
                                            if (!isInterfaceEdit) {
                                              return;
                                            }
                                            getValues("CheckExternalHooter")
                                            if (parentIsEnablingExternalHooter) {
                                              setValue("CheckExternalHooter", 0)
                                              setParentIsEnablingExternalHooter(false);
                                            } else {
                                              setValue("CheckExternalHooter", 1)
                                              setParentIsEnablingExternalHooter(true);
                                              setValue("CheckInternalHooter", 0);
                                              setParentIsEnablingInternalHooter(false);
                                            }

                                          }}
                                        >
                                          Enable External Hooter
                                        </label>
                                      </div>
                                    </div>
                                  </div>
                                </div>
                              </div>
                              :
                              null
                            :
                            null
                        }

                        {
                          !HIDE_STUFF
                            ?
                            enableHooter
                              ? (
                                <div className="inputMainContainer w40dot75rem mt30">
                                  <div className="inputTypeLabelContainer">
                                    <label className='formFieldLabels'>Enable/Disable Hooter</label>
                                    <div className="onlyforEnableCheckbox">
                                      <input
                                        className="checkbox_custom"
                                        type="checkbox"
                                        disabled={!isInterfaceEdit}
                                        {...register("IsHooterEnabled")}
                                        onChange={(e) => {
                                          setValue("IsHooterEnabled", Number(e.target.checked))
                                          setParentPanelIsEnablingHooter(e.target.checked);

                                        }}
                                        defaultValue={Number(interfaceDevice.IsHooterEnabled)}
                                      />
                                      <label className="mb-0"
                                        onClick={() => {
                                          if (!isInterfaceEdit) {
                                            return;
                                          }
                                          getValues("IsHooterEnabled")
                                          if (parentPanelIsEnablingHooter) {
                                            setValue("IsHooterEnabled", 0)
                                            setParentPanelIsEnablingHooter(false);
                                          } else {
                                            setValue("IsHooterEnabled", 1)
                                            setParentPanelIsEnablingHooter(true);
                                          }
                                        }}
                                      >
                                        Enable Hooter
                                      </label>
                                    </div>
                                  </div>
                                </div>
                              ) : null
                            : null
                        }

                        <div className="BulkText" hidden>
                          <div className="SentenceText">

                          </div>

                          <div className="ButtonText">
                            <button
                              className="AlertViewButton"
                            //  style={{ visibility: "collapse" }}
                            >
                              <p>Upload CSV</p>
                            </button>
                          </div>
                        </div>
                      </div>
                    </form>
                  </div>
                  <div style={{ textAlign: "center" }}>
                    {
                      interfaceDevice.InterfaceId != 0 &&
                      (
                        <button className="DataAccessEdit" style={{ width: "100px" }}
                          onClick={() => {
                            setSelectedInterface(interfaceObj);
                            setIsInterfaceEdit(true)
                            setParentIsEnablingInternalHooter(false)
                            setParentIsEnablingExternalHooter(false)
                            setParentPanelIsEnablingHooter(false)
                            clearInterfaceFields();
                          }}
                        >
                          <span className="ViewUpdate">Add New</span>
                        </button>
                      )
                    }
                    {
                      interfaceDevice.InterfaceId != 0 && !isInterfaceEdit &&
                      (
                        <button className="DataAccessEdit" style={{ width: "100px" }}
                          onClick={() => setIsInterfaceEdit(true)}
                        >
                          <span className="ViewUpdate">Edit</span>
                        </button>
                      )
                    }
                    {
                      interfaceDevice.InterfaceId == 0 &&
                      (
                        <button className="DataAccessEdit" style={{ width: "100px" }}
                          disabled={buttonDisable}
                          onClick={handleSubmit((e) => onInterfaceFormSubmit(e, interfaceDevice.InterfaceId, rtspValueEntered, playbackValueEntered))}
                        >
                          <span className="ViewUpdate">Save</span>
                        </button>
                      )
                    }
                    {
                      interfaceDevice.InterfaceId != 0 && isInterfaceEdit &&
                      (
                        <>
                          <button className="DataAccessEdit" style={{ width: "100px" }}
                            disabled={buttonDisable}
                            onClick={handleSubmit((e) => onInterfaceFormSubmit(e, interfaceDevice.InterfaceId, rtspValueEntered, playbackValueEntered))}
                          >
                            <span className="ViewUpdate">Save</span>
                          </button>
                          <button className="DataAccessEdit" style={{ width: "100px" }} onClick={(e) => onCancelInterface(e)} >
                            <span className="ViewUpdate">Cancel</span>
                          </button>
                        </>
                      )
                    }
                    {
                      interfaceDevice.InterfaceId != 0 && !isInterfaceEdit &&
                      (
                        <button className="DataAccessEdit" style={{ width: "100px" }}
                          disabled={buttonDisable}
                          onClick={(e) => onDeleteInterfaceChange(e, interfaceDevice.InterfaceId, interfaceDevice.InterfaceName)}>
                          <span className="ViewUpdate">Delete</span>
                        </button>
                      )
                    }
                  </div>
                </div>
              </div>
              :
              <div className="SiteMainContainer">
                <div className="SiteLeftContainer">
                  <div className="m-2 HeaderSiteList">
                    <input
                      id="Search_txt_device"
                      className="SearchViewInput1"
                      type="text"
                      onChange={(e) => {
                        setSearchTextDevice(e.target.value)
                        onSearchChange(e.target.value, "Device List", interfaceType)
                      }
                      }
                      placeholder="Search device"
                      autoFocus
                      value={searchTextDevice}
                    />
                    <div className="SearchTextDivConfigTool">
                      {
                        isDarkTheme ?
                          <img
                            className="EyeIcon"
                            src={SearchIcon}
                            alt="search icon"
                          /> :
                          <img
                            className="EyeIcon"
                            src={SearchIconWhite}
                            alt="search icon" height="15"
                          />
                      }
                    </div>
                  </div>
                  <div className="StepperSiteList">
                    {
                      interfaceType === NVRDVR
                        ?
                        (
                          cameradeviceslist ? (
                            cameradeviceslist?.length > 0
                              ?
                              cameradeviceslist.map((item) => {
                                return (
                                  <div
                                    className={selectedDevice?.ParentCameraID === item.ParentCameraID ? "OneList SelectedList" : "OneList"}
                                    key={item.ParentCameraID}
                                    onClick={(e) => {
                                      onDeviceSelection(item)
                                      setIsChannelNumberEdited(false)
                                      clearCameraFields()
                                    }}
                                  >
                                    <p className="ListofText">
                                      {item.CameraName}
                                      <span className="SiteNameText"> - {item.ParentCameraID}</span>
                                    </p>
                                    <div>
                                    </div>
                                  </div>
                                );
                              })
                              : (<div className="no-data-found">No Camera Found.</div>)
                          )
                            : (!cameradeviceslist && (
                              <div className="no-data-found">No Camera Available.</div>
                            ))
                        )
                        : interfaceType === ACS
                          ?
                          (
                            acsDevicesList ? (
                              acsDevicesList?.length > 0
                                ?
                                acsDevicesList.map((item) => {
                                  return (
                                    <div
                                      className={selectedDevice?.ParentCameraID === item.ParentCameraID ? "OneList SelectedList" : "OneList"}
                                      key={item.ParentCameraID}
                                      onClick={(e) => {
                                        onDeviceSelection(item)
                                        getAssociateACSList(item)
                                        setIsChannelNumberEdited(false)
                                        clearCameraFields()
                                        setEditAssociate(false);
                                      }}
                                    >
                                      <p className="ListofText">
                                        {item.CameraName}
                                        <span className="SiteNameText"> - {item.ParentCameraID}</span>
                                      </p>
                                      <div>
                                      </div>
                                    </div>
                                  );
                                })
                                : (<div className="no-data-found">No ACS Found.</div>)
                            )
                              : (!cameradeviceslist && (
                                <div className="no-data-found">No ACS Available.</div>
                              ))
                          )
                          :
                          (
                            paneldeviceslist ? (
                              paneldeviceslist?.length > 0
                                ?
                                paneldeviceslist.map((item) => {
                                  return (
                                    <div
                                      className={selectedDevice?.ParentCameraID === item.ParentCameraID ? "OneList SelectedList" : "OneList"}
                                      key={item.ParentCameraID}
                                      onClick={(e) => onDeviceSelection(item)}
                                    >
                                      <p className="ListofText">
                                        {item.CameraName}
                                        <span className="SiteNameText"> - {item.ParentCameraID}</span>
                                      </p>
                                      <div>
                                      </div>
                                    </div>
                                  );
                                })
                                : (
                                  <div className="no-data-found">No Panel Found.</div>
                                )
                            )
                              : (!paneldeviceslist && (
                                <div className="no-data-found">No Panel Available.</div>
                              ))
                          )
                    }
                  </div>
                </div>
                <div className="SiteRightContainer">
                  {
                    interfaceType === NVRDVR
                      ?
                      <>
                        <h1 className="MainHeaderName">Camera Details</h1>
                        <div className="siteformContainer">
                          {
                            bulkUpload
                              ? <CameraBulkUpload
                                selectedCamHeaders={selectedCamHeaders}
                                OnAddBulkDevicesClick={OnAddBulkDevicesClick}
                                setSelectedCamHeaders={setSelectedCamHeaders}
                                clearImportedCamHeaders={clearImportedCamHeaders}
                                deviceBulkUploadResponse={deviceBulkUploadResponse}
                                setImportedFileData={setImportedFileData}
                                setDeviceBulkUploadResponse={setDeviceBulkUploadResponse}
                                handleSingleUploadClick={handleSingleUploadClick}
                                setBulkUpload={setBulkUpload}
                                setIsSingleOrBulkSelection={setIsSingleOrBulkSelection}
                                importedFileHeaders={importedFileHeaders}
                                setImportedFileHeaders={setImportedFileHeaders}
                              />
                              :
                              <div className="site-form" style={{ display: "flex", flexDirection: "column", alignItems: "center" }}>
                                <div className="inputMainContainer w40dot75rem">
                                  <div className="inputTypeLabelContainer">
                                    <label className="formFieldLabels">Brand Name</label>
                                    <select
                                      {...register("CameraBrandId")}
                                      className="inputType ArrowSelectClass"
                                      disabled={true}
                                      placeholder="Select Brand Name"
                                      defaultValue={selectedDevice.cameraBrandId || selectedDevice.CameraBrandId}
                                      onChange={(e) => {
                                        setValue("CameraBrandId", e.target.value);
                                        handleOptionChange(e.target.value)
                                      }}
                                    >
                                      <option key="0" disabled={false} value="0">Select Brand Name*</option>

                                      {devicesmasterlist &&
                                        devicesmasterlist.cameraTypeMasterList &&
                                        devicesmasterlist.cameraTypeMasterList.map((val) => (
                                          <option value={val.brandId} key={val.brandId}>
                                            {val.brandName}
                                          </option>
                                        ))}
                                    </select>
                                  </div>
                                </div>

                                <div className="inputMainContainer w40dot75rem mt35">
                                  <div className="inputTypeLabelContainer">
                                    <label className='formFieldLabels'>IP Address</label>
                                    <input
                                      {...register("privateIPAddress")}
                                      className="inputType"
                                      disabled={true}
                                      placeholder="IP Address* e.g. 192.168.0.54"
                                      defaultValue={selectedDevice.privateIPAddress}
                                    />
                                  </div>
                                </div>

                                <div className="inputMainContainer w40dot75rem mt35">
                                  <div className="inputTypeLabelContainer">
                                    <label className='formFieldLabels'>Communication Port</label>
                                    <input
                                      {...register("communicationPort")}
                                      className="inputType"
                                      disabled={!isDeviceEdit}
                                      placeholder="Enter Communication Port* e.g. 8080"
                                      onKeyPress={(e) => {
                                        onNumberOnlyChange(e);
                                        setCommunicationPortError('')
                                      }}
                                      onChange={() => setCommunicationPortError('')}
                                      defaultValue={selectedDevice.communicationPort}
                                    />
                                    <p className="Error_P" style={{ float: "left" }}><span> {communicationPortError} </span></p>
                                  </div>
                                </div>

                                <div className="inputMainContainer w40dot75rem mt35">
                                  <div className="inputTypeLabelContainer">
                                    <label className='formFieldLabels'>Http Port</label>
                                    <input
                                      {...register("httpPort")}
                                      className="inputType"
                                      disabled={!isDeviceEdit}
                                      placeholder="Enter Http Port* e.g. 443"
                                      onKeyPress={(e) => {
                                        onNumberOnlyChange(e);
                                        setHttpPotError('')
                                      }}
                                      onChange={() => setHttpPotError('')}
                                      defaultValue={selectedDevice.httpPort}
                                    />
                                    <p className="Error_P" style={{ float: "left" }}><span> {httpPortError} </span></p>
                                  </div>
                                </div>

                                <div className="inputMainContainer w40dot75rem mt35">
                                  <div className="inputTypeLabelContainer">
                                    <label className='formFieldLabels'>User Name</label>
                                    <input
                                      {...register("userName")}
                                      className="inputType"
                                      disabled={true}
                                      placeholder="User Name* e.g. Admin"
                                      defaultValue={selectedDevice.camUserName}
                                    />
                                  </div>
                                </div>

                                <div className="inputMainContainer w40dot75rem mt35">
                                  <div className="inputTypeLabelContainer">
                                    <label className='formFieldLabels'>Password</label>
                                    <input
                                      {...register("password")}
                                      className="inputType"
                                      disabled={true}
                                      placeholder="Password* e.g. Abc@123"
                                      defaultValue={selectedDevice.camPassword}
                                    />
                                  </div>
                                </div>

                                <div className="inputMainContainer w40dot75rem mt35">
                                  <div className="inputTypeLabelContainer">
                                    <label className='formFieldLabels'>RTSP Port</label>
                                    <input
                                      {...register("port")}
                                      className="inputType"
                                      placeholder="RTSP Port* e.g. 554"
                                      disabled={true}
                                      onKeyPress={onNumberOnlyChange}
                                      defaultValue={selectedDevice.camPort}
                                    />
                                  </div>
                                </div>

                                <div className="inputMainContainer w40dot75rem mt35">
                                  <div className="inputTypeLabelContainer">
                                    <label className='formFieldLabels'>Channel Number</label>
                                    <input
                                      {...register("channelNumber")}
                                      className="inputType"
                                      disabled={!isDeviceEdit}
                                      placeholder={channelNumberPlaceholder}
                                      onKeyPress={(e) => {
                                        setIsChannelNumberEdited(true)
                                        onNumberOnlyChange(e)
                                        setChannelNumberError('')
                                      }}
                                      onChange={() => { setChannelNumberError('') }}
                                      defaultValue={selectedDevice.channelNum}
                                    />
                                    <p className="Error_P" style={{ float: "left" }}><span>{channelNumberError}</span></p>
                                  </div>
                                </div>

                                <div className="inputMainContainer w40dot75rem mt35">
                                  <div className="inputTypeLabelContainer">
                                    <label className='formFieldLabels'>Live Stream Type</label>
                                    <select
                                      {...register("liveStreamTypeNumber")}
                                      className="inputType ArrowSelectClass"
                                      disabled={!isDeviceEdit}
                                      placeholder="Stream type (Live)*"
                                      onChange={(e) => {
                                        setValue('liveStreamTypeNumber', e.target.value)
                                        setLiveStreamTypeError("");
                                      }}
                                    >
                                      <option value="">Select Live Stream Type</option>
                                      <option value="Main">Main</option>
                                      <option value="Sub">Sub</option>
                                      <option value="Ter">Ter</option>
                                    </select>

                                    <p className="Error_P" style={{ float: "left" }}><span>{liveStreamTypeError}</span></p>
                                  </div>
                                </div>

                                <div className="inputMainContainer w40dot75rem mt35">
                                  <div className="inputTypeLabelContainer">
                                    <label className='formFieldLabels'>Playback Stream Type</label>
                                    <select
                                      {...register("playbackStreamTypeNumber")}
                                      className="inputType ArrowSelectClass"
                                      disabled={!isDeviceEdit}
                                      placeholder="Stream type (Playback)*"
                                      onChange={(e) => {
                                        setValue('playbackStreamTypeNumber', e.target.value)
                                        setPlaybackStreamTypeError("");
                                      }}
                                    >
                                      <option value="">Select Playback Stream Type</option>
                                      <option value="Main">Main</option>
                                      <option value="Sub">Sub</option>
                                      <option value="Ter">Ter</option>
                                    </select>
                                    <p className="Error_P" style={{ float: "left" }}><span>{playbackStreamTypeError}</span></p>
                                  </div>
                                </div>

                                <div className="inputMainContainer w40dot75rem mt35">
                                  <div className="inputTypeLabelContainer">
                                    <label className='formFieldLabels'>RTSP URL</label>
                                    <input
                                      {...register("rTSPURL")}
                                      className="inputType"
                                      disabled={true}
                                      placeholder="RTSP URL* e.g. rtsp://Admin:Abc@123@192.168.0.58:554/Streaming/Channels/1"
                                      defaultValue={selectedDevice.RTSPURL}
                                      onChange={() => {
                                        setRtspValueChanged(true)
                                        setrtspURLError('')
                                      }}
                                    />
                                    <p className="Error_P" style={{ float: "left" }}><span>{rtspURLError}</span></p>
                                  </div>
                                </div>

                                <div className="inputMainContainer w40dot75rem mt35">
                                  <div className="inputTypeLabelContainer">
                                    <label className='formFieldLabels'>Device Name</label>
                                    <input
                                      {...register("cameraName")}
                                      className="inputType"
                                      disabled={!isDeviceEdit}
                                      placeholder="Enter Device Name* e.g. Lobby Camera"
                                      defaultValue={selectedDevice.CameraName}
                                      onKeyPress={(e) => {
                                        onSpecialChangeOnly(e);
                                        setDeviceNameError('')
                                      }}
                                      onChange={() => { setDeviceNameError('') }}
                                    />
                                    <p className="Error_P" style={{ float: "left" }}><span>{deviceNameError}</span></p>
                                  </div>
                                </div>

                                <div className="inputMainContainer w40dot75rem mt35">
                                  <div className="inputTypeLabelContainer">
                                    <label className='formFieldLabels'>Branch Name</label>
                                    <select
                                      {...register("SiteID")}
                                      className="inputType ArrowSelectClass"
                                      placeholder="Branch*"
                                      disabled={true}
                                      defaultValue={selectedDevice.SiteID}
                                      onChange={(e) => setValue("SiteID", e.target.value)}
                                    >
                                      <option key="0" disabled={false} value="0">Select Branch*</option>
                                      {sites &&
                                        sites.map((site) => (
                                          <option value={site.SiteID} key={site.SiteID}>
                                            {site.SiteName}
                                          </option>
                                        ))}
                                    </select>
                                  </div>
                                </div>

                                <div className="inputMainContainer w40dot75rem mt35">
                                  <div className="inputTypeLabelContainer">
                                    <label className='formFieldLabels'>Entity Subtype</label>
                                    <select
                                      {...register("CCTVTypeID")}
                                      className="inputType ArrowSelectClass"
                                      placeholder="Entity Subtype*"
                                      disabled={true}
                                      defaultValue={selectedDevice.CCTVTypeID}
                                      onChange={(e) =>
                                        setValue("CCTVTypeID", e.target.value)

                                      }
                                    >
                                      <option key="0" disabled={false} value="0" id="0">Entity Subtype*</option>
                                      {cctvTypeData && cctvTypeData.map((entity) => (
                                        <option value={entity.CCTVTypeID} key={entity.CCTVTypeID}>
                                          {entity.CCTVType}
                                        </option>
                                      ))}
                                    </select>
                                  </div>
                                </div>

                                {
                                  MULTI_TIME_ZONE
                                    ?
                                    <div className="inputMainContainer w40dot75rem mt35">
                                      <div className="inputTypeLabelContainer">
                                        <label className='formFieldLabels'>Timezone</label>
                                        <select
                                          {...register("TimeZoneIdDevice")}
                                          className="inputType ArrowSelectClass"
                                          placeholder="Select timezone*"
                                          disabled={true}
                                          defaultValue={selectedDevice.TimeZoneIdDevice}
                                          onChange={(e) =>
                                            setValue("TimeZoneIdDevice", e.target.value)
                                          }
                                        >
                                          <option key="0" disabled={false} value="0" id="0">Select timezone*</option>
                                          {timezone &&
                                            timezone.map((val) => (
                                              <option
                                                value={val.TimeZoneId} key={val.TimeZoneId}
                                                id={val.Abbreviation}>
                                                {val.Abbreviation}-({val.Fullname})
                                              </option>
                                            ))}
                                        </select>
                                      </div>
                                    </div>
                                    : null
                                }

                                <div className="inputMainContainer w40dot75rem mt30">
                                  <div className="inputTypeLabelContainer">
                                    <label className='formFieldLabels'>Enable Transcoding</label>
                                    <div className='mt-1 signTypeCheckboxLabels'>
                                      <div className="SiteTextBox SiteNameText mt-0">
                                        <div className="onlyforEnableCheckbox ml-0">
                                          <input
                                            className="checkbox_custom"
                                            type="checkbox"
                                            disabled={!isDeviceEdit}
                                            {...register("isTranscoded")}
                                            onChange={(e) => {
                                              setValue("isTranscoded", Number(e.target.checked))
                                              setParentIsEncoding(e.target.checked);
                                            }}
                                            defaultValue={Number(selectedDevice.isTranscoded)}
                                          />
                                          <label className="mb-0"

                                            onClick={() => {
                                              if (!isDeviceEdit) {
                                                return;
                                              }
                                              getValues("isTranscoded")
                                              if (parentIsEncoding) {
                                                setValue("isTranscoded", 0)
                                                setParentIsEncoding(false);
                                              } else {
                                                setValue("isTranscoded", 1)
                                                setParentIsEncoding(true);
                                              }
                                            }}
                                          >
                                            Live Enable Transcoding to H.264 <sup>(Optional)</sup>
                                          </label>
                                        </div>
                                      </div>
                                      <div className="SiteTextBox SiteNameText mt-0">
                                        <div className="onlyforEnableCheckbox">
                                          <input
                                            className="checkbox_custom"
                                            type="checkbox"
                                            disabled={!isDeviceEdit}
                                            {...register("isPlaybackTranscoded")}
                                            onChange={(e) => {
                                              setValue("isPlaybackTranscoded", Number(e.target.checked))
                                              setParentIsPlaybackEncoding(e.target.checked);
                                            }}
                                            defaultValue={Number(selectedDevice.isPlaybackTranscoded)}
                                          />
                                          <label className="mb-0"
                                            onClick={() => {
                                              getValues("isPlaybackTranscoded")
                                              if (parentIsPlaybackEncoding) {
                                                setValue("isPlaybackTranscoded", 0)
                                                setParentIsPlaybackEncoding(false);
                                              } else {
                                                setValue("isPlaybackTranscoded", 1)
                                                setParentIsPlaybackEncoding(true);
                                              }
                                            }}
                                          >
                                            Playback Enable Transcoding to H.264 <sup>(Optional)</sup>
                                          </label>
                                        </div>
                                      </div>
                                    </div>
                                  </div>
                                </div>

                                {interfaceDevice.IsTwoWayEnabled == "1" &&
                                  <div className="inputMainContainer w40dot75rem">
                                    <div className="inputTypeLabelContainer">
                                      <label className='formFieldLabels'>Enable Two Way Communication</label>
                                      <div className="onlyforEnableCheckbox">
                                        <input
                                          className="checkbox_custom"
                                          type="checkbox"
                                          disabled={!isDeviceEdit}
                                          {...register("isTwoWayEnabledDevice")}
                                          onChange={(e) => {
                                            setValue("isTwoWayEnabledDevice", Number(e.target.checked))
                                            setParentIsEnablingTwoWayDevice(e.target.checked);
                                          }}
                                          defaultValue={Number(selectedDevice.isTwoWayEnabled)}
                                        />
                                        <label className="mb-0"
                                          onClick={() => {
                                            if (!isDeviceEdit) {
                                              return;
                                            }
                                            getValues("isTwoWayEnabledDevice")
                                            if (parentIsEnablingTwoWayDevice) {
                                              setValue("isTwoWayEnabledDevice", 0)
                                              setParentIsEnablingTwoWayDevice(false);
                                            } else {
                                              setValue("isTwoWayEnabledDevice", 1)
                                              setParentIsEnablingTwoWayDevice(true);
                                            }
                                          }}
                                        >
                                          Enable Two Way Communication
                                        </label>
                                      </div>
                                    </div>
                                  </div>
                                }

                                <div className="inputMainContainer w40dot75rem">
                                  <div className="inputTypeLabelContainer">
                                    <label className='formFieldLabels'>Enable PTZ</label>
                                    <div className="onlyforEnableCheckbox">
                                      <input
                                        className="checkbox_custom"
                                        type="checkbox"
                                        disabled={!isDeviceEdit}
                                        {...register("isPTZ")}
                                        onChange={(e) => {
                                          setValue("isPTZ", Number(e.target.checked))
                                          setParentIsEnablingPTZ(e.target.checked)
                                          // setParentIsEnablingTwoWayDevice(e.target.checked);
                                        }}
                                        defaultValue={Number(selectedDevice.isPTZ)}
                                      />
                                      <label className="mb-0"
                                        onClick={() => {
                                          if (!isDeviceEdit) {
                                            return;
                                          }
                                          getValues("isPTZ")
                                          if (parentIsEnablingPTZ) {
                                            setValue("isPTZ", 0)
                                            setParentIsEnablingPTZ(false);
                                            // setParentIsEnablingTwoWayDevice(false);
                                          } else {
                                            setValue("isPTZ", 1)
                                            setParentIsEnablingPTZ(true);
                                            // setParentIsEnablingTwoWayDevice(true);
                                          }
                                        }}
                                      >
                                        Enable PTZ
                                      </label>
                                    </div>
                                  </div>
                                </div>

                                {
                                  MULTI_TIME_ZONE
                                    ?
                                    <div className="inputMainContainer w40dot75rem">
                                      <div className="inputTypeLabelContainer">
                                        <label className='formFieldLabels'>Enable Playback in UTC</label>
                                        <div className="onlyforEnableCheckbox">
                                          <input
                                            className="checkbox_custom"
                                            type="checkbox"
                                            disabled={!isDeviceEdit}
                                            {...register("isPlaybackUTC")}
                                            onChange={(e) => {
                                              setValue("isPlaybackUTC", Number(e.target.checked))
                                              setParentIsEnablingUTCPlayback(e.target.checked);
                                            }}
                                            defaultValue={Number(selectedDevice.isPlaybackUTC)}
                                          />
                                          <label className="mb-0"
                                            onClick={() => {
                                              if (!isDeviceEdit) {
                                                return;
                                              }
                                              getValues("isPlaybackUTC")
                                              if (parentIsEnablingUTCPlayback) {
                                                setValue("isPlaybackUTC", 0)
                                                setParentIsEnablingUTCPlayback(false);
                                                // setParentIsEnablingTwoWayDevice(false);
                                              } else {
                                                setValue("isPlaybackUTC", 1)
                                                setParentIsEnablingUTCPlayback(true);
                                                // setParentIsEnablingTwoWayDevice(true);
                                              }
                                            }}
                                          >
                                            Enable Playback in UTC
                                          </label>
                                        </div>
                                      </div>
                                    </div>
                                    : null
                                }

                              </div>
                          }
                        </div>
                      </>
                      :
                      interfaceType === "ACS"
                        ?
                        <>
                          <h1 className="MainHeaderName">ACS Details</h1>
                          {
                            bulkUpload
                              ?
                              <AcsBulkUpload
                                selectedHeaders={selectedAcsHeaders}
                                onPanelBulkUpload={onAcsBulkUpload}
                                setSelectedHeaders={setSelectedAcsHeaders}
                                clearImportedPanelHeaders={clearImportedAcsHeaders}
                                setImportedFileData={setImportedFileData}
                                panelBulkUploadResponse={acsBulkUploadResponse}
                                setPanelBulkUploadResponse={setAcsBulkUploadResponse}
                                panelBulkUploadError={acsBulkUploadError}
                                setPanelBulkUploadError={setAcsBulkUploadError}
                                setBulkUpload={setBulkUpload}
                                setIsSingleOrBulkSelection={setIsSingleOrBulkSelection}
                                importedPanelHeaders={importedAcsHeaders}
                                setImportedPanelHeaders={setImportedAcsHeaders}
                                onAcsBulkUpload={onAcsBulkUpload}
                              />
                              :
                              <div className="siteformContainer">
                                <div className="site-form" style={{ display: "flex", flexDirection: "column", alignItems: "center" }}>

                                  <div className="inputMainContainer w40dot75rem">
                                    <div className="inputTypeLabelContainer">
                                      <label className="formFieldLabels">Brand Name</label>
                                      <select
                                        {...register("PanelMasterId")}
                                        className="inputType ArrowSelectClass"
                                        disabled={true}
                                        placeholder="Select Brand Name"
                                        defaultValue={selectedDevice.panelMasterId}
                                        onChange={(e) => {
                                          setValue("PanelMasterId", e.target.value);
                                        }}
                                      >
                                        <option key="0" disabled={false} value="0">Select Brand Name*</option>
                                        {devicesmasterlist &&
                                          devicesmasterlist.panelTypeMasterList &&
                                          devicesmasterlist.panelTypeMasterList.map((site) => (
                                            <option value={site.panelMasterID} key={site.panelName}>
                                              {site.panelName}
                                            </option>
                                          ))}
                                      </select>
                                    </div>
                                  </div>

                                  <div className="inputMainContainer w40dot75rem mt35">
                                    <div className="inputTypeLabelContainer">
                                      <label className='formFieldLabels'>IP Address</label>
                                      <input
                                        {...register("privateIPAddress")}
                                        className="inputType"
                                        disabled={true}
                                        placeholder="IP address* e.g. 192.168.0.54"
                                        defaultValue={selectedDevice.privateIPAddress}
                                      />
                                    </div>
                                  </div>

                                  <div className="inputMainContainer w40dot75rem mt35">
                                    <div className="inputTypeLabelContainer">
                                      <label className='formFieldLabels'>Port</label>
                                      <input
                                        {...register("port")}
                                        className="inputType"
                                        disabled={true}
                                        placeholder="Enter Port* e.g. 443"
                                        onKeyPress={(e) => {
                                          onNumberOnlyChange(e);
                                        }}
                                        defaultValue={selectedDevice.camPort}
                                      />
                                      {/* <p className="Error_P" style={{ float: "left" }}><span> {httpPortError} </span></p> */}
                                    </div>
                                  </div>

                                  <div className="inputMainContainer w40dot75rem mt35">
                                    <div className="inputTypeLabelContainer">
                                      <label className='formFieldLabels'>Interface Username</label>
                                      <input
                                        {...register("InterfaceUsername")}
                                        className="inputType"
                                        disabled={!isInterfaceEdit}
                                        placeholder="Enter Interface Username*"
                                        onChange={() => setInterfaceUserError('')}
                                      />
                                      <p className="Error_P" style={{ float: "left" }}><span> {interfaceUserError} </span></p>
                                    </div>
                                  </div>

                                  <div className="inputMainContainer w40dot75rem mt35">
                                    <div className="inputTypeLabelContainer">
                                      <label className='formFieldLabels'>Interface Password</label>
                                      <input
                                        {...register("InterfacePassword")}
                                        className="inputType"
                                        disabled={!isInterfaceEdit}
                                        placeholder="Enter Interface Password*"
                                        onChange={() => setInterfacePasswardError('')}
                                      />
                                      <p className="Error_P" style={{ float: "left" }}><span> {interfacePasswardError} </span></p>
                                    </div>
                                  </div>

                                  <div className="inputMainContainer w40dot75rem mt35">
                                    <div className="inputTypeLabelContainer">
                                      <label className='formFieldLabels'>Device Name</label>
                                      <input
                                        {...register("cameraName")}
                                        className="inputType"
                                        disabled={!isDeviceEdit}
                                        placeholder="Enter Device Name* e.g. Lobby Camera"
                                        defaultValue={selectedDevice.CameraName}
                                        onKeyPress={(e) => {
                                          onSpecialChangeOnly(e);
                                          setDeviceNameError('')
                                        }}
                                        onChange={() => { setDeviceNameError('') }}
                                      />
                                      <p className="Error_P" style={{ float: "left" }}><span>{deviceNameError}</span></p>
                                    </div>
                                  </div>

                                  <div className="inputMainContainer w40dot75rem mt35">
                                    <div className="inputTypeLabelContainer">
                                      <label className='formFieldLabels'>Identifier Name</label>
                                      <input
                                        {...register("identifierName")}
                                        className="inputType"
                                        disabled={!isDeviceEdit}
                                        placeholder="Enter Identifier Name* e.g. Lobby Camera"
                                        defaultValue={selectedDevice.IdentifierName}
                                        onKeyPress={(e) => {
                                          onSpecialChangeOnly(e);
                                          setIdentifierError('')
                                        }}
                                        onChange={() => { setIdentifierError('') }}
                                      />
                                      <p className="Error_P" style={{ float: "left" }}><span>{identifierError}</span></p>
                                    </div>
                                  </div>

                                  <div style={{ alignItems: "center" }}>
                                    {
                                      selectedDevice.ParentCameraID == 0 && interfaceType == ACS && !bulkUpload &&
                                      (
                                        <button className="DataAccessEdit" style={{ width: "100px" }} onClick={() => handleBulkUploadClick()}>
                                          <span className="ViewUpdate">Bulk upload</span>
                                        </button>
                                      )
                                    }
                                    {
                                      selectedDevice.ParentCameraID == 0 && interfaceType == ACS && bulkUpload &&
                                      (
                                        <button lassName="DataAccessEdit" style={{ width: "100px" }}
                                          onClick={handleSubmit((e) => {
                                            OnAddBulkDevicesClick(e, importedFileHeaders);
                                          })}
                                        >
                                          <span className="ViewUpdate">Upload</span>
                                        </button>
                                      )
                                    }
                                    {
                                      selectedDevice.ParentCameraID != 0 && (interfaceType === NVRDVR || interfaceType === ACS) &&
                                      (
                                        <button className="DataAccessEdit" style={{ width: "100px" }}
                                          onClick={() => {
                                            let temp = interfaceDevice.InterfacesubtypeId;
                                            if (interfaceType === NVRDVR) {
                                              let v1 = devicesmasterlist.cameraTypeMasterList.filter(x => x.brandName.toLowerCase() == interfaceDevice.Interfacesubtypename.toLowerCase());
                                              if (v1 != undefined && v1.length > 0) {
                                                temp = v1[0].brandId;
                                              }
                                            } else {
                                              let v1 = devicesmasterlist.panelTypeMasterList.filter(x => x.panelName.toLowerCase() == interfaceDevice.Interfacesubtypename.toLowerCase());
                                              if (v1 != undefined && v1.length > 0) {
                                                temp = v1[0].panelMasterID;
                                              }
                                            }
                                            console.log("AddNew", temp)
                                            let deviceObject = {
                                              "ParentCameraID": 0,
                                              "cameraName": '',
                                              "privateIPAddress": interfaceDevice.InterfaceIP,
                                              "SiteID": interfaceDevice.siteid,
                                              "userName": interfaceDevice.UserName,
                                              "password": interfaceDevice.Password,
                                              "port": interfaceDevice.InterfacePort,
                                              "channelNumber": '',
                                              "liveStreamTypeNumber": '',
                                              "playbackStreamTypeNumber": '',
                                              "rTSPURL": '',
                                              "location": '',
                                              "PanelMasterId": interfaceType === NVRDVR ? 0 : temp,
                                              "CameraBrandId": temp,
                                              "communicationPort": '',
                                              "httpPort": '',
                                              "isTranscoded": 0,
                                              "isPlaybackTranscoded": 0,
                                              "isTwoWayEnabledDevice": 0,
                                              "CCTVTypeID": interfaceDevice.EntityTypeID,
                                              "TimeZoneIdDevice": interfaceDevice.TimeZoneId,
                                              "identifierName": ""
                                            }
                                            setSelectedDevice(deviceObject);
                                            setIsDeviceEdit(true)
                                            setAssociateCamSecVisibility(false);
                                            setParentIsEncoding(false);
                                            setParentIsPlaybackEncoding(false);
                                            setParentIsEnablingTwoWayDevice(false)
                                            setParentIsEnablingTwoWayInterface(false)
                                            setParentIsEnablingPlaybackTimeStampsToUTC(false)
                                            setParentIsEnablingPlaybackLongDateFormat(false)
                                            clearCameraFields()
                                          }
                                          }
                                        >
                                          <span className="ViewUpdate">Add New</span>
                                        </button>
                                      )
                                    }

                                    {
                                      selectedDevice.ParentCameraID != 0 && !isDeviceEdit && selectedDevice.panelMasterId != 0 && (interfaceType === NVRDVR || interfaceType === ACS) &&
                                      (
                                        <button className="DataAccessEdit" style={{ width: "100px" }}
                                          onClick={() => {
                                            setIsDeviceEdit(true);
                                          }}
                                        >
                                          <span className="ViewUpdate">Edit</span>
                                        </button>
                                      )
                                    }
                                    {
                                      selectedDevice.ParentCameraID == 0 && (interfaceType === NVRDVR || interfaceType === ACS) && !bulkUpload &&
                                      (
                                        <button className="DataAccessEdit" style={{ width: "100px" }}
                                          onClick={handleSubmit((e) => {
                                            if (interfaceType === NVRDVR) {
                                              OnSaveDevice(e, selectedDevice.ParentCameraID)
                                            } else {
                                              onSaveACS(e, selectedDevice.ParentCameraID)
                                              // Call Save Function of updating ACS device.
                                            }
                                          })}
                                        >
                                          <span className="ViewUpdate">Save</span>
                                        </button>
                                      )
                                    }
                                    {
                                      selectedDevice.ParentCameraID != 0 && isDeviceEdit && (interfaceType === NVRDVR || interfaceType === ACS) &&
                                      (
                                        <>
                                          <button className="DataAccessEdit" style={{ width: "100px" }}
                                            onClick={handleSubmit((e) => {
                                              if (interfaceType === NVRDVR) {
                                                OnSaveDevice(e, selectedDevice.ParentCameraID)
                                              } else {
                                                onSaveACS(e, selectedDevice.ParentCameraID)
                                                // Call Save Function of updating ACS device.
                                              }
                                            })}
                                          >
                                            <span className="ViewUpdate">Save</span>
                                          </button>
                                          <button className="DataAccessEdit" style={{ width: "100px" }} onClick={(e) => onCancelDevice(e)}>
                                            <span className="ViewUpdate">Cancel</span>
                                          </button>
                                        </>
                                      )
                                    }
                                    {
                                      selectedDevice.ParentCameraID != 0 && !isDeviceEdit && (interfaceType === NVRDVR || interfaceType === ACS) &&
                                      (
                                        <button className="DataAccessEdit" style={{ width: "100px" }} onClick={() => {
                                          interfaceType === NVRDVR
                                            ? checkForAlertsAndDelDevice(selectedDevice)
                                            : interfaceType === ACS
                                              ? checkForAlertsAndDelDevice(selectedDevice)
                                              : OnAddIDSPanel(null, selectedDevice.ParentCameraID, "deletePanel")
                                        }}>
                                          <span className="ViewUpdate">Delete</span>
                                        </button>
                                      )
                                    }
                                  </div>

                                  {
                                    selectedDevice.ParentCameraID != 0
                                      ?
                                      <>
                                        <div className="ACSCustomerFilterMainDiv w40dot75rem mt35">
                                          <div className="ACSTextBox mt-0">
                                            <p className="SiteNameText mb-0" style={{ fontSize: "1.2rem" }}>
                                              Associate Device
                                            </p>
                                            <div className='filterinnerboxdiv1'>
                                              <div>
                                                <div className="SelectionListWithSelectAll">
                                                  <input
                                                    className="SearchViewInput1"
                                                    style={{ width: "100%" }}
                                                    type="search"
                                                    placeholder="Search"
                                                    value={cameraSearch}
                                                    onChange={(e) => searchCameraHandler(e, "Camera")}
                                                  />
                                                  {/* {
                                                                            isDarkTheme ?
                                                                                <img src={SearchIcon} alt='search icon' style={{ cursor: 'pointer' }} /> :
                                                                                <img src={SearchIconWhite} height="15" alt='search icon' style={{ cursor: 'pointer' }} />
                                                                        } */}
                                                </div>
                                              </div>
                                              {/* {
                                                true > 0
                                                  ?
                                                  <div className='dataAccessListItem ACSCustomerFilter mt-2'
                                                    style={{ textAlign: "left", marginLeft: "5px" }}
                                                  >
                                                    <input type="checkbox"
                                                      checked={cameraSelectAll}
                                                      onChange={handleCameraSelectAll}
                                                      disabled={!editAssociate}
                                                    />
                                                    <p className="AllTextwithValue mb-1" style={{ fontWeight: "500" }}
                                                      onClick={() => {
                                                        if (editAssociate) {
                                                          handleCameraSelectAll()
                                                        }
                                                      }}
                                                      disabled={!editAssociate}
                                                    >
                                                      Select all <span>{cameraCount}</span>
                                                    </p>
                                                  </div>
                                                  : null
                                              } */}
                                              <div className='HgtFilterBoxList' style={{ textAlign: "left", marginLeft: "5px", height: "17vh" }}>
                                                {
                                                  acsList.length > 0
                                                  ?
                                                  acsList.map((item) => (
                                                    <div key={item.deviceId} className="dataAccessListItem">
                                                      <input type="checkbox" checked={item.isChecked} disabled={!editAssociate} onChange={(e) => customerCheckHandler(e, item.deviceId)} />
                                                      <p className="AllTextwithValue" disabled={!editAssociate} onClick={(e) => {
                                                        if (editAssociate) {
                                                          customerCheckHandler(e, item.deviceId)
                                                        }
                                                      }}>{item.cameraName}</p>
                                                    </div>
                                                  ))
                                                  : 
                                                  <p className="AllTextwithValue" >No Cameras Found.</p>
                                                }
                                                {/* <div className="dataAccessListItem">
                                                                                <input type="checkbox" />
                                                                                <p className="AllTextwithValue">Device1</p>
                                                                            </div>
                                                                            <div className="dataAccessListItem">
                                                                                <input type="checkbox" />
                                                                                <p className="AllTextwithValue">Device2</p>
                                                                            </div>
                                                                            <div className="dataAccessListItem">
                                                                                <input type="checkbox" />
                                                                                <p className="AllTextwithValue">Device3</p>
                                                                            </div>
                                                                            <div className="dataAccessListItem">
                                                                                <input type="checkbox" />
                                                                                <p className="AllTextwithValue">Device4</p>
                                                                            </div>
                                                                            <div className="dataAccessListItem">
                                                                                <input type="checkbox" />
                                                                                <p className="AllTextwithValue">Device5</p>
                                                                            </div>
                                                                            <div className="dataAccessListItem">
                                                                                <input type="checkbox" />
                                                                                <p className="AllTextwithValue">Device6</p>
                                                                            </div> */}
                                              </div>
                                            </div>
                                          </div>
                                        </div>

                                        <div style={{ alignItems: "center" }}>
                                          {
                                            !editAssociate
                                              ?
                                              <button className="DataAccessEdit" style={{ width: "170px" }} onClick={() => setEditAssociate(true)}>
                                                <span className="ViewUpdate">Edit Associate Devices</span>
                                              </button>
                                              :
                                              <>
                                                <button className="DataAccessEdit" style={{ width: "170px" }} onClick={() => associatetoACS(selectedDevice)}>
                                                  <span className="ViewUpdate">Save Associate Devices</span>
                                                </button>
                                                <button className="DataAccessEdit" style={{ width: "170px" }} onClick={cancelACSAssociation}>
                                                  <span className="ViewUpdate">Cancel Associate Devices</span>
                                                </button>
                                              </>
                                          }
                                        </div>
                                      </>
                                      :
                                      null
                                  }

                                </div>
                              </div>
                          }

                        </>
                        :
                        <>
                          <h1 className="MainHeaderName">Panel Details</h1>
                          <div className="siteformContainer">
                            {
                              bulkUpload
                                ? <PanelBulkUpload
                                  selectedHeaders={selectedHeaders}
                                  onPanelBulkUpload={onPanelBulkUpload}
                                  setSelectedHeaders={setSelectedHeaders}
                                  clearImportedPanelHeaders={clearImportedPanelHeaders}
                                  setImportedFileData={setImportedFileData}
                                  panelBulkUploadResponse={panelBulkUploadResponse}
                                  setPanelBulkUploadResponse={setPanelBulkUploadResponse}
                                  panelBulkUploadError={panelBulkUploadError}
                                  setPanelBulkUploadError={setPanelBulkUploadError}
                                  setBulkUpload={setBulkUpload}
                                  setIsSingleOrBulkSelection={setIsSingleOrBulkSelection}
                                  importedPanelHeaders={importedPanelHeaders}
                                  setImportedPanelHeaders={setImportedPanelHeaders}
                                />
                                :
                                <>
                                  <form>
                                    <div className="site-form" style={{ display: "flex", flexDirection: "column", alignItems: "center" }}>
                                      <div className="inputMainContainer w40dot75rem">
                                        <div className="inputTypeLabelContainer">
                                          <label className='formFieldLabels'>Brand Name</label>
                                          <select
                                            {...register("PanelMasterId")}
                                            // className="SiteNameTextBox"
                                            className="inputType ArrowSelectClass"
                                            placeholder="Select Brand Name"
                                            disabled
                                            defaultValue={selectedDevice.panelMasterId}
                                            onChange={(e) => setValue("PanelMasterId", e.target.value)}
                                          >
                                            <option key="0" disabled={false} value="">Brand Name*</option>
                                            {devicesmasterlist &&
                                              devicesmasterlist.panelTypeMasterList &&
                                              devicesmasterlist.panelTypeMasterList.map((site) => (
                                                <option value={site.panelMasterID} key={site.panelName}>
                                                  {site.panelName}
                                                </option>
                                              ))}
                                          </select>
                                        </div>
                                      </div>

                                      <div className="inputMainContainer w40dot75rem">
                                        <div className="inputTypeLabelContainer">
                                          <label className='formFieldLabels'>Panel Name</label>
                                          <input
                                            type="text"
                                            {...register("cameraName")}
                                            className="inputType"
                                            disabled={true}
                                            placeholder="Enter Panel name* e.g. Thane panel"
                                            defaultValue={selectedDevice.CameraName}
                                            onKeyPress={onSpecialChangeOnly}
                                          />
                                        </div>
                                      </div>

                                      <div className="inputMainContainer w40dot75rem">
                                        <div className="inputTypeLabelContainer">
                                          <label className='formFieldLabels'>IP Address</label>
                                          <input
                                            {...register("privateIPAddress")}
                                            className="inputType"
                                            disabled={true}
                                            placeholder="IP address* e.g. 192.168.0.54"
                                            defaultValue={selectedDevice.privateIPAddress}
                                          />
                                        </div>
                                      </div>

                                      <div className="inputMainContainer w40dot75rem">
                                        <div className="inputTypeLabelContainer">
                                          <label className='formFieldLabels'>Site</label>
                                          <select
                                            {...register("SiteID")}
                                            className="inputType ArrowSelectClass"
                                            placeholder="Site"
                                            disabled={true}
                                            defaultValue={selectedDevice.SiteID}
                                            onChange={(e) => setValue("SiteID", e.target.value)}
                                          >
                                            <option key="0" disabled={false} value="0">Branch Name*</option>
                                            {sites &&
                                              sites.map((site) => (
                                                <option value={site.SiteID} key={site.SiteID}>
                                                  {site.SiteName}
                                                </option>
                                              ))}
                                          </select>
                                        </div>
                                      </div>
                                    </div>

                                  </form>
                                  {associateCamSecVisibility && (
                                    <ConfigurePanelZones
                                      selectedDevice={selectedDevice}
                                      devicesmasterlist={devicesmasterlist}
                                      OnAddIDSPanel={OnAddIDSPanel}
                                      setselectedZone={setselectedZone}
                                      selectedZone={selectedZone}
                                      isDeviceEdit={isDeviceEdit}
                                    />
                                  )}
                                  {associateCamSecVisibility && (
                                    <AssociateCameraOnSensor
                                      selectedDevice={selectedDevice}
                                      onSensorSelection={onSensorSelection}
                                      associatedDevices={associatedDevices}
                                      onAssociate={onAssociate}
                                      selectedSensor={selectedSensor}
                                      selectedItems={selectedItems}
                                      setSelectedItems={setSelectedItems}
                                      isDeviceEdit={isDeviceEdit}
                                    />
                                  )}
                                </>
                            }
                          </div>
                        </>
                  }
                  {
                    interfaceType === ACS
                      ? null
            
                      // <div style={{ textAlign: "center" }}>
                      // {
                      //   bulkUpload &&
                      //   (
                      //     <button className="DataAccessEdit" style={{ width: "100px" }}
                      //     onClick={handleSubmit((e) => {
                      //       onAcsBulkUpload(e, importedAcsHeaders);
                      //     })}
                      //   >
                      //     <span className="ViewUpdate">Upload</span>
                      //   </button>
                      //   )
                      // }
                      // </div>
      
                      :
                      <div style={{ textAlign: "center" }}>
                        {
                          selectedDevice.ParentCameraID != 0 && (interfaceType === NVRDVR || interfaceType === ACS) &&
                          (
                            <button className="DataAccessEdit" style={{ width: "100px" }}
                              onClick={() => {
                                let temp = interfaceDevice.InterfacesubtypeId;
                                if (interfaceType === NVRDVR) {
                                  let v1 = devicesmasterlist.cameraTypeMasterList.filter(x => x.brandName.toLowerCase() == interfaceDevice.Interfacesubtypename.toLowerCase());
                                  if (v1 != undefined && v1.length > 0) {
                                    temp = v1[0].brandId;
                                  }
                                } else {
                                  let v1 = devicesmasterlist.panelTypeMasterList.filter(x => x.panelName.toLowerCase() == interfaceDevice.Interfacesubtypename.toLowerCase());
                                  if (v1 != undefined && v1.length > 0) {
                                    temp = v1[0].panelMasterID;
                                  }
                                }
                                console.log("AddNew", temp)
                                let deviceObject = {
                                  "ParentCameraID": 0,
                                  "cameraName": '',
                                  "privateIPAddress": interfaceDevice.InterfaceIP,
                                  "SiteID": interfaceDevice.siteid,
                                  "userName": interfaceDevice.UserName,
                                  "password": interfaceDevice.Password,
                                  "port": interfaceDevice.InterfacePort,
                                  "channelNumber": '',
                                  "liveStreamTypeNumber": '',
                                  "playbackStreamTypeNumber": '',
                                  "rTSPURL": '',
                                  "location": '',
                                  "PanelMasterId": interfaceType === NVRDVR ? 0 : temp,
                                  "CameraBrandId": temp,
                                  "communicationPort": '',
                                  "httpPort": '',
                                  "isTranscoded": 0,
                                  "isPlaybackTranscoded": 0,
                                  "isTwoWayEnabledDevice": 0,
                                  "CCTVTypeID": interfaceDevice.EntityTypeID,
                                  "TimeZoneIdDevice": interfaceDevice.TimeZoneId,
                                  "identifierName": ""
                                }
                                setSelectedDevice(deviceObject);
                                setIsDeviceEdit(true)
                                setAssociateCamSecVisibility(false);
                                setParentIsEncoding(false);
                                setParentIsPlaybackEncoding(false);
                                setParentIsEnablingTwoWayDevice(false)
                                setParentIsEnablingTwoWayInterface(false)
                                setParentIsEnablingPlaybackTimeStampsToUTC(false)
                                setParentIsEnablingPlaybackLongDateFormat(false)
                                clearCameraFields()
                              }
                              }
                            >
                              <span className="ViewUpdate">Add New</span>
                            </button>
                          )
                        }
                        {/* rupesh gupta */}
                        {
                          selectedDevice.ParentCameraID == 0 && interfaceType === NVRDVR && !bulkUpload &&
                          (
                            <button className="DataAccessEdit" style={{ width: "100px" }} onClick={() => handleBulkUploadClick()}>
                              <span className="ViewUpdate">Bulk upload</span>
                            </button>
                          )
                        }
                        {
                          selectedDevice.ParentCameraID == 0 && interfaceType === NVRDVR && bulkUpload &&
                          (
                            <button className="DataAccessEdit" style={{ width: "100px" }}
                              onClick={handleSubmit((e) => {
                                OnAddBulkDevicesClick(e, importedFileHeaders);
                              })}
                            >
                              <span className="ViewUpdate">Upload</span>
                            </button>
                          )
                        }
                        {
                          interfaceType === PANEL && !bulkUpload &&
                          (
                            <button className="DataAccessEdit" style={{ width: "100px" }} onClick={() => handleBulkUploadClick()}>
                              <span className="ViewUpdate">Bulk upload</span>
                            </button>
                          )
                        }
                        {
                          interfaceType === PANEL && bulkUpload &&
                          (
                            <button className="DataAccessEdit" style={{ width: "100px" }}
                              onClick={handleSubmit((e) => {
                                onPanelBulkUpload(e, importedPanelHeaders);
                              })}
                            >
                              <span className="ViewUpdate">Upload</span>
                            </button>
                          )
                        }
                        {
                          selectedDevice.ParentCameraID != 0 && !isDeviceEdit && selectedDevice.panelMasterId != 0 && (interfaceType === NVRDVR || interfaceType === ACS) &&
                          (
                            <button className="DataAccessEdit" style={{ width: "100px" }}
                              onClick={() => {
                                setIsDeviceEdit(true);
                              }}
                            >
                              <span className="ViewUpdate">Edit</span>
                            </button>
                          )
                        }

                        {
                          selectedDevice.ParentCameraID == 0 && (interfaceType === NVRDVR || interfaceType === ACS) && !bulkUpload &&
                          (
                            <button className="DataAccessEdit" style={{ width: "100px" }}
                              onClick={handleSubmit((e) => {
                                if (interfaceType === NVRDVR) {
                                  OnSaveDevice(e, selectedDevice.ParentCameraID)
                                } else {
                                  // Call Function of saving ACS device
                                  onSaveACS(e, selectedDevice.ParentCameraID)
                                }
                              }
                              )}
                              disabled={isSubmitting}
                            >
                              <span className="ViewUpdate">Save</span>
                            </button>
                          )
                        }
                        {
                          selectedDevice.ParentCameraID != 0 && isDeviceEdit && (interfaceType === NVRDVR || interfaceType === ACS) &&
                          (
                            <>
                              <button className="DataAccessEdit" style={{ width: "100px" }}
                                onClick={handleSubmit((e) => {
                                  if (interfaceType === NVRDVR) {
                                    OnSaveDevice(e, selectedDevice.ParentCameraID)
                                  } else {
                                    onSaveACS(e, selectedDevice.ParentCameraID)
                                    // Call Save Function of updating ACS device.
                                  }
                                })}
                                disabled={isSubmitting}
                              >
                                <span className="ViewUpdate">Save</span>
                              </button>
                              <button className="DataAccessEdit" style={{ width: "100px" }} onClick={(e) => onCancelDevice(e)}>
                                <span className="ViewUpdate">Cancel</span>
                              </button>
                            </>
                          )
                        }
                        {
                          selectedDevice.ParentCameraID != 0 && !isDeviceEdit && (interfaceType === NVRDVR || interfaceType === ACS) &&
                          (
                            <button className="DataAccessEdit" style={{ width: "100px" }} onClick={() => {
                              interfaceType === NVRDVR
                                ? checkForAlertsAndDelDevice(selectedDevice)
                                : interfaceType === ACS
                                  ? checkForAlertsAndDelDevice(selectedDevice)
                                  : OnAddIDSPanel(null, selectedDevice.ParentCameraID, "deletePanel")
                            }}>
                              <span className="ViewUpdate">Delete</span>
                            </button>
                          )
                        }
                      </div>
                  }

                </div>
              </div>
        }

        <Modal size="lg" show={show} onHide={() => setShow(false)} dialogClassName="modal-90w" backdrop="static" className="AddCustomerModal">
          <>
            <Modal.Header >
              <Modal.Title id="example-custom-modal-styling-title">
                Add Customer
              </Modal.Title>
              <img
                src={RemoveIcon}
                alt="Remove"
                style={{ cursor: "pointer" }}
                onClick={() => {
                  setShow(false)
                  setAddCustomerError('')
                }}
              />
            </Modal.Header>

            <Modal.Body>
              <div className="inputMainContainer w40dot75rem w-100">
                <div className="inputTypeLabelContainer">
                  <label className='formFieldLabels'>Customer Name</label>
                  <input style={{ width: "40vw" }}
                    type="text"
                    className="inputType"
                    placeholder="Add Customer"
                    {...register("AddCustomerName")}
                    onChange={(e) => setAddCustomerError('')}
                  />
                  <p className="Error_P" style={{ float: "left" }}><span>{addCustomerError}</span></p>
                </div>
              </div>
              <div style={{textAlign: "center"}}> 
              <button className="DataAccessEdit mt-3" style={{ width: "250px" }} onClick={addCustomer}>
                <span className="ViewUpdate">Add</span>
              </button>
              </div>
            </Modal.Body>
          </>
        </Modal>
      </div>
    </div>
  );
});

export default AddSiteForm