import React, { useEffect, useContext } from 'react'
import { Link, useHistory, useLocation } from "react-router-dom"
import { SiteTemplateLocBlue, SiteTemplateLocWhite, UsersBlue, UsersWhite, NotificationSelected, NotificationUnSelected, AlertPrePostSelected, AlertPrePostDefault, SoundSettingSelected, SoundSettingDefault } from '../../assets/images';
import { VERSION, IS_SITE_MANAGEMENT_TAB_HIDDEN, IS_ALERT_TEMPLATE_TAB_HIDDEN, IS_USERS_TAB_HIDDEN, IS_NOTIFICATION_SETTING_TAB_HIDDEN, IS_PREPOSTSETTING_TAB_HIDDEN, IS_EMAILSETTING_TAB_HIDDEN, IS_SOUNDSETTING_TAB_HIDDEN } from '../../config';
import { DrawerContext } from "../../Context/DrawerContext";
import alertSelected from '../../assets/images/sidebar-logo/alertSelected.svg'
import alertUnselected from '../../assets/images/sidebar-logo/alertUnselected.svg'
import { ABOUTTERMS, CONFIG_ALERTTEMPLATE, CONFIG_DATACCESSGROUP, CONFIG_FR, CONFIG_MANAGESITEGROUPS, CONFIG_NOTIFICATION, CONFIG_PREPOST, CONFIG_SOUND, CONFIG_USER, CONFIG_WORKFLOW, CONFIGURATION } from '../../navigation/CONSTANTS';

let tempSiteObj = {
    "SiteID": 0,
    "SiteName": '',
    "BranchID": '',
    "SiteAddress": '',
    "clientID": 0,
    "AddCustomerName": '',
    "CountryName": 0,
    "StateName": 0,
    "CityName": '',
    "SiteRegionName": '',
    "SitePincode": '',
    "SiteArea": '',
}
export default function ConfigSideBar({ list, onNavigate, setIsItemClick, selectedSubMenu,
    setSelectedSubMenu, onMoveNext, selectedMainMenu, selectedMenu, setSelectedMenu, setSelectedSite, setSelectedInterface, setAlertTemplateObj, setIsAlertTemplateEdit, setIsAlertTempFormEnabled, setIsEmptyFormAlert,
    setDeviceTemplateList, setDevTemClicked, setSelTempDevice, setDisplayButtons, isDarkTheme, setActivityLogShow, setInterfaceDataList, setPageState
}) {
    const { collapsed, setCollapsed } = useContext(DrawerContext);
    
    let history = useHistory();
    const location = useLocation()

    useEffect(() => {
        if (selectedSubMenu === 'Site Configuration' || selectedSubMenu === "Manage Devices" || selectedSubMenu === "Manage Site Groups") {
            setIsItemClick(false);
        }
        if (selectedSubMenu === 'Add Alert Template' || selectedSubMenu === "Manage Workflow") {
            setIsItemClick(false);
        }
        if (selectedSubMenu === 'Add User' || selectedSubMenu === "FR Assignment" || selectedSubMenu === "Data Access Group") {
            setIsItemClick(false);
        }
    },)

    useEffect(() => {
        if (location.pathname == CONFIGURATION) {
            setSelectedMenu('Site Management'); 
            setSelectedSubMenu('Site Configuration'); 
            onMoveNext('siteTemplate'); 
            setSelectedSite(tempSiteObj);
        } else if (location.pathname == CONFIG_MANAGESITEGROUPS) {
            setSelectedMenu('Site Management'); 
            setSelectedSubMenu('Manage Site Groups');
            onMoveNext('siteTemplate');
        } else if (location.pathname == CONFIG_ALERTTEMPLATE) {
            setSelectedMenu('Alert Template'); 
            onMoveNext('alertTemplate'); 
            setSelectedSubMenu('Add Alert Template');
            let tObj = {
                templateId: "",
                templateName: "",
                templateClientId: "0",
                templateSiteId: [],
                templateDeviceTypeId: "0",
                templateDeviceSubTypeId: "0",
                templateDevice: [],
                templateAlertType: "0",
                scheduleType: "NA",
                schedulerObj: {},
                tempSelectedSites: [],
                tempSelectedDevices: [],
            }
            setAlertTemplateObj(tObj);
            setIsAlertTemplateEdit(true)
            setIsAlertTempFormEnabled(true);
            setIsEmptyFormAlert(true);
            setDeviceTemplateList([]);
            setDevTemClicked(false);
            setSelTempDevice(null);
            setDisplayButtons(true);
            setActivityLogShow(false);
        } else if (location.pathname == CONFIG_WORKFLOW) {
            setSelectedMenu('Alert Template'); 
            setSelectedSubMenu('Manage Workflow');
            onMoveNext('alertTemplate')
        } else if (location.pathname == CONFIG_USER) {
            console.log("location.pathname", location.pathname)
            setSelectedMenu('Users'); 
            setSelectedSubMenu('Add User');
            onMoveNext('users'); 
        } else if (location.pathname == CONFIG_FR) {
            setSelectedMenu('Users');
            setSelectedSubMenu('FR Assignment');
            onMoveNext('users');
        } else if (location.pathname == CONFIG_DATACCESSGROUP) {
            setSelectedMenu('Users');
            setSelectedSubMenu('Data Access Group');
            onMoveNext('users');
        } else if(location.pathname == CONFIG_SOUND){
            setSelectedMenu('Sound Setting');
            onMoveNext('soundSetting');
        } else if (location.pathname == CONFIG_PREPOST) {
            setSelectedMenu('Alert Pre Post Setting');
            onMoveNext('prePostSetting');
        } else if (location.pathname == CONFIG_NOTIFICATION) {
            setSelectedMenu('Notification Setting');
            onMoveNext('notification');
        } 
    }, [location.pathname]);

    useEffect(() => {
        if (selectedMainMenu) {
            setSelectedMenu(selectedMainMenu)
        }
    }, [selectedMainMenu])

    return (
        <div>
            <div className={collapsed ? 'sidebar-minimized' : ''}>
                <div className="sidebar left updatedSidebar">
                    <nav className="sidebar-nav">
                        <div className="menu-list">
                            <ul id="menu-content" className="navList borderLineList">
                                <li
                                    style={{ display: IS_SITE_MANAGEMENT_TAB_HIDDEN ? 'none' : 'block' }}
                                    onClick={() => {
                                        if (selectedMenu == 'Site Management' && selectedSubMenu == 'Site Configuration') {
                                            return;
                                        }
                                        setSelectedMenu('Site Management'); onMoveNext('siteTemplate'); setSelectedSubMenu('Site Configuration'); setSelectedSite(tempSiteObj); history.push(CONFIGURATION)
                                        setInterfaceDataList(null)
                                    }}
                                    className={selectedMenu == "Site Management" ? "nav-item nav-dropdown open" : 'nav-item nav-dropdown'}>
                                    <a className='nav-link'>
                                        {
                                            !isDarkTheme ?
                                                <>
                                                    <img src={selectedMenu == "Site Management" ? SiteTemplateLocWhite : SiteTemplateLocWhite} alt="Logo" height={17} width={17} />
                                                    <span className={`pl-3 ${selectedMenu == "Site Management" ? "nav-label" : "nav-label"}`}>Site Management</span>
                                                </>
                                                :
                                                <>
                                                    <img className='onHoverImage' src={selectedMenu == "Site Management" ? SiteTemplateLocWhite : SiteTemplateLocBlue} alt="Logo" height={17} width={17} />
                                                    <img src={SiteTemplateLocWhite} className='onHoverActiveImage' height={17} width={17} />
                                                    <span className={`pl-3 ${selectedMenu == "Site Management" ? "nav-label" : "nav-label"}`}>Site Management</span>
                                                </>
                                        }
                                    </a>
                                </li>
                                {selectedMenu == "Site Management" &&
                                    <ul className={`nav-dropdown-items`} id="products">
                                        <li
                                            style={{ display: IS_SITE_MANAGEMENT_TAB_HIDDEN ? 'none' : 'block' }}
                                            className={`${selectedSubMenu == 'Site Configuration' ? 'active-sub-menu' : ''}`}
                                            onClick={() => {
                                                if (selectedMenu == 'Site Management' && selectedSubMenu == 'Site Configuration') {
                                                    return;
                                                }
                                                onNavigate('Site Configuration');
                                                setIsItemClick(false);
                                                setSelectedSubMenu('Site Configuration');
                                                setSelectedSite(tempSiteObj);
                                                setInterfaceDataList(null)
                                                history.push(CONFIGURATION);
                                            }}>
                                            <span className='sidebarLabel'>Site Configuration</span>
                                        </li>
                                        <li
                                            style={{ display: IS_SITE_MANAGEMENT_TAB_HIDDEN ? 'none' : 'block' }}
                                            className={`${selectedSubMenu == 'Manage Site Groups' ? 'active-sub-menu' : ''}`} onClick={() => {
                                                onNavigate('Manage Site Groups');
                                                setIsItemClick(false);
                                                setSelectedSubMenu('Manage Site Groups');
                                                history.push(CONFIG_MANAGESITEGROUPS);
                                            }}>
                                            <span className='sidebarLabel'> Manage Site Groups</span>
                                        </li>
                                    </ul>
                                }
                                <li
                                    style={{ display: IS_ALERT_TEMPLATE_TAB_HIDDEN ? 'none' : 'block' }}
                                    onClick={() => {
                                        setSelectedMenu('Alert Template'); onMoveNext('alertTemplate'); setSelectedSubMenu('Add Alert Template');
                                        let tObj = {
                                            templateId: "",
                                            templateName: "",
                                            templateClientId: "0",
                                            templateSiteId: [],
                                            templateDeviceTypeId: "0",
                                            templateDeviceSubTypeId: "0",
                                            templateDevice: [],
                                            templateAlertType: "0",
                                            scheduleType: "NA",
                                            schedulerObj: {},
                                            tempSelectedSites: [],
                                            tempSelectedDevices: [],
                                        }
                                        setAlertTemplateObj(tObj);
                                        setIsAlertTemplateEdit(true)
                                        setIsAlertTempFormEnabled(true);
                                        setIsEmptyFormAlert(true);
                                        setDeviceTemplateList([]);
                                        setDevTemClicked(false);
                                        setSelTempDevice(null);
                                        setDisplayButtons(true);
                                        setActivityLogShow(false);
                                        history.push(CONFIG_ALERTTEMPLATE);
                                    }}
                                    className={` ${selectedMenu == "Alert Template" ? "nav-item nav-dropdown open" : 'nav-item nav-dropdown'}`}>
                                    <a className='nav-link'>
                                        {
                                            !isDarkTheme ?
                                                <>
                                                    <img src={selectedMenu == "Alert Template" ? alertSelected : alertSelected} alt="Logo" height={17} width={17} />
                                                    <span className={`pl-3 nav-label`}>Alert Template</span>
                                                </>
                                                :
                                                <>
                                                    <img className='onHoverImage' src={selectedMenu == "Alert Template" ? alertSelected : alertUnselected} alt="Logo" height={17} width={17} />
                                                    <img src={alertSelected} className='onHoverActiveImage' height={17} width={17} />
                                                    <span className={`pl-3 nav-label`}>Alert Template</span>
                                                </>
                                        }
                                    </a>
                                </li>
                                {
                                    selectedMenu == "Alert Template" &&
                                    <ul className={`nav-dropdown-items`} id="products">
                                        <li className={`${selectedSubMenu == 'Add Alert Template' ? 'active-sub-menu' : ''}`} onClick={() => {
                                            onNavigate('Add Alert Template');
                                            setIsItemClick(false);
                                            setSelectedSubMenu('Add Alert Template')
                                            history.push(CONFIG_ALERTTEMPLATE)
                                        }}>
                                            <span className='sidebarLabel'> Add Alert Template</span>
                                        </li>
                                        <li className={`${selectedSubMenu == 'Manage Workflow' ? 'active-sub-menu' : ''}`} onClick={() => {
                                            onNavigate('Manage Workflow');
                                            setIsItemClick(false);
                                            setSelectedSubMenu('Manage Workflow');
                                            history.push(CONFIG_WORKFLOW)
                                        }}>
                                            <span className='sidebarLabel'>Manage Workflow</span>
                                        </li>
                                    </ul>
                                }
                                <li
                                    style={{ display: IS_USERS_TAB_HIDDEN ? 'none' : 'block' }}
                                    onClick={() => { setSelectedMenu('Users'); onMoveNext('users'); setSelectedSubMenu('Add User'); history.push(CONFIG_USER)}}
                                    className={` ${selectedMenu == "Users" ? "nav-item nav-dropdown open" : 'nav-item nav-dropdown'}`}>
                                    <a className='nav-link'>
                                        {
                                            !isDarkTheme ?
                                                <>
                                                    <img src={selectedMenu == "Users" ? UsersWhite : UsersWhite} alt="Logo" height={17} width={17} />
                                                    <span className={`pl-3 nav-label`}>Users</span>
                                                </>
                                                :
                                                <>
                                                    <img className='onHoverImage' src={selectedMenu == "Users" ? UsersWhite : UsersBlue} alt="Logo" height={17} width={17} />
                                                    <img src={UsersWhite} className='onHoverActiveImage' height={17} width={17} />
                                                    <span className={`pl-3 nav-label`}>Users</span>
                                                </>
                                        }
                                    </a>
                                </li>
                                {
                                    selectedMenu == "Users" &&
                                    <ul className={`nav-dropdown-items`} id="products">
                                        <li className={`${selectedSubMenu == 'Add User' ? 'active-sub-menu' : ''}`} onClick={() => {
                                            onNavigate('Add User');
                                            setIsItemClick(false);
                                            setSelectedSubMenu('Add User');
                                            history.push(CONFIG_USER)
                                        }}>
                                            <span className='sidebarLabel'> Add User</span>
                                        </li>
                                        <li className={`${selectedSubMenu == 'FR Assignment' ? 'active-sub-menu' : ''}`} onClick={() => {
                                            onNavigate('FR Assignment');
                                            setIsItemClick(false);
                                            setSelectedSubMenu('FR Assignment');
                                            history.push(CONFIG_FR)
                                        }}>
                                            <span className='sidebarLabel'>FR Assignment</span>
                                        </li>
                                        {
                                            <li className={`${selectedSubMenu == 'Data Access Group' ? 'active-sub-menu' : ''}`} onClick={() => {
                                                onNavigate('Data Access Group');
                                                setIsItemClick(false);
                                                setSelectedSubMenu('Data Access Group');
                                                history.push(CONFIG_DATACCESSGROUP)
                                            }}>
                                                <span className='sidebarLabel'>  Data Access Group</span>
                                            </li>
                                        }
                                    </ul>
                                }
                                <li
                                    style={{ display: IS_NOTIFICATION_SETTING_TAB_HIDDEN ? 'none' : 'block' }}
                                    onClick={() => { setSelectedMenu('Notification Setting'); onMoveNext('notification'); history.push(CONFIG_NOTIFICATION) }}
                                    className={` ${selectedMenu == "Notification Setting" ? "nav-item nav-dropdown open noDrop navPage" : 'nav-item nav-dropdown navPage'}`}>
                                    <a className='nav-link'>
                                        {
                                            !isDarkTheme ?
                                                <>
                                                    <img src={selectedMenu == "Notification Setting" ? NotificationSelected : NotificationSelected} alt="Logo" height={17} width={17} />
                                                    <span className={`pl-3 nav-label`}>Notification Setting</span>
                                                </>
                                                :
                                                <>
                                                    <img className='onHoverImage' src={selectedMenu == "Notification Setting" ? NotificationSelected : NotificationUnSelected} alt="Logo" height={17} width={17} />
                                                    <img src={NotificationSelected} className='onHoverActiveImage' height={17} width={17} />
                                                    <span className={`pl-3 nav-label`}>Notification Setting</span>
                                                </>
                                        }
                                    </a>
                                </li>

                                <li
                                    style={{ display: IS_PREPOSTSETTING_TAB_HIDDEN ? 'none' : 'block' }}
                                    onClick={() => { setSelectedMenu('Alert Pre Post Setting'); onMoveNext('prePostSetting'); history.push(CONFIG_PREPOST) }}
                                    className={` ${selectedMenu == "Alert Pre Post Setting" ? "nav-item nav-dropdown open noDrop navPage" : 'nav-item nav-dropdown navPage'}`}>
                                    <a className='nav-link'>
                                        {
                                            !isDarkTheme ?
                                                <>
                                                    <img src={selectedMenu == "Alert Pre Post Setting" ? AlertPrePostSelected : AlertPrePostSelected} alt="Logo" height={14} />
                                                    <span className={`pl-3 nav-label`}>Alert Pre Post Setting</span>
                                                </>
                                                :
                                                <>
                                                    <img className='onHoverImage' src={selectedMenu == "Alert Pre Post Setting" ? AlertPrePostSelected : AlertPrePostDefault} alt="Logo" height={14} />
                                                    <img src={AlertPrePostSelected} className='onHoverActiveImage' height={14} />
                                                    <span className={`pl-3 nav-label`}>Alert Pre Post Setting</span>
                                                </>
                                        }
                                    </a>
                                </li>

                                <li style={{ display: "none" }}
                                    onClick={() => { setSelectedMenu('Email Setting'); onMoveNext('emailSetting'); }}
                                    className={` ${selectedMenu == "Email Setting" ? "nav-item nav-dropdown open noDrop navPage" : 'nav-item nav-dropdown navPage'}`}>
                                    <a className='nav-link'>
                                        {
                                            !isDarkTheme ?
                                                <>
                                                    <img src={selectedMenu == "Email Setting" ? NotificationSelected : NotificationSelected} alt="Logo" height={17} width={17} />
                                                    <span className={`pl-3 nav-label`}>Email Setting</span>
                                                </>
                                                :
                                                <>
                                                    <img className='onHoverImage' src={selectedMenu == "Email Setting" ? NotificationSelected : NotificationUnSelected} alt="Logo" height={17} width={17} />
                                                    <img src={NotificationSelected} className='onHoverActiveImage' height={17} width={17} />
                                                    <span className={`pl-3 nav-label`}>Email Setting</span>
                                                </>
                                        }
                                    </a>
                                </li>

                                <li
                                    style={{ display: IS_SOUNDSETTING_TAB_HIDDEN ? 'none' : 'block' }}
                                    onClick={() => { setSelectedMenu('Sound Setting'); onMoveNext('soundSetting'); history.push(CONFIG_SOUND) }}
                                    className={` ${selectedMenu == "Sound Setting" ? "nav-item nav-dropdown open noDrop navPage" : 'nav-item nav-dropdown navPage'}`}>
                                    <a className='nav-link'>
                                        {
                                            !isDarkTheme ?
                                                <>
                                                    <img src={selectedMenu == "Sound Setting" ? SoundSettingSelected : SoundSettingSelected} alt="Logo" height={17} />
                                                    <span className={`pl-3 nav-label`}>Sound Setting</span>
                                                </>
                                                :
                                                <>
                                                    <img className='onHoverImage' src={selectedMenu == "Sound Setting" ? SoundSettingSelected : SoundSettingDefault} alt="Logo" height={17} />
                                                    <img src={SoundSettingSelected} className='onHoverActiveImage' height={17} />
                                                    <span className={`pl-3 nav-label`}>Sound Setting</span>
                                                </>
                                        }
                                    </a>
                                </li>
                                {
                                    selectedMenu == "Review" &&
                                    <ul className={`nav-dropdown-items`} id="products">
                                        <li className={`${selectedSubMenu == 'Site Template' ? 'active-sub-menu' : ''}`} onClick={() => {
                                            onNavigate('Site Template');
                                            setIsItemClick(false);
                                            setSelectedSubMenu('Site Template');
                                        }}>
                                            <span className='sidebarLabel'> Site Template</span>
                                        </li>
                                        <li className={`${selectedSubMenu == 'Devices' ? 'active-sub-menu' : ''}`} onClick={() => {
                                            onNavigate('Devices');
                                            setIsItemClick(false);
                                            setSelectedSubMenu('Devices');
                                        }}>
                                            <span className='sidebarLabel'> Devices</span>
                                        </li>
                                        <li className={`${selectedSubMenu == 'Alert Template' ? 'active-sub-menu' : ''}`} onClick={() => {
                                            onNavigate('Alert Template');
                                            setIsItemClick(false);
                                            setSelectedSubMenu('Alert Template');
                                        }}>
                                            <span className='sidebarLabel'> Alert Template</span>
                                        </li>
                                        <li className={`${selectedSubMenu == 'Users' ? 'active-sub-menu' : ''}`} onClick={() => {
                                            onNavigate('Users');
                                            setIsItemClick(false);
                                            setSelectedSubMenu('Users');
                                        }}>
                                            <span className='sidebarLabel'> Users</span>
                                        </li>
                                    </ul>
                                }
                            </ul>
                        </div>
                        <div className='versionTextDiv'>
                        <button className='termsText' onClick={(e) => { history.push(ABOUTTERMS) }}>T&C</button>
                            <label className='versionLabel'>Version: {VERSION}</label>
                        </div>
                    </nav>
                </div>
            </div>
        </div>
    )
}