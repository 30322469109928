import React, { useState, useCallback, useEffect, memo } from 'react'

import {DeleteQc, SearchIcon, AddNode, InfoIcon, SearchIconWhite } from '../../../assets/images'
import { deleteDataAccess, deleteFile, getDataAccess, getDetails, getReport } from '../../../services/dataAccessService';

import Tab from "react-bootstrap/Tab";
import Tabs from "react-bootstrap/Tabs";
import Button from "react-bootstrap/Button";
import Pagination from "react-bootstrap/Pagination";
import Table from "react-bootstrap/Table";
import Tooltip from 'react-bootstrap/Tooltip'
import ReactFlow, {
  ReactFlowProvider, Controls,
  addEdge,
} from "react-flow-renderer";
import Swal from 'sweetalert2';
import { OverlayTrigger } from 'react-bootstrap'

import { WarningToast } from '../../../CustomHooks/WarningToast';
import { SuccessToast } from '../../../CustomHooks/SuccessToast';
import { ErrorToast } from '../../../CustomHooks/ErrorToast';
import { useDataAccessPagination } from '../../../CustomHooks/useDataAccessPagination';

import DataAccessControls from './DataAccessControls';
import { ConvertDateThree } from '../../../utils/utils';

const DataAccessGroup = ({
  nodes, setNodes, isNodeSelected, setIsNodeSelected, setSelectedNode, selectedNode, setDisableAdd, disableAdd, editMode, onLoad, setEditMode, isDarkTheme,buttonDisable,setButtonDisable
}) => {
 
  //#region useState start
  const [activeTabKey, setActiveTabKey] = useState('details');
  const [isEditCustomer, setIsEditCustomer] = useState(false);
  const [groupName, setGroupName] = useState("");
  const [groupDescription, setGroupDescription] = useState("");
  const [oldGroupName, setOldGroupName] = useState("");
  const [oldGroupDescription, setOldGroupDescription] = useState("");
  const [oldSelectedNode, setOldSelectedNode] = useState(null);
  const [count, setCount] = useState(0);
  const [deviceData, setDeviceData] = useState([]);
  const [tempDeviceData, setTempDeviceData] = useState([]);
  const [stats, setStats] = useState([]);
  const [sessionStart, setSessionStart] = useState(false);
  const [tempCount, setTempCount] = useState(0);
  const [edgeConnected, setEdgeConnected] = useState(false);
  const [tempParent, setTempParent] = useState("");
  const [adminNodeSelected, setAdminNodeSelected] = useState(false);
  const [newNodeParent, setNewNodeParent] = useState("");

  const [nextClick, prevClick, pageCounter, currentPageNo, limit, totalPages, setCurrentPageNo, setTotalPages, maxPageAllowed, setPageCounter] = useDataAccessPagination(10, 1, 10, 1);
  const [width, setWidth] = useState(40);
  const [tempNode, setTempNode] = useState([]);
  const minWidth = 335;
  const [deviceSearch, setDeviceSearch] = useState("");
  const [deviceTypeSearch, setDeviceTypeSearch] = useState("");
  const [branchIdSearch, setBranchIdSearch] = useState("");
  const [siteSearch, setSiteSearch] = useState("");
  const [pinCodeSearch, setpinCodeSearch] = useState("");
  const [areaSearch, setAreaSearch] = useState("");
  const [regionSearch, setRegionSearch] = useState("");
  const [citySearch, setCitySearch] = useState("");
  const [stateSearch, setStateSearch] = useState("")
  const [countrySearch, setCountrySearch] = useState("");
  const [customerSearch, setCustomerSearch] = useState("");
  const [totalString, setTotalString] = useState("");
  const [disableInputs, setDisableInputs] = useState(false);

  const [isNewNode, setIsNewNode] = useState(false);
  const [totalDevices, setTotalDevices] = useState(0);
  const [reportData, setReportData] = useState([]);
  const [timer, setTimer] = useState(null)
  const [mouseDown, setMouseDown] = useState(false);
  //#endregion useState end

  //#region useEffect start
  useEffect(() => {
    getNodes();
    setActiveTabKey('details');
    setGroupName('');
    setGroupDescription('');
    setSessionStart(false);
    setSelectedNode(null);
    setIsNewNode(false);
    setEdgeConnected(false);
    setDisableAdd(false);
  }, [editMode]);

  useEffect(() => {
    let fname = groupName + "_" + localStorage.getItem('authCode');
    if (selectedNode !== null) {
      getDataAccessDetails(selectedNode.id, tempParent, fname, currentPageNo, totalString, "")
    }
  }, [currentPageNo]);

  useEffect(() => {
    if (selectedNode != null) {
      clearTimeout(timer)
      const newTimer = setTimeout(() => {
        searchAccessDetails();
      }, 500)
      setTimer(newTimer)
    }
  }, [deviceSearch,branchIdSearch,deviceTypeSearch,siteSearch,pinCodeSearch,areaSearch,regionSearch,citySearch,stateSearch, countrySearch, customerSearch]);

  useEffect(() => {
    window.addEventListener('mousedown', handleMouseDown);
    window.addEventListener('mouseup', handleMouseUp);
    return () => {
      window.removeEventListener('mousedown', handleMouseDown);
      window.removeEventListener('mouseup', handleMouseUp);
    };
  }, []);
  //#endregion useEffect end

  //Get Nodes Functions Start
  const getNodes = () => {
    getDataAccess()
      .then((resp) => {
        buildWorkflowTree(resp.Data);
      }).catch(err => {
        console.log(err);
      })
  }
  //Get Nodes Functions End

  // Node Builder Function Start

  // The following function is to the build the tree based on the position of x and y axis.
  const buildWorkflowTree = (arr) => {
    let tempArr = [];
    // This is for plotting the nodes.
    for (let i = 0; i < arr.length; i++) {
      let tempxpos = arr[i].Position_X === "" ? 250 : parseInt(arr[i].Position_X);
      let tempypos = arr[i].Position_Y === "" ? 5 : parseInt(arr[i].Position_Y);

      let obj = {
        id: arr[i].Id.toString(),
        type: i === 0 ? 'input' : 'default',
        position: { x: tempxpos, y: tempypos },
        data: {
          label: (
            <>
              <div className='NodeTitleDescr'>
                <p className='NodeHeading'>{arr[i].Name}</p>
                <p className='NodeDescreption'>{arr[i].Description}</p>
              </div>
              <div className='NodeDeletemainContainer'>
                <div className='nodeDeleteIcon'>
                  {
                    editMode
                      ? <img style={{ width: "12px" }} alt='delete icon' src={DeleteQc} onClick={() => onDeleteNode(arr[i].Id, arr, arr[i].ParentId)} />
                      : null
                  }
                </div>
              </div>
            </>
          )
        },
        databaseId: arr[i].Id,
        parentId: arr[i].ParentId,
        style: null
      }
      tempArr.push(obj);
    }
    //This is for plotting the edges.
    for (let x = 0; x < arr.length; x++) {
      let parentId = 0;
      let source = "";
      let target = "";
      if (arr[x].ParentId !== null) {
        parentId = arr[x].ParentId;
        target = arr[x].Id.toString();
        source = arr[x].ParentId.toString();
        let edgeObj = {
          id: `e${source}-${target}`,
          source: source,
          target: target,
          type: 'edge',
          arrowHeadType: 'arrowclosed',
          style: { strokeWidth: 2, stroke: 'black', }
        }
        tempArr.push(edgeObj);
      }
    }
    setNodes(tempArr);
    setTempNode(tempArr);
    let c = count + 1;
    setCount(c);
  }
  // Node Builder Function End.

  // Node Related Functions Start
  const dragHandler = useCallback(
    (e) => {
      const w =
        ((e.clientX -
          e.currentTarget.parentElement.parentElement.getBoundingClientRect()
            .left) /
          e.currentTarget.parentElement.parentElement.getBoundingClientRect()
            .width) *
        100;
      if (w > 0 && w !== width) {
        setWidth(w);
        document.querySelector(".react-flow__controls-fitview").click();
      }
    }
    // [width]
  );

  /* ---------------------------- Workflow Handlers --------------------------- */
  const onAddNodes = () => {
    if (disableAdd) {
      WarningToast("Please save the data access group before adding other data access group!!");
      return;
    }
    let tempArr = [...nodes];
    let lastIndex;
    let lastX = 250;
    let lastY = 5;
    let toAddNode;
    if (nodes.length === 0) {
      toAddNode = {
        id: '1',
        data: {
          label:
            (
              <>
                <div className='NodeTitleDescr'>
                  <p className='NodeHeading'>Node 1</p>
                  <p className='NodeDescreption'>Sub Heading</p>
                </div>
                <div className='NodeDeletemainContainer'>
                  <div className='nodeDeleteIcon'>
                    {
                      editMode
                        ? <img style={{ width: "12px" }} alt='delete icon' src={DeleteQc} onClick={() => onDeleteNode(1, nodes)} />
                        : null
                    }
                  </div>
                </div>
              </>
            )
        },
        position: { x: lastX, y: lastY },
        type: 'input',
        databaseId: null,
        parentId: null,
      }
    } else {
      for (let i = nodes.length - 1; i >= 0; i--) {
        if (nodes[i].type === "default" || nodes[i].type === "input") {
          lastIndex = nodes[i].id;
          lastX = nodes[i].position.x;
          lastY = nodes[i].position.y;
          break;
        }
      }
      toAddNode = {
        id: (parseInt(lastIndex) + 1).toString(),
        data: {
          label:
            (
              <>
                <div className='NodeTitleDescr'>
                  <p className='NodeHeading'>{ConvertDateThree(new Date())}</p>
                  <p className='NodeDescreption'>Description</p>
                </div>
                <div className='NodeDeletemainContainer'>
                <div className='nodeDeleteIcon'>
                  {
                    editMode
                      ? <img style={{ width: "12px" }} alt='delete icon' src={DeleteQc} onClick={() => onDeleteNode(parseInt(lastIndex) + 1, nodes)} />
                      : null
                  }
                </div>
                </div>
              </>
            )
        },
        position: { x: 0, y: 0 },
        type: 'default',
        databaseId: null,
        parentId: null,
      };
    }
    tempArr.push(toAddNode);
    setNodes(tempArr);
    setTempNode(tempArr);
    let c = count + 1;
    setCount(c);
    setDisableAdd(true);
  };

  const onConnect = (params) => {
    setTempParent(params.source)
    setNewNodeParent(params.source);
    let edgeAlreadyPresent = nodes.filter((item) => item.id == params.target);
    if (edgeAlreadyPresent[0].parentId != null) {
      WarningToast("The data access group already has a parent");
      return;
    }
    setNodes((els) => addEdge({
      ...params,
      type: 'edge',
      arrowHeadType: 'arrowclosed',
      style: { strokeWidth: 2, stroke: 'black', }
    }, els));
    setIsNewNode(false);
    setActiveTabKey('details')
    setEdgeConnected(true);
  }

  const onEdgeUpdate = (oldEdge, newConnection) => {
    let edgeAlreadyPresent = nodes.filter((item) => item.id == newConnection.target);
    if (edgeAlreadyPresent[0].parentId != null) {
      WarningToast("The data access group already has a parent");
      return;
    }
  }

  const getParentId = (id) => {
    let parentId = nodes.find((item) => item.id == id);
    return parentId.parentId;
  }

  const nodeClicked = (event, element) => {
    if (selectedNode?.id == element.id) {
      return;
    }
    if (sessionStart && selectedNode != null && selectedNode?.id != element.id && editMode) {
      Swal.fire({
        title: 'Operation in Progress.',
        text: "You have started the operation for a data access group, do you want to discard any changes and continue to the other data access group.",
        icon: 'warning',
        showCancelButton: true,
        confirmButtonColor: '#027aaa',
        cancelButtonColor: '#d33',
        confirmButtonText: 'Ok!',
        allowOutsideClick: false
    }).then((result) => {
        if (result.isConfirmed) {
          let isItAdmin = element.type == "input";
          setActiveTabKey('details')
          setSessionStart(true);
          setTempCount(tempCount + 1);
          setAdminNodeSelected(isItAdmin);
          let parentId = getParentId(element.id);
          if (parentId == null && !edgeConnected && !isItAdmin) {
            setIsNewNode(true);
          } else {
            setIsNewNode(false);
          }
          setIsNodeSelected(true);
          setSelectedNode(element);

          let tempOldGn = oldGroupName;
          let tempOldGD = oldGroupDescription;
          let tempGroupName = element.data.label.props.children[0].props.children[0].props.children
          setGroupName(element.data.label.props.children[0].props.children[0].props.children);
          setGroupDescription(element.data.label.props.children[0].props.children[1].props.children);
          setOldGroupName(element.data.label.props.children[0].props.children[0].props.children);
          setOldGroupDescription(element.data.label.props.children[0].props.children[1].props.children);
          let style = {
            border: "2px solid #397ff5",
            backgroundColor: '#f2f6fe',
          }
          let newArr = nodes.map((item) => {
            if (item.id == element.id) {
              item.style = style
              return item;
            }
            else if (oldSelectedNode.id == item.id) {
              item.style = null;
              item.data = {
                label:
                  (
                    <>
                      <div className='NodeTitleDescr'>
                        <p className='NodeHeading'>{tempOldGn}</p>
                        <p className='NodeDescreption'>{tempOldGD}</p>
                      </div>
                      <div className='NodeDeletemainContainer'>
                      <div className='nodeDeleteIcon'>
                        {
                          editMode
                            ? <img style={{ width: "12px" }} alt='delete icon' src={DeleteQc} onClick={() => onDeleteNode(item.id, nodes)} />
                            : null
                        }
                        {/* <img style={{ width: "15px" }} src={DeleteQc} onClick={() => onDeleteNode(item.id, nodes)} /> */}
                      </div>
                      </div>
                    </>
                  )
              };
              return item;
            }
            else if (item.type == "default" || item.type == "input") {
              item.style = null;
              return item;
            } else {
              return item;
            }
          })
          setNodes(newArr);
          let c = count + 1;
          setCount(c);
          let tparentId
          if (parentId == 1) {
            tparentId = 0;
          } else {
            tparentId = parentId
          }
          let fname = tempGroupName + "_" + localStorage.getItem('authCode');
          if (tparentId == null) {
            tparentId = tempParent;
            setTempParent(tparentId)
          }
          setTempParent(tparentId)
          setCurrentPageNo(1);
          setPageCounter(1);
          setDeviceSearch("");
          setDeviceTypeSearch("");
          setBranchIdSearch("");
          setSiteSearch("");
          setpinCodeSearch("");
          setAreaSearch("");
          setRegionSearch("");
          setCitySearch("");
          setStateSearch("");
          setCountrySearch("");
          setCustomerSearch("");
          setTotalString("");
          deleteFile(fname);
          setOldSelectedNode(element);
          // getDataAccessDetails(element.id, tparentId, fname, 1, "", "");
          // getDataAccessDetails(-1, tparentId, fname, 1, "", "");
        }

      })
    } else {
      let isItAdmin = element.type == "input";
      setActiveTabKey('details')
      setSessionStart(true);
      setAdminNodeSelected(isItAdmin);
      let parentId = getParentId(element.id);
      if (parentId == null && !edgeConnected && !isItAdmin) {
        setIsNewNode(true);
      } else {
        setIsNewNode(false);
      }
      setIsNodeSelected(true);
      setSelectedNode(element);
      setOldSelectedNode(element);

      let tempGroupName = element.data.label.props.children[0].props.children[0].props.children
      setGroupName(element.data.label.props.children[0].props.children[0].props.children);
      setGroupDescription(element.data.label.props.children[0].props.children[1].props.children);
      setOldGroupName(element.data.label.props.children[0].props.children[0].props.children);
      setOldGroupDescription(element.data.label.props.children[0].props.children[1].props.children);
      let style = {
        border: "2px solid #397ff5",
        backgroundColor: '#f2f6fe',
      }
      let newArr = nodes.map((item) => {
        if (item.id == element.id) {
          item.style = style
          return item;
        } else if (item.type == "default" || item.type == "input") {
          item.style = null;
          return item;
        } else {
          return item;
        }
      })
      setNodes(newArr);
      let c = count + 1;
      setCount(c);
      let tparentId
      if (parentId == 1) {
        tparentId = 0;
      } else {
        tparentId = parentId
      }
      let fname = tempGroupName + "_" + localStorage.getItem('authCode');
      if (tparentId == null) {
        tparentId = tempParent;
        setTempParent(tparentId)
      }
      setTempParent(tparentId)
      deleteFile(fname);
    }
  }

  const changeGroupName = (e) => {
    if (e.target.value.length > 30) {
      WarningToast("Group Name shouldn't exceed 30 characters");
      return;
    }
    let selectedNodeId = selectedNode.id;
    let tempValue = e.target.value.replace(/[’/`~!#*$@%+=,^&(){}[\]|;:”<>?\\]/gi, "");
    if (!/[0-9a-zA-Z]/.test(e.key)) {
      e.preventDefault();
      return;
    } else {
      setGroupName(tempValue);
    }
    let newArr = nodes.map((item) => {
      if (item.id == selectedNodeId) {
        item.data.label = (
          <>
            <div className='NodeTitleDescr'>
              <p className='NodeHeading'>{tempValue}</p>
              <p className='NodeDescreption'>{item.data.label.props.children[0].props.children[1].props.children}</p>
            </div>
            <div className='NodeDeletemainContainer'>
            <div className='nodeDeleteIcon'>
              {
                editMode
                  ? <img style={{ width: "12px" }} alt='delete icon' src={DeleteQc} onClick={() => onDeleteNode(item.id, nodes)} />
                  : null
              }
              {/* <img style={{ width: "15px" }} src={DeleteQc} alt="Delete Icon" onClick={() => onDeleteNode(item.id, nodes)} /> */}
            </div>
            </div>
          </>
        )
        return item;
      } else {
        return item;
      }
    })
    setNodes(newArr);
    let c = count + 1;
    setCount(c);
  }

  const changeGroupDescription = (e) => {
    if (e.target.value.length > 30) {
      WarningToast("Group Description shouldn't exceed 30 characters");
      return;
    }
    let selectedNodeId = selectedNode.id;
    let tempValue = e.target.value.replace(/[’/`~!#*$@%+=,^&(){}[\]|;:”<>?\\]/gi, "");
    if (!/[0-9a-zA-Z]/.test(e.key)) {
      e.preventDefault();
      return;
    } else {
      setGroupDescription(tempValue);
    }

    let newArr = nodes.map((item) => {
      if (item.id == selectedNodeId) {
        item.data.label = (
          <>
            <div className='NodeTitleDescr'>
              <p className='NodeHeading'>{item.data.label.props.children[0].props.children[0].props.children}</p>
              <p className='NodeDescreption'>{tempValue}</p>
            </div>
            <div className='NodeDeletemainContainer'>
              <div className='nodeDeleteIcon'>
                {
                  editMode
                    ? <img style={{ width: "12px" }} alt='delete icon' src={DeleteQc} onClick={() => onDeleteNode(item.id, nodes)} />
                    : null
                }
              </div>
            </div>
          </>
        )
        return item;
      } else {
        return item;
      }
    })
    setNodes(newArr);
    let c = count + 1;
    setCount(c);
  }

  const onDeleteNode = (id, argNodes, parentId) => {
    let c = count + 1;
    setCount(c);

    /* ---------------------------- Delete main node ---------------------------- */
    if (parentId === null) {
      WarningToast("You cannot delete the super admin data access group");
      return;
    }

    /* ------------------------ Delete Node of added node ----------------------- */
    if (parentId === undefined) {
      let elementToRemove = [
        {
          id: id,
          type: 'default',
        }
      ]
      Swal.fire({
        title: 'Are you sure?',
        icon: 'warning',
        showCancelButton: true,
        confirmButtonColor: '#027aaa',
        cancelButtonColor: '#d33',
        confirmButtonText: 'Yes, Delete it!'
      }).then((result) => {
        if (result.isConfirmed) {
          setDisableAdd(false);
          setGroupName('');
          setGroupDescription('');
          setActiveTabKey('details');
          setIsNodeSelected(false);
          setSelectedNode(null);
          setEditMode(!editMode);  
          return;
        } else {
          return;
        }
      })
    }

    let parentIdsArr = argNodes.map((item) => item.ParentId);
    let obj;
    let elementIndex = 0;
    for (let x = 0; x < argNodes.length; x++) {
      if (argNodes[x].Id == id) {
        obj = argNodes[x];
        elementIndex = x;
      }
    }
    let payloadObj = {
      id: obj.Id,
      parentId: obj.ParentId,
      name: obj.Name,
      description: "Sub Heading",
      fileName: "temp1",
      position_X: "100",
      position_Y: "100",
    }

    if (parentIdsArr.includes(id)) {
      Swal.fire({
        title: 'Unable to delete',
        text: "The data access group contains some children data access groups, delete them before deleting this.",
        icon: 'warning',
        showCancelButton: true,
        confirmButtonColor: '#027aaa',
        cancelButtonColor: '#d33',
        confirmButtonText: 'Ok!',
        allowOutsideClick: false
    }).then((result) => {
        if (result.isConfirmed) {
        }
      })
    } else {
      Swal.fire({
        title: 'Are you sure?',
        icon: 'warning',
        showCancelButton: true,
        confirmButtonColor: '#027aaa',
        cancelButtonColor: '#d33',
        confirmButtonText: 'Yes, Delete it!'
      }).then((result) => {
        if (result.isConfirmed) {
          deleteNodes(payloadObj);
          setGroupName('');
          setGroupDescription('');
          setActiveTabKey('details');
        }
      })
    }
  }

  const deleteNodes = (payloadObj) => {
    deleteDataAccess(payloadObj)
      .then((res) => {
        if (res.Status == "success") {
          SuccessToast(res.Message);
          setEditMode(!editMode);
        } else {
          ErrorToast(res.Message);
        }
      }).catch(err => {
        console.log(err);
      })
  }

  const getDataAccessDetails = (id, parentId, fname,pageNo, searchText, searchType, isItSearch) => {
    let oldNode = nodes.filter((item) => item.databaseId == id);
    if (id == 1) {
      id = -1;
      parentId = 0;
    }
    if (parentId == 1) {
      parentId = 0
      id = -1
    }
    if (disableAdd && oldNode.length === 0) {
      id = -1;
      parentId = (parentId == 0) ? parentId : newNodeParent;
    }
    let tempsearchText = searchText.toLowerCase();
    let tempPageNo = pageNo;
    if(isItSearch){
      tempPageNo = 1;
    }
    getDetails(id, parentId, fname, tempPageNo, tempsearchText, searchType)
      .then(res => {
        if (res.Status == "success") {
          let repData = res.Data.replace(/\"/g, '"');
          let temp = JSON.parse(repData);
          let tempArr = temp.Data.map((item) => {
            let obj = {
              ...item,
              isItSelected: true
            }
            return obj;
          })
          setTotalDevices(parseInt(temp.TotalLength));
          setTotalPages(parseInt(temp.TotalLength));
          setDeviceData(tempArr);
          setTempDeviceData(tempArr);
          setStats(temp.LstEntityDataFilterSummary);
        }
      })
  }

  const searchAccessDetails = () => {
    let obj = {
      "customer": customerSearch,
      "country": countrySearch,
      "state": stateSearch,
      "city": citySearch,
      "region": regionSearch,
      "area": areaSearch,
      "pincode": pinCodeSearch,
      "site": siteSearch,
      "branchId": branchIdSearch,
      "deviceType": deviceTypeSearch,
      "device": deviceSearch,
    }
    let fname = groupName + "_" + localStorage.getItem('authCode');
    let stringText = JSON.stringify(obj);
    let tempStringText = stringText.replace(/["'{}]/g, "").replace(/[,]/g, ";")
    setTotalString(tempStringText);
    setCurrentPageNo(1);
    getDataAccessDetails(selectedNode?.id, tempParent, fname, currentPageNo, tempStringText, "", true);
  }
  
  const TempPaginationComponent = (currentPageNo) => {
    let numbers = [];
    if(maxPageAllowed - 2 != 0  && (currentPageNo === maxPageAllowed || currentPageNo === (maxPageAllowed - 1) || currentPageNo === (maxPageAllowed - 2)))
    {
      numbers.push(
      <Pagination.Item
        key={maxPageAllowed - 2}
        active={maxPageAllowed - 2 == currentPageNo}
        onClick={() => setCurrentPageNo(maxPageAllowed - 2)}
      >
        {maxPageAllowed - 2}
      </Pagination.Item>)
      numbers.push(
        <Pagination.Item
          key={maxPageAllowed - 1}
          active={maxPageAllowed - 1 == currentPageNo}
          onClick={() => setCurrentPageNo(maxPageAllowed - 1)}
        >
          {maxPageAllowed - 1}
        </Pagination.Item>) 
      numbers.push(
        <Pagination.Item
          key={maxPageAllowed}
          active={maxPageAllowed == currentPageNo}
          onClick={() => setCurrentPageNo(maxPageAllowed)}
        >
    {maxPageAllowed}
    </Pagination.Item>)
    }
    else if(maxPageAllowed - 1 != 0 && (currentPageNo === maxPageAllowed || currentPageNo === (maxPageAllowed - 1))){
      numbers.push(
        <Pagination.Item
          key={maxPageAllowed - 1}
          active={maxPageAllowed - 1 == currentPageNo}
          onClick={() => setCurrentPageNo(maxPageAllowed - 1)}
        >
          {maxPageAllowed - 1}
        </Pagination.Item>)
        numbers.push(
          <Pagination.Item
            key={maxPageAllowed}
            active={maxPageAllowed == currentPageNo}
            onClick={() => setCurrentPageNo(maxPageAllowed)}
          >
            {maxPageAllowed}
          </Pagination.Item>) 
    }
    else
    {
      for(let i=currentPageNo; i<currentPageNo + 3; i++) {
        if(i <= maxPageAllowed){
          numbers.push(
            <Pagination.Item
              key={i}
              active={i == currentPageNo}
              onClick={() => setCurrentPageNo(i)}
            >
              {i}
            </Pagination.Item>
          )
        }
      }  
    }
    return numbers;
  }

  const handleMouseDown = () => {
    setMouseDown(true);
  };

  const handleMouseUp = () => {
    setMouseDown(false);
  };

  const convertToCSV = (objArray) => {
    let array = typeof objArray != 'object' ? JSON.parse(objArray) : objArray;
    let str = '';

    for (let i = 0; i < array.length; i++) {
      let line = '';
      for (let index in array[i]) {
        if (line != '') line += ','
        line += array[i][index];
      }
      str += line + '\r\n';
    }
    return str;
  }

  const exportHandler = () => {
    setButtonDisable(true)
    let id = selectedNode.id
    let parentId = tempParent
    let fname = groupName + "_" + localStorage.getItem('authCode');
    let oldNode = nodes.filter((item) => item.databaseId == id);
    //API Method to call here
    if (id == 1) {
      id = -1;
      parentId = 0;
    }
    if (tempParent == 1) {
      parentId = 0
      id = -1
    }
    if (disableAdd && oldNode.length === 0) {
      id = -1;
      parentId = (parentId == 0) ? parentId : newNodeParent;
    }
    getReport(id, parentId, fname, "")
      .then(res => {
        if (res.Status == "success") {
          setButtonDisable(false)
          let repData = res.Data.replace(/\"/g, '"');
          let temp = JSON.parse(repData);
          setReportData(temp.Data);
          let stemp = temp.Data;
          if (temp.Data.length == 0) {
            Swal.fire({ text: 'No records available to download.' })
            return
          }

          stemp.map( x => {
            delete x['ClientId']
            delete x['SiteID']
            delete x['countrymasterid']
            delete x['statemasterid']
            delete x['DeviceId']
            delete x['DeviceTypeID']
            delete x['checkflag']
          })

          let header = {
            ClientName: "Customer Name",
            SiteName: "Site Name",
            BranchID: 'Branch Id',
            countryname: "Country Name",
            statename: "State Name",
            City: 'City',
            Region: "Region",
            Area: 'Area',
            PinCode: "Pincode",
            devicename: "Device Name",
            DeviceType: "Device Type",
          }
          stemp.unshift(header);
          let jsonObject = JSON.stringify(stemp);
          let csv = convertToCSV(jsonObject);
          let blob = new Blob([csv], { type: 'text/csv;charset=utf-8;' });
          let link = document.createElement("a");
          let fileN = groupName + "_Data Access Report.csv"
          if (link.download !== undefined) {
            let url = URL.createObjectURL(blob);
            link.setAttribute("href", url);
            link.setAttribute("download", fileN);
            link.style.visibility = 'hidden';
            document.body.appendChild(link);
            link.click();
            document.body.removeChild(link);
          }
        }
      })
      .catch(err => {
        setButtonDisable(false)
        console.log(err);
      })
  }

  return (
    <>
      <div className={editMode ? "ManageSite data-access-container" : "ManageSite data-access-nonedit"} >
        <div style={{ display: "flex", height: "100%" }} className='dataaccessreactflow'>
          <div
            style={{
              width: `${width}%`,
              minWidth: minWidth,
            }}
          >
              <OverlayTrigger
                placement='bottom'
                overlay={
                  <Tooltip>
                    Add data access group
                  </Tooltip>
                }
              >
              <button
                className={editMode ? "DataAccessEdit" : "DAGAddNode"}
                style={editMode
                  ? { float: "right", margin: "4px 4px 0px 0px" }
                  : { margin: "4px 4px 0px 0px" }}
                onClick={onAddNodes}
                disabled={!editMode}
              >
                <span className="ViewUpdate"><img src={AddNode} alt='addNode' /></span>
              </button>
            </OverlayTrigger>
            {/* Left Panel */}

            <ReactFlowProvider>
              <ReactFlow
                style={{ cursor: mouseDown ? "grabbing" : "all-scroll",  }}
                onMoveStart={handleMouseDown} 
                onMoveEnd={handleMouseUp}
                elements={nodes}
                onConnect={editMode ? onConnect : {}}
                onLoad={onLoad}
                onEdgeUpdate={editMode ? onEdgeUpdate : {}}
                onNodeDoubleClick={nodeClicked}
                key={count}
              >
              </ReactFlow>
              <Controls />
            </ReactFlowProvider>
          </div>
          <div
            style={{ borderLeft: "1px solid  #c4c4c4", cursor: "col-resize" }}
            onDrag={dragHandler}
          >
            <div className="ResizePanel-module_ResizeBarHorizontal">
              <div className="ResizePanel-module_ResizeHandleHorizontal">
                <span></span>
              </div>
            </div>
          </div>
          <div
            style={{
              flex: 1,
              minWidth: minWidth,
              width: `${100 - width}%`,
              padding: "15px"
            }}
          >
            <Tabs
              defaultActiveKey="details"
              id="uncontrolled-tab-example"
              className="mb-3"
              onSelect={(k) => {
                if (k == "profile" && activeTabKey == "details") {
                  let fname = groupName + "_" + localStorage.getItem('authCode');
                  getDataAccessDetails(selectedNode.id, tempParent, fname, 1, "", "");
                  setCurrentPageNo(1);
                  setPageCounter(1);
                }
                setActiveTabKey(k)
              }}
              activeKey={activeTabKey}
            >
              <Tab eventKey="details" title="Details"  >
                <div className="mainDataDiv BoxShadowDiv">
                  <div className="m-3 pt-3" style={{ width: "95%" }}>
                    <div className="inputTypeLabelContainerDag">
                      <label htmlFor="email">Data Access Group Name</label>
                      <input
                        className="inputType"
                        id="email"
                        type="text"
                        value={groupName}
                        onChange={changeGroupName}
                        disabled={selectedNode == null || editMode == false || adminNodeSelected == true}
                        placeholder="Ex. Data Access Group Name 1"
                      />
                    </div>
                  </div>
                  <div className="m-3 pt-2" style={{ width: "95%" }}>
                    <div className="inputTypeLabelContainerDag DescContainer mt20">
                      <label htmlFor="email">Data Access Group Description</label>
                      <input
                        className="inputType"
                        id="email"
                        type="text"
                        value={groupDescription}
                        disabled={selectedNode == null || editMode == false || adminNodeSelected == true}
                        onChange={changeGroupDescription}
                        placeholder="Ex. Data Access Group Description"
                      />
                    </div>
                  </div>
                </div>
              </Tab>
              <Tab eventKey="profile" title="Data Access" disabled={!isNodeSelected} >
                <div className="mainDataDiv BoxShadowDiv">
                  {
                    !isNewNode
                      ?
                      <>
                        <DataAccessControls
                          stats={stats}
                          editMode={editMode}
                          isEditCustomer={isEditCustomer}
                          setIsEditCustomer={setIsEditCustomer}
                          groupName={groupName}
                          selectedNode={selectedNode}
                          nodes={nodes}
                          disableAdd={disableAdd}
                          tempParent={tempParent}
                          sessionStart={sessionStart}
                          tempCount={tempCount}
                          getDataAccessDetails={getDataAccessDetails}
                          adminNodeSelected={adminNodeSelected}
                          setDisableInputs={setDisableInputs}
                          isDarkTheme={isDarkTheme}
                        />
                        <div className='SelectionErrorDiv'>
                          <div>
                            <span className="AllTextwithValue">
                              Total device count - <span className="devicesValue">{totalDevices}</span>{" "}
                            </span>
                          </div>
                        </div>
                        <div className="DataTableView">
                          <Table id="data-access-report" striped hover border={0}>
                            <thead>
                              <tr>
                                <th>
                                  <p>Device(s)</p>
                                  <div className="SearchTextDiv StatusSearchText">
                                    <input className="dataAccessSearchInput" type="search" placeholder='Search' value={deviceSearch} 
                                    onChange={(e) => setDeviceSearch(e.target.value)} 
                                    />
                                    {
                                      isDarkTheme ?
                                      <img className="EyeIcon" style={{ paddingBottom: "3px" }} alt="Search-icon" src={SearchIcon}
                                        onClick={() => searchAccessDetails()}
                                      /> :
                                      <img className="EyeIcon" style={{ paddingBottom: "3px" }} alt="Search-icon" src={SearchIconWhite} height="16"
                                        onClick={() => searchAccessDetails()}
                                      />
                                    }
                                  </div>
                                </th>
                                <th>
                                  <p>Device Type(s)</p>
                                  <div className="SearchTextDiv StatusSearchText">
                                    <input className="dataAccessSearchInput" type="search" placeholder='Search' value={deviceTypeSearch} onChange={(e) => setDeviceTypeSearch(e.target.value)} />
                                    {
                                      isDarkTheme ?
                                      <img className="EyeIcon" style={{ paddingBottom: "3px" }} alt="Search-icon" src={SearchIcon}
                                        onClick={() => searchAccessDetails()}
                                      /> :
                                      <img className="EyeIcon" style={{ paddingBottom: "3px" }} alt="Search-icon" src={SearchIconWhite} height="16"
                                        onClick={() => searchAccessDetails()}
                                      />
                                    }
                                  </div>
                                </th>
                                <th>
                                  <p>Branch ID(s)</p>
                                  <div className="SearchTextDiv StatusSearchText">
                                    <input className="dataAccessSearchInput" type="search" placeholder='Search' value={branchIdSearch} onChange={(e) => setBranchIdSearch(e.target.value)} />
                                    {
                                      isDarkTheme ?
                                      <img className="EyeIcon" style={{ paddingBottom: "3px" }} alt="Search-icon" src={SearchIcon}
                                        onClick={() => searchAccessDetails()}
                                      /> :
                                      <img className="EyeIcon" style={{ paddingBottom: "3px" }} alt="Search-icon" src={SearchIconWhite} height="16"
                                        onClick={() => searchAccessDetails()}
                                      />
                                    }
                                  </div>
                                </th>
                                <th>
                                  <p>Site(s)</p>
                                  <div className="SearchTextDiv StatusSearchText">
                                    <input className="dataAccessSearchInput" type="search" placeholder='Search' value={siteSearch} onChange={(e) => setSiteSearch(e.target.value)} />
                                    {
                                      isDarkTheme ?
                                      <img className="EyeIcon" style={{ paddingBottom: "3px" }} alt="Search-icon" src={SearchIcon}
                                        onClick={() => searchAccessDetails()}
                                      /> :
                                      <img className="EyeIcon" style={{ paddingBottom: "3px" }} alt="Search-icon" src={SearchIconWhite} height="16"
                                        onClick={() => searchAccessDetails()}
                                      />
                                    }
                                  </div>
                                </th>
                                <th>
                                  <p>Pin code(s)</p>
                                  <div className="SearchTextDiv StatusSearchText">
                                    <input className="dataAccessSearchInput" type="search" placeholder='Search' value={pinCodeSearch} onChange={(e) => setpinCodeSearch(e.target.value)} />
                                    {
                                      isDarkTheme ?
                                      <img className="EyeIcon" style={{ paddingBottom: "3px" }} alt="Search-icon" src={SearchIcon}
                                        onClick={() => searchAccessDetails()}
                                      /> :
                                      <img className="EyeIcon" style={{ paddingBottom: "3px" }} alt="Search-icon" src={SearchIconWhite} height="16"
                                        onClick={() => searchAccessDetails()}
                                      />
                                    }
                                  </div>
                                </th>
                                <th>
                                  <p>Area(s)</p>
                                  <div className="SearchTextDiv StatusSearchText">
                                    <input className="dataAccessSearchInput" type="search" placeholder='Search' value={areaSearch} onChange={(e) => setAreaSearch(e.target.value)} />
                                    {
                                      isDarkTheme ?
                                      <img className="EyeIcon" style={{ paddingBottom: "3px" }} alt="Search-icon" src={SearchIcon}
                                        onClick={() => searchAccessDetails()}
                                      /> :
                                      <img className="EyeIcon" style={{ paddingBottom: "3px" }} alt="Search-icon" src={SearchIconWhite} height="16"
                                        onClick={() => searchAccessDetails()}
                                      />
                                    }
                                  </div>
                                </th>
                                <th>
                                  <p>Region(s)</p>
                                  <div className="SearchTextDiv StatusSearchText">
                                    <input className="dataAccessSearchInput" type="search" placeholder='Search' value={regionSearch} onChange={(e) => setRegionSearch(e.target.value)} />
                                    {
                                      isDarkTheme ?
                                      <img className="EyeIcon" style={{ paddingBottom: "3px" }} alt="Search-icon" src={SearchIcon}
                                        onClick={() => searchAccessDetails()}
                                      /> :
                                      <img className="EyeIcon" style={{ paddingBottom: "3px" }} alt="Search-icon" src={SearchIconWhite} height="16"
                                        onClick={() => searchAccessDetails()}
                                      />
                                    }
                                  </div>
                                </th>
                                <th>
                                  <p>City(s)</p>
                                  <div className="SearchTextDiv StatusSearchText">
                                    <input className="dataAccessSearchInput" type="search" placeholder='Search' value={citySearch} onChange={(e) => setCitySearch(e.target.value)} />
                                    {
                                      isDarkTheme ?
                                      <img className="EyeIcon" style={{ paddingBottom: "3px" }} alt="Search-icon" src={SearchIcon}
                                        onClick={() => searchAccessDetails()}
                                      /> :
                                      <img className="EyeIcon" style={{ paddingBottom: "3px" }} alt="Search-icon" src={SearchIconWhite} height="16"
                                        onClick={() => searchAccessDetails()}
                                      />
                                    }
                                  </div>
                                </th>
                                <th>
                                  <p>State(s)</p>
                                  <div className="SearchTextDiv StatusSearchText">
                                    <input className="dataAccessSearchInput" type="search" placeholder='Search' value={stateSearch} onChange={(e) => setStateSearch(e.target.value)} />
                                    {
                                      isDarkTheme ?
                                      <img className="EyeIcon" style={{ paddingBottom: "3px" }} alt="Search-icon" src={SearchIcon}
                                        onClick={() => searchAccessDetails()}
                                      /> :
                                      <img className="EyeIcon" style={{ paddingBottom: "3px" }} alt="Search-icon" src={SearchIconWhite} height="16"
                                        onClick={() => searchAccessDetails()}
                                      />
                                    }
                                  </div>
                                </th>
                                <th>
                                  <p>Country(s)</p>
                                  <div className="SearchTextDiv StatusSearchText">
                                    <input className="dataAccessSearchInput" type="search" placeholder='Search' value={countrySearch} onChange={(e) => setCountrySearch(e.target.value)} />
                                    {
                                      isDarkTheme ?
                                      <img className="EyeIcon" style={{ paddingBottom: "3px" }} alt="Search-icon" src={SearchIcon}
                                        onClick={() => searchAccessDetails()}
                                      /> :
                                      <img className="EyeIcon" style={{ paddingBottom: "3px" }} alt="Search-icon" src={SearchIconWhite} height="16"
                                        onClick={() => searchAccessDetails()}
                                      />
                                    }
                                  </div>
                                </th>
                                <th>
                                  <p>Customer(s)</p>
                                  <div className="SearchTextDiv StatusSearchText">
                                    <input className="dataAccessSearchInput" type="search" placeholder='Search' value={customerSearch} onChange={(e) => setCustomerSearch(e.target.value)} />
                                    {
                                      isDarkTheme ?
                                      <img className="EyeIcon" style={{ paddingBottom: "3px" }} alt="Search-icon" src={SearchIcon}
                                        onClick={() => searchAccessDetails()}
                                      /> :
                                      <img className="EyeIcon" style={{ paddingBottom: "3px" }} alt="Search-icon" src={SearchIconWhite} height="16"
                                        onClick={() => searchAccessDetails()}
                                      />
                                    }
                                  </div>
                                </th>
                              </tr>
                            </thead>                              
                            <tbody>
                              {
                                tempDeviceData.length > 0 ? 
                                tempDeviceData.map((data) => (
                                  <tr key={data.Deviceid}>
                                    <td>{data.devicename}</td>
                                    <td>{data.DeviceType}</td>
                                    <td>{data.BranchID}</td>
                                    <td>{data.SiteName}</td>
                                    <td>{data.PinCode}</td>
                                    <td>{data.Area}</td>
                                    <td>{data.Region}</td>
                                    <td>{data.City}</td>
                                    <td>{data.statename}</td>
                                    <td>{data.countryname}</td>
                                    <td>{data.ClientName}</td>
                                  </tr>
                                )) : <div className='noRecordFound'><p>No Record Available.</p></div>
                              }
                            </tbody> 
                          </Table>
                        </div>
                        {/* disabled={buttonDisable} */}
                        <div className="selectDeviceDiv">
                          <Button variant="primary" className='UpdateSelectedDevices' onClick={exportHandler} disabled={buttonDisable}>Export all devices</Button>
                          {
                            totalDevices == 0 || totalPages == 0 || totalPages <= limit
                              ? null
                              :
                              <div className="PaginationDiv">
                                <h5 className='PageNumbers'>
                                  {
                                    totalDevices == 0 ? 0
                                      : (currentPageNo - 1) * parseInt(limit) + parseInt(1)}
                                  -
                                  {
                                    (currentPageNo * limit) > (totalDevices)
                                      ? (totalDevices)
                                      : currentPageNo * limit
                                  }
                                </h5>
                                <span>of</span>
                                <h5 className='PageNumbers'>{totalDevices}</h5>
                                <Pagination>
                                  <Pagination.Prev onClick={prevClick} disabled={currentPageNo == 1} />
                                  {
                                    TempPaginationComponent(currentPageNo)
                                  }
                                  <Pagination.Next onClick={nextClick} disabled={currentPageNo == maxPageAllowed} />
                                </Pagination>
                              </div>
                          }
                        </div>
                      </>
                      :
                      <div className="noDataAccessMsg">
                        <div><img className="informationIcon" src={InfoIcon} alt='info icon' /></div>
                        <div style={{ width: "100%" }}>
                          <h3 className="No-Data-Access-Group">
                            Selected Data Access Group Not Connected
                          </h3>
                          <span className="Add-or-select-data-access-group">
                            Kindly inherit the data access group from other data access group
                          </span>
                        </div>
                      </div>
                  }
                </div>
              </Tab>
            </Tabs>
          </div>
        </div>
      </div>
    </>
  );
}

export default memo(DataAccessGroup);